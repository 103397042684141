
export class Config {

    static organizationId = '5f63de578ceefc56d20226ab';
    static organizationName = "@mod-appname";
    static appVersion = '2.00.06';

    static cipher: string = 'KPdzpXFGaUkAFHwj5FBy82B6'; 

    static isCanuucan = 'false';

    static pickupTypes = [ 'DELIVERYTYPE02', 'DELIVERYTYPE07' ];

    constructor() { }
}
