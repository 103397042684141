import { Injectable } from '@angular/core';
import swal from 'sweetalert2';
import { environment } from '../environments/environment';
import { WhitelabelState } from '../state/whitelabel.state';
import { Store, Select } from '@ngxs/store';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class SweetAlertService {
  alertComponent;

  constructor(private store :Store, public translate: TranslateService) {}

  confirmDelete(title, message) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: message,
          showCancelButton: true,
          confirmButtonText: this.translate.instant('yes-delete'),
          cancelButtonText: this.translate.instant('no-keep'),
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }
  confirm(title, message) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: message,
          position: 'center',
          showCancelButton: true,
          confirmButtonText: this.translate.instant('yes'),
          cancelButtonText: this.translate.instant('no'),
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }

  allowPushNotification(title, message, isMobile = true) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: message,
          position: isMobile ? 'bottom-start' : 'center',
          showCancelButton: true,
          confirmButtonText: 'Allow',
          cancelButtonText: 'Deny',
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }
  alert(title, message) {
    swal.fire(title, message, 'success');
  }
  toast(icon, message) {
    const Toast = swal.mixin({
      toast: true,
      position: 'top-start',
      showConfirmButton: false,
      animation: false,
      timer: 7000,
      timerProgressBar: true,
      onOpen: (toast) => {
        toast.addEventListener('mouseenter', swal.stopTimer);
        toast.addEventListener('mouseleave', swal.resumeTimer);
      },
    });
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    Toast.fire({
      // imageUrl: environment.imagebaseurl + whitelabel.faviconImage,
      // imageWidth: 18,
      title: message,
    });
  }
  alertError(title, message) {
    swal.fire(title, message, 'error');
  }
  alertWarning(title, message) {
    swal.fire(title, message, 'warning');
  }

  confirmWithoutCancel(title, message) {
    return new Promise((resolve, reject) => {
      swal
        .fire({
          title: title,
          text: message,
          position: 'center',
          showCancelButton: false,
          confirmButtonText: 'OK',
        })
        .then((result) => {
          resolve(result.value);
        });
    });
  }
}
