import { Venue } from '../models';

export class SetSelectedPickUpLocation {
  static readonly type = '[VENUEMANAGEMENT] SetSelectedPickUpLocation';

  constructor(public payload: Venue) {}
}

export class SetSelectedRoom {
  static readonly type = '[VENUEMANAGEMENT] SetSelectedRoom';

  constructor(public payload: Venue) {}
}

export class SetSelectedTakeOut {
  static readonly type = '[VENUEMANAGEMENT] SetSelectedTakeOut';

  constructor(public payload: Venue) {}
}
export class SetSelectedTable {
  static readonly type = '[VENUEMANAGEMENT] SetSelectedTable';

  constructor(public payload) {}
}

export class SetCustomerPerTable {
  static readonly type = '[VENUEMANAGEMENT] SetCustomersInTable';

  constructor(public payload) {}
}
export class SetLocationAccess {
  static readonly type = '[VENUEMANAGEMENT] SetLocationAccessEnabled';
  constructor(public payload) {}
}
export class ResetCustomerPerTable {
  static readonly type = '[VENUEMANAGEMENT] ResetCustomersPerTable';

  constructor(public payload) {}
}
export class FetchVenues {
  static readonly type = '[VENUEMANAGEMENT] FetchVenues';
  constructor(public payload) {}
}

export class ChangeRoomSelectionPopupStatus {
  static readonly type = '[STATUS] ChangeRoomSelectionPopupStatus';

  constructor(public payload?: boolean) {}
}

export class ChangePickupStationPopupStatus {
  static readonly type = '[STATUS] ChangePickupStationPopupStatus';

  constructor(public payload?: boolean) {}
}

export class ChangeTakeoutStationPopupStatus {
  static readonly type = '[STATUS] ChangeTakeoutStationPopupStatus';

  constructor(public payload?: boolean) {}
}
export class ChangeLoungePopupStatus {
  static readonly type = '[STATUS] ChangeLoungePopupStatus';

  constructor(public payload?: boolean) {}
}


export class ClearAllVenueDatas {
  static readonly type = '[VENUEMANAGEMENT] ClearAllVenueDatas';
  constructor() {}
}
