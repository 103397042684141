// angular
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// app
import { SharedModule } from './features/shared/shared.module';
import { WJModifierComponent } from './features/shared/wj-modifier/wj-modifier.component';
import { WJReviewComponent } from './features/wj-review/wj-review.component';
import {
  WJSelectedLocationGuard,
  WJCartGuard,
  WJEmptyRedirectionGuard,
  WJLocationAccessGuard
} from '../guards';
import { WJTableEmptyComponent } from './features/wj-table-empty/wj-table-empty.component';
import { WJLocationAccessComponent } from './features/wj-home/wj-location-access/wj-location-access.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/locations',
    pathMatch: 'full',
  },
  {
    path: 'locations/:locationId/order',
    pathMatch: 'full',
    canActivate: [WJSelectedLocationGuard,WJCartGuard],
    component: WJReviewComponent,
  },
  {
    path: 'locations',
    canActivate: [WJEmptyRedirectionGuard],
    loadChildren: () =>
      import('./core/wj-location-wrapper/wj-location-wrapper.module').then(
        (m) => m.WJLocationWrapperModule
      ),
  },
  {
    path: 'locations/:locationId',
    canActivate: [WJSelectedLocationGuard],
    loadChildren: () =>
      import('./core/wj-location-wrapper/wj-location-wrapper.module').then(
        (m) => m.WJLocationWrapperModule
      ),
  },
  {
      path: 'order/complete',
      canActivate: [WJCartGuard],
      loadChildren: () =>
        import('./features/order-complete/order-complete.module').then(
          (m) => m.OrderCompleteModule)
  },
  {
    path: 'order/success',
    loadChildren: () =>
      import('./features/wj-order-success/wj-order-success.module').then(
        (m) => m.OrderSuccessModule
      ),
  },
  {
    path: 'modifier/:menuItemId',
    component: WJModifierComponent,
  },
  {
    path: 'emptyTable',
    component: WJTableEmptyComponent,
  },
  {
    path: 'locationAccess',
    canActivate: [WJLocationAccessGuard],
    component: WJLocationAccessComponent,
  },
  {
    path: '**',
    redirectTo: "locations",
  },
];

@NgModule({
  imports: [SharedModule, RouterModule.forRoot(routes)],
  providers: [
    WJCartGuard,
    WJSelectedLocationGuard,
    WJEmptyRedirectionGuard,
    WJLocationAccessGuard
  ],
})
export class WJAppRoutingModule {}
