import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Address } from '../models';
import {
  FetchAddressList,
  SetAddress,
  SetSelectedAddress,
  FetchDeliveryAddress,
  ChangeDeliveryAddress,
  ChangePopupStatus,
  ChangeMapPopupStatus,
  ChangeToNewDeliveryAddress,
  ChangeTempToDeliveryAddress,
  SaveNewDeliveryAddress,
  ChangeadrsDetailsPopupStatus,
  UpdateAddressList,
  DeleteDeliveryAddress,
  ChangeDeliveryAddressLocal,
  FetchDeliveryAddressDetails,
  setLocationDistance,
  FetchDeliverableDetails,
  ClearSelectedAddress,
  UpdateDeliverableStatus,
} from '../actions';
import { Injectable } from '@angular/core';
import { AddressService } from '../services';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { AuthState } from './auth.state';

export class AddressStateModel {
  addressList: Address[];
  addressListDetails: Address[];
  addressListLocal: Address[];
  selectedAddress: Address;
  tempselectedAddress: Address;
  setSelectedAddress: string;
  modalChangeAdrsStatus: boolean;
  modalChangeMapStatus: boolean;
  modalAdrsDetailsStatus: boolean;
  isDeliverable: boolean;
}

@State<AddressStateModel>({
  name: 'address',
  defaults: {
    selectedAddress: null,
    tempselectedAddress: null,
    addressList: null,
    addressListLocal: null,
    setSelectedAddress: null,
    modalChangeAdrsStatus: false,
    modalChangeMapStatus: false,
    modalAdrsDetailsStatus: false,
    addressListDetails: null,
    isDeliverable: null,
  },
})
@Injectable()
export class AddressState {
  constructor(private addressservice: AddressService, private store: Store) {}

  @Selector()
  static getAddressList(state: AddressStateModel) {
    return state.addressList;
  }
  @Selector()
  static getAddressListFromLocal(state: AddressStateModel) {
    state.addressListLocal = localStorage.getItem('locationList')
      ? JSON.parse(localStorage.getItem('locationList'))
      : undefined;
    return state.addressListLocal;
  }

  @Selector()
  static getSelectedAddress(state: AddressStateModel) {
    return state.setSelectedAddress;
  }
  @Selector()
  static getTempSelectedAddress(state: AddressStateModel) {
    return state.tempselectedAddress;
  }
  @Selector()
  static getDeliveryAddress(state: AddressStateModel) {
    if (state.isDeliverable == false) state.selectedAddress = null;
    else if (state.isDeliverable == null || state.isDeliverable == true) {
      if (state.selectedAddress == null)
        state.selectedAddress =
          localStorage.getItem('selectedAdrsLocation') &&
          localStorage.getItem('selectedAdrsLocation') != 'undefined'
            ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
            : undefined;
    }
    return state.selectedAddress;
  }
  @Selector()
  static getAddressPopUpStatus(state: AddressStateModel) {
    return state.modalChangeAdrsStatus;
  }

  @Selector()
  static getMapPopUpStatus(state: AddressStateModel) {
    return state.modalChangeMapStatus;
  }
  @Selector()
  static getAdrsDetailsPopUpStatus(state: AddressStateModel) {
    return state.modalAdrsDetailsStatus;
  }
  @Action(FetchAddressList)
  fetchAddress({ setState }: StateContext<AddressStateModel>) {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    return this.addressservice.fetchAddress(customer.customerId).pipe(
      tap((response) => {
        if (response) {
          setState(
            patch({
              addressList: response['data'],
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }
  @Action(FetchDeliveryAddress)
  fetchdeliveryAddress({ setState }: StateContext<AddressStateModel>) {
    const lastLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    {
      setState(
        patch({
          selectedAddress: lastLocation,
        })
      );
    }
  }

  @Action(SetSelectedAddress)
  setAddress(
    { setState }: StateContext<AddressStateModel>,
    { payload }: SetSelectedAddress
  ) {
    setState(
      patch({
        setSelectedAddress: payload,
      })
    );
  }

  @Action(ChangeTempToDeliveryAddress)
  setNewFromTempAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: ChangeTempToDeliveryAddress
  ) {
    setState(
      patch({
        tempselectedAddress: payload,
      })
    );
  }
  @Action(ChangeToNewDeliveryAddress)
  saveNewAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: ChangeToNewDeliveryAddress
  ) {
    let selectedLocation = localStorage.getItem('selectedLocation')
      ? JSON.parse(localStorage.getItem('selectedLocation'))
      : undefined;
    if (selectedLocation) {
      this.store.dispatch(new setLocationDistance(selectedLocation._id));
    }
  }

  @Action(DeleteDeliveryAddress)
  deleteAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: DeleteDeliveryAddress
  ) {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    return this.addressservice
      .deleteDeliveryAddress(customer.customerId, payload)
      .pipe(
        tap((response) => {
          if (response) {
          } else throw response;
        }),
        catchError((error) => {
          return Observable.throw(error);
        })
      );
  }

  @Action(SaveNewDeliveryAddress)
  addNewAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: SaveNewDeliveryAddress
  ) {
    let AddressObj = {
      instructions: payload.instructions,
      buzzerNumber: payload.buzzerNumber,
      unitNumber: payload.unitNumber,
      postalcode: payload.postalcode,
      country: payload.country,
      state: payload.state,
      city: payload.city,
      streetAddress: payload.streetAddress,
      addressType: payload.addressType,
    };
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (payload._id == '') {
      return this.addressservice
        .saveDeliveryAddress(customer.customerId, AddressObj)
        .pipe(
          tap((response) => {
            if (response) {
            } else throw response;
          }),
          catchError((error) => {
            return Observable.throw(error);
          })
        );
    } else {
      let AddressObj = {
        instructions: payload.instructions,
        buzzerNumber: payload.buzzerNumber,
        unitNumber: payload.unitNumber,
        postalcode: payload.postalcode,
        country: payload.country,
        state: payload.state,
        city: payload.city,
        streetAddress: payload.streetAddress,
        addressType: payload.addressType,
        _id: payload._id,
      };
      return this.addressservice
        .updateDeliveryAddress(customer.customerId, payload._id, AddressObj)
        .pipe(
          tap((response) => {
            if (response) {
              if (response) {
              }
            } else throw response;
          }),
          catchError((error) => {
            return Observable.throw(error);
          })
        );
    }
  }

  @Action(ChangeDeliveryAddress)
  changeAddress(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: ChangeDeliveryAddress
  ) {
    const state = getState();
    setState(
      patch({
        selectedAddress: state.addressList.find(
          (address) => address._id === payload
        ),
        tempselectedAddress: state.addressList.find(
          (address) => address._id === payload
        ),
      })
    );

    localStorage.setItem(
      'selectedAdrsLocation',
      JSON.stringify(
        state.addressList.find((address) => address._id === payload)
      )
    );
    let selectedLocation = localStorage.getItem('selectedLocation')
      ? JSON.parse(localStorage.getItem('selectedLocation'))
      : undefined;
    if (selectedLocation) {
      this.store.dispatch(new setLocationDistance(selectedLocation._id));
    }
  }
  @Action(ChangeDeliveryAddressLocal)
  changeAddressLocal(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: ChangeDeliveryAddress
  ) {
    const state = getState();
    setState(
      patch({
        selectedAddress: JSON.parse(localStorage.getItem('locationList')).find(
          (address) => address._id === payload
        ),
      })
    );
    localStorage.setItem(
      'selectedAdrsLocation',
      JSON.stringify(
        JSON.parse(localStorage.getItem('locationList')).find(
          (address) => address._id === payload
        )
      )
    );
    let selectedLocation = localStorage.getItem('selectedLocation')
      ? JSON.parse(localStorage.getItem('selectedLocation'))
      : undefined;
    if (selectedLocation) {
      this.store.dispatch(new setLocationDistance(selectedLocation._id));
    }
  }
  @Action(ChangePopupStatus)
  ChangeStatus(
    { setState }: StateContext<AddressStateModel>,
    { payload }: ChangePopupStatus
  ) {
    setState(
      patch({
        modalChangeAdrsStatus: payload,
      })
    );
  }
  @Action(ChangeMapPopupStatus)
  ChangeMapStatus(
    { setState }: StateContext<AddressStateModel>,
    { payload }: ChangeMapPopupStatus
  ) {
    setState(
      patch({
        modalChangeMapStatus: payload,
      })
    );
  }
  @Action(ChangeadrsDetailsPopupStatus)
  ChangeadrsDetails(
    { setState }: StateContext<AddressStateModel>,
    { payload }: ChangeadrsDetailsPopupStatus
  ) {
    setState(
      patch({
        modalAdrsDetailsStatus: payload,
      })
    );
  }

  @Action(UpdateAddressList)
  UpdateAddressList({ setState }: StateContext<AddressStateModel>) {
    setState(
      patch({
        addressListLocal: localStorage.getItem('locationList')
          ? JSON.parse(localStorage.getItem('locationList'))
          : undefined,
      })
    );
  }
  @Action(FetchDeliveryAddressDetails)
  fetchDeliveryAddressDetails(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: FetchDeliveryAddressDetails
  ) {
    const state = getState();
    return this.addressservice.fetchDeliveryAddressDetails(payload).pipe(
      tap((response) => {
        if (response) {
          let customer = this.store.selectSnapshot(
            AuthState.getCommonAuthDetails
          );
          if (
            customer &&
            customer.isLoggedIn &&
            response['errorCode'] != '4001'
          ) {
            for (let i = 0; i < response['data'].length; i++) {
              if (response['data'][i].distanceFromAddress != null) {
                state.addressList[i].distance =
                  response['data'][i].distanceFromAddress;
              }
              state.addressList[i].deliverable =
                response['data'][i].isDeliveryAllowed;
            }
          } else {
            if (response['errorCode'] != '4001') {
              for (let i = 0; i < response['data'].length; i++) {
                if (response['data'][i].distanceFromAddress != null) {
                  state.addressListLocal[i].distance =
                    response['data'][i].distanceFromAddress;
                }
                state.addressListLocal[i].deliverable =
                  response['data'][i].isDeliveryAllowed;
              }
            }
          }
        } else {
        }
      }),
      catchError((error) => {
        console.error(error);
        return Observable.throw(error);
      })
    );
  }
  @Action(FetchDeliverableDetails)
  fetchDeliverableDetails(
    { getState, setState }: StateContext<AddressStateModel>,
    { payload }: FetchDeliverableDetails
  ) {
    const state = getState();
    return this.addressservice.fetchDeliveryAddressDetails(payload).pipe(
      tap((response) => {
        setState(
          patch({
            isDeliverable: response['data'][0].isDeliveryAllowed,
          })
        );
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  @Action(ClearSelectedAddress)
  clearSelectedAddress({
    patchState,
    getState,
  }: StateContext<AddressStateModel>) {
    patchState({
      selectedAddress: null,
    });
  }
  @Action(UpdateDeliverableStatus)
  UpdateDeliverableStatus(
    { setState }: StateContext<AddressStateModel>,
    { payload }: UpdateDeliverableStatus
  ) {
    setState(
      patch({
        isDeliverable: payload,
      })
    );
  }
}
