// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
// import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

@Injectable({
  providedIn: 'root',
})
export class AddressService {
  constructor(private http: HttpClient) {}

  fetchAddress(_id) {
    const url = `${environment.addressApiUrl}customers/` + _id + `/address`;
    return this.http.get(url); //5a5480ee2c393854d50189e4
  }
  fetchDeliveryAddress(_id) {
    const url = `${environment.addressApiUrl}customers/` + _id + `/address`;
    return this.http.get(url);
  }
  saveDeliveryAddress(_id, payload) {
    const url = `${environment.addressApiUrl}customers/` + _id + `/address`;
    return this.http.put(url, payload);
  }
  updateDeliveryAddress(customerId, _id, payload) {
    const url =
      `${environment.addressApiUrl}customers/` + customerId + `/address/` + _id;
    return this.http.put(url, payload);
  }
  deleteDeliveryAddress(customerId, payload) {
    const url =
      `${environment.addressApiUrl}customers/` +
      customerId +
      `/address/` +
      payload;
    return this.http.delete(url, payload);
  }
  fetchDeliveryAddressDetails(payload) {
    let body = {
      locationId: payload.locationId,
      locationCoordinates: payload.locationCoordinates,
      addressCoordinates: payload.addressCoordinates,
    };
    const url = `${environment.customerApiv3Url}customers/addressCheck`;
    return this.http.post(url, body);
  }
}
