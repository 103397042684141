import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import {
  SetModifierItem,
  RemoveModifierItem,
  SetSubModifierItem,
  BaseComponent,
  RemoveSubModifierItem,
} from '@moduurnv2/libs-orderingapp/src';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wj-modifier-list',
  templateUrl: 'wj-modifier-list.component.html',
  styleUrls: ['wj-modifier-list.component.scss'],
})
export class WJModifierListComponent extends BaseComponent implements OnInit {

  @Input('modifierItem') modifierItem;
  @Input('modifier') modifier;
  generatedMenuItem;

  constructor(private store: Store, private translate:TranslateService) {
    super();
  }

  ngOnInit() { }

  checkInput(checkInput) {
    if (checkInput.isForced && checkInput.maxLimit === 1) return true;
    return false;
  }

  choiceText(modifierItem) {
    const { isForced, maxLimit, minLimit } = modifierItem;
    let choiceOption = this.translate.instant('please-select');
    let endText = this.translate.instant('optional');
    if (isForced) endText = this.translate.instant('required');

    if (maxLimit === 1) return `${choiceOption} 1 `+this.translate.instant('option')+ ` (${endText})` ;
    else if (maxLimit > 0)
      if (!minLimit || minLimit === 0) return `${choiceOption} ${this.translate.instant('upto')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else if (minLimit === maxLimit)
        return `${choiceOption} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
      else return `${choiceOption} ${this.translate.instant('between')} ${minLimit} ${this.translate.instant('and')} ${maxLimit} `+this.translate.instant('option')+'s'+ ` (${endText})`;
  }

  modifierItemChange(event, modifierItem, modifier) {
    if (event && event.target) {
      const { modifierItems, maxLimit } = modifier;
      const selectedItems = modifierItems.filter(
        (modifierItem) => modifierItem.selected === true
      );
      // if checkbox
      if (this.checkInput(modifier) === false) {
        if (event.target.checked === true) {
          if (
            (maxLimit > 0 && maxLimit >= selectedItems.length) ||
            maxLimit === 0
          )
            this.store.dispatch(
              new SetModifierItem(this.modifier._id, modifierItem._id)
            );
          else
            event.target.checked = false;
        } else if (event.target.checked === false) {
          this.store.dispatch(
            new RemoveModifierItem(this.modifier._id, modifierItem._id)
          );
        } else {
          event.target.checked = false;
        }
      } else {
        if (event.target.checked === true)
          if (
            (maxLimit > 0 && maxLimit >= selectedItems.length) ||
            maxLimit === 0
          )
            this.store.dispatch(
              new SetModifierItem(this.modifier._id, modifierItem._id)
            );
      }
    }
  }

  subModifierItemChange(event, subModifierItem, subModifier) {
    if (event && event.target) {
      const { maxLimit : modifierMax, modifierItems } = this.modifier;
      const { items, maxLimit } = subModifier;
      const selectedItems = items.filter(
        (item) => item.selected === true
      );
      if (this.checkInput(subModifier) === false) {
        if (event.target.checked === true) {
          if (
            (maxLimit > 0 && maxLimit >= selectedItems.length) ||
            maxLimit === 0
          )
            this.store.dispatch(
              new SetSubModifierItem(
                this.modifier._id,
                subModifier._id,
                subModifierItem._id
              )
            );
          else {
            event.target.checked = false;
            subModifierItem.selected = false;
          }
        } else if (event.target.checked === false) {
          this.store.dispatch(
            new RemoveSubModifierItem(
              this.modifier._id,
              subModifier._id,
              subModifierItem._id
            )
          );
        } else {
          event.target.checked = false;
          subModifierItem.selected = false;
        }
      } else {
        if (event.target.checked === true)
          if (
            (maxLimit > 0 && maxLimit >= selectedItems.length) ||
            maxLimit === 0
          )
            this.store.dispatch(
              new SetSubModifierItem(
                this.modifier._id,
                subModifier._id,
                subModifierItem._id
              )
            );
      }
    }
  }

  headerErrors(subModifierValid) {
    const submitted = this.store.selectSnapshot(
      (app) => app && app.menuItem && app.menuItem.submitted
    );
    return submitted ? subModifierValid == false : false;
  }

}
