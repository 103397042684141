import { Component, Input, OnInit } from '@angular/core';
import {
  ItemCountBaseComponent,
  MenuItemCreation,
  CartState,
  CartItems,
  SweetAlertService,
  SetItemDetails,
  AddItemToCart,
  UpdateCount,
  SetMenuItem,
  SharedService,
} from '@moduurnv2/libs-orderingapp';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import {TranslateService} from '@ngx-translate/core';
import { AlertPopupComponent } from '@moduurnv2/web/features/ui/components/alert-popup/alert-popup.component';
import { WJModifierComponent } from '../wj-modifier/wj-modifier.component';
@Component({
  selector: 'wj-item-count',
  templateUrl: 'wj-item-count.component.html',
  styleUrls: ['wj-item-count.component.scss'],
})
export class WJItemCountComponent extends ItemCountBaseComponent
  implements OnInit {
  @Input('menuitem') menuItem;
  @Input('reviewmenuitem') reviewMenuItem: boolean = false;
  selectedMenuItem: MenuItemCreation[];
  @Select(CartState.getCartPriceItems) cart$: Observable<any>;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  value: number = 0;
  isEmpty: boolean;
  myCart: any;
  countSubject = new Subject();
  loading: boolean = false;
  popup: boolean = false;
  // showModifier: boolean = false;
  // menuItemId:string;
  constructor(
    public store: Store,
    public sweetAlert: SweetAlertService,
    private modal: ModalService,
    private shared: SharedService,
    public translate: TranslateService
  ) {
    super();
    this.isEmpty = true;
  }

  ngOnInit() {
    this.value = 0;
    this.cart$.pipe(takeUntil(this.destroy$)).subscribe((cartItems) => {
      if (cartItems) this.checkIfSelectedMenuItem(cartItems);
    });
    this.cartItems$.pipe(takeUntil(this.destroy$)).subscribe((cartItems) => {
      this.loading = false;
    });
    this.setupCountSubject();
  }

  subtractCount(item) {
    const cartItems = this.store.selectSnapshot(CartState.getCartPriceItems);
    const cart = this.store.selectSnapshot(CartState.getCart);

    let itemId = '';

    if (this.reviewMenuItem) itemId = item._id;
    else itemId = item._id;
    let selectedMenuItem = cartItems.find((menuItem) => {
      if (this.reviewMenuItem) return menuItem.id === itemId;
      else return menuItem.itemId === itemId;
    });

    if (cart && this.value) {
      if (this.reviewMenuItem && this.value == 1) {
        const payload = {
          count: this.value - 1,
          menuItemId: selectedMenuItem.id,
          cartId: cart.cart_id,
        };
        this.modal.showSmallModal(AlertPopupComponent,{data:{
          header:this.translate.instant('remove-item'),
          content:this.translate.instant('are-you-sure-remove'),
          actionable:true,
          buttonText:this.translate.instant('remove'),
          isRemove:true
        },
        whenClosed: (data) => {
          if(data)this.countSubject.next(payload);
        },
      });
      } else {
        this.value--;
        const payload = {
          count: this.value,
          menuItemId: selectedMenuItem.id,
          cartId: cart.cart_id,
        };

        this.countSubject.next(payload);
      }
    } else {
      this.isEmpty = true;
    }

    if (!this.value) {
      this.isEmpty = true;
    }
    this.store.dispatch(new SetMenuItem(null));
  }

  addCount(item) {
    const cartItems = this.store.selectSnapshot(CartState.getCartPriceItems);
    const cart = this.store.selectSnapshot(CartState.getCart);
    let itemId = '';

    if (this.reviewMenuItem) itemId = item._id;
    else itemId = item._id;
    let selectedMenuItem = cartItems.find((menuItem) => {
      if (this.reviewMenuItem) return menuItem.id === itemId;
      else return menuItem.itemId === itemId;
    });

    if (cart) {
      this.value++;
      const payload = {
        count: this.value,
        menuItemId: selectedMenuItem.id,
        cartId: cart.cart_id,
      };

      this.countSubject.next(payload);
      this.store.dispatch(new SetMenuItem(null));
    }
  }

  addItem(menuitem) {
    const cart = this.store.selectSnapshot(CartState.getCart);
    if (!menuitem.portion || !menuitem.cartModifiers) {
      const itemCount = this.value + 1;
      this.selectedMenuItem = [
        {
          count: itemCount,
          menuItem: menuitem._id,
          portion: undefined,
          cartModifiers: [],
        },
      ];
    }

    // create a new cart call
    if (this.checkCustomize) {
      const menuDetails = {
        icons: menuitem.icons,
        description: menuitem.description,
        imageURL: menuitem.imageURL,
        name: menuitem.itemTitle,
        _id: menuitem._id,
        translation:menuitem.translation
      };
      this.store.dispatch(new SetItemDetails(menuDetails));
      if (menuitem._id) {
          //this.showModifier = true;
          // this.menuItemId = menuitem._id;
          this.modal.showModifierModal(WJModifierComponent,{
            data:{
              menuItemId:menuitem._id,
              isEdit:false
          }})
      }
    } else {
      if(this.validateMaxItemsPerUser(this.selectedMenuItem[0].menuItem,this.selectedMenuItem[0].count)){
        this.loading = true;
        this.store.dispatch(new AddItemToCart(this.selectedMenuItem));
      }
    }
    this.store.dispatch(new SetMenuItem(null));
  }

  get checkCustomize() {
    const { portonsCount, modifiersCount } = this.menuItem;
    return portonsCount || modifiersCount;
  }

  checkIfSelectedMenuItem(menuItems) {
    const foundMenuItem = menuItems.find((menuItem) => {
      if (menuItem)
        if (this.reviewMenuItem) return menuItem.id === this.menuItem._id;
        else return menuItem.itemId === this.menuItem._id;

      return false;
    });
    if (foundMenuItem) {
      this.isEmpty = false;
      this.value = foundMenuItem.count;
    } else {
      this.isEmpty = true;
      this.value = 0;
    }
  }

  setupCountSubject() {
    this.countSubject
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((data) => {
            const payload = data as any;
            if(this.validateMaxItemsPerUser(payload.menuItemId, payload.count)){
                if (payload) {
                  this.loading = true;
                  this.store.dispatch(new UpdateCount(payload));
                }
            }
      });
  }

  get checkModifier() {
    if (this.reviewMenuItem) return this.checkCustomize;
    else if (this.isEmpty) return true;
    else return this.checkCustomize > 0;
  }


    validateMaxItemsPerUser(selectedMenuItemId,selectedMenuCountToUpdate){
        const state = this.store.selectSnapshot((app) => app);
        const { menulist, cart,venuemanagement } = state;
        let guestCount = undefined;
        if(venuemanagement?.totalPersonPerTable?.count){
        guestCount = parseInt(venuemanagement.totalPersonPerTable['count']);
        }
        if(guestCount > 0){
            let selectedCategory;
            let cartItemPrice;
            if(cart.cart && cart.cart.itemPrice){
              cartItemPrice = cart.cart.itemPrice;
                    let selectedItem = cart.cart.itemPrice.find(item=> item.id == selectedMenuItemId);
                    if(selectedItem){
                    let selectedCategoryId = selectedItem.menuSectionId;
                      selectedCategory = menulist.menuList.categories.find(cat =>cat._id == selectedCategoryId);
                    } else if(menulist.selectedCategory){
                      selectedCategory = menulist.selectedCategory;
                    }
            } else if(menulist.selectedCategory){
              selectedCategory = menulist.selectedCategory;
            }
            if(selectedCategory && selectedCategory.maxItemsPerOrder && selectedCategory.maxItemsPerOrder>0){
              if(cartItemPrice){
              let cartItemsFromCurrentCategory = cartItemPrice.filter(item=>item.menuSectionId == selectedCategory._id);
              let selectedMenuDetails = cartItemPrice.find(item=>item.id == selectedMenuItemId);
              const totalItemCountPerCategory = cartItemsFromCurrentCategory.reduce((total, eachItem) => {
                    return total + eachItem.count;
                }, 0);
              let totalItemsValidPerCategory = guestCount * selectedCategory.maxItemsPerOrder;
              let updatedCountOfCurrentCategory;

              if(selectedMenuDetails && totalItemCountPerCategory && selectedMenuCountToUpdate > selectedMenuDetails.count){
                updatedCountOfCurrentCategory = selectedMenuCountToUpdate + (totalItemCountPerCategory - selectedMenuDetails.count)
              } else if(!selectedMenuDetails && totalItemCountPerCategory){
                updatedCountOfCurrentCategory = totalItemCountPerCategory + selectedMenuCountToUpdate
              }
              if((totalItemCountPerCategory && updatedCountOfCurrentCategory > totalItemsValidPerCategory)||((totalItemsValidPerCategory <= totalItemCountPerCategory) && (!selectedMenuDetails || selectedMenuDetails.count < selectedMenuCountToUpdate))){
            this.modal.showSmallModal(AlertPopupComponent,{data:{
              header:this.translate.instant('limit-reached'),
              content:this.translate.instant('limit-reached-description'),
              actionable:true,
              buttonText:this.translate.instant("continue")
            },
            whenClosed: (data) => {
             // if(data)this.countSubject.next(payload);
            },
          });
              if(selectedMenuDetails?.count) {
                this.value = selectedMenuDetails.count;
              } else {
                this.value = 0;
              }
                return false;
              } else {
                return true;
              }
              } else if(selectedMenuCountToUpdate > guestCount * selectedCategory.maxItemsPerOrder){
                this.modal.showSmallModal(AlertPopupComponent,{data:{
                  header:this.translate.instant('limit-reached'),
                  content:this.translate.instant('limit-reached-description'),
                  actionable:true,
                  buttonText:this.translate.instant("continue")
                },
                whenClosed: (data) => {
                 // if(data)this.countSubject.next(payload);
                },
              });
                return false;
              }
              else {
                return true;
              }
            } else  {
              return true;
            }
        } else if(guestCount == 0){
            this.sweetAlert.toast('error', 'You cant proceed without entering the number of guests.');
            return false;
        } else {
          return true;
        }
    }
    // closeModal(){
    //   this.showModifier = false;
    // }
}
