export class FetchSelectedMenuItem {
    static readonly type = '[MENUITEM] FetchItem';
    
    constructor(public payload) {}
}

export class SetPortion {
    static readonly type = '[MENUITEM] SetPortion';

    constructor(public payload) {}
}

export class SetModifierItem {
    static readonly type = '[MENUITEM] SetModifierItem';

    constructor(public id, public payload) {}
}
export class RemoveModifierItem {
    static readonly type = '[MENUITEM] RemoveModifierItem';

    constructor(public id, public payload) {}
}

export class GetGenModifierItem {
    static readonly type = '[MENUITEM] GetGenModifierItem';

    constructor(public id, public payload) {}
}

export class SetSubModifierItem {
    static readonly type = '[MENUITEM] SetsubModifierItem';

    constructor(public id, public subId, public payload) {}
}

export class RemoveSubModifierItem {
    static readonly type = '[MENUITEM] RemoveSubModifierItem';

    constructor(public id, public subId, public payload) {}
}

export class SubmitModifierItem {
    static readonly type = '[MENUITEM] SubmitModifierItem';

    constructor() {}
}

export class UpdateItemCount {
    static readonly type = '[MENUITEM] UpdateItemCount';

    constructor(public payload:number) {}
}

export class SetItemDetails {
    static readonly type = '[MENUITEM] SetItemDetails';

    constructor(public payload:any) {}
}

export class ClearMenuItem {
    static readonly type = '[MENUITEM] ClearMenuItem';

    constructor() {}
}

