const baseurl = 'https://betawso2.moduurn.ca/';
const baseurlv2 = 'https://dev-server.moduurn.ca/';

export const environment = {
    production: true,
    environment: 'qa3',
    localServerApiUrl: 'http://localhost:8080/api/v3/',
    imagebaseurl: 'https://s3.amazonaws.com/qamodimages/',
    customerApiUrl: `${baseurl}customerApi/v1/`,
    orderApiUrl: `${baseurlv2}api/v3/`,
    organizationApiUrl: `${baseurl}organizationapi/v1/`,
    mobileStoreApiUrl: `${baseurl}mobileStoreApi/v1/`,
    deliveriesApiUrl: `${baseurlv2}api/v3/`,
    locationApiUrl: `${baseurlv2}api/v3/`,
    addressApiUrl: `${baseurl}customerApi/v1/`,
    cartApiUrl: `${baseurl}cartApi/v1/carts/`,
    placeOrderApiUrl: `${baseurl}orderApi/v2/orders/`,
    clearCartApiUrl: `${baseurl}cartApi/v1/carts/`,
    paymentApiUrl: `${baseurl}paymentApi/v1/`,
    userApiUrl: `${baseurl}userapi/v1/users/`,
};