// angular
import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { Category } from '../models';

@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private http: HttpClient, private store: Store) {}

  fetchHomeData(selectedLocationId , selectedDelivery): Observable<any> {   
    let selectedTime = new Date().toISOString();
    const futureTime = this.store.selectSnapshot(app => app.cart && app.cart.futureDate);
    if(futureTime)
      selectedTime = new Date(futureTime).toISOString();
    const timezoneOffset = new Date().getTimezoneOffset();
    let url = `${environment.locationApiUrl}locations/${selectedLocationId}/homeComponents`;
    url += `?selectedTime=${selectedTime}&timezoneOffset=${timezoneOffset}&selectedDelivery=${selectedDelivery}`;
    return this.http.get(url);
  }
}
