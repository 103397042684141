import { Component, Input } from '@angular/core';
import { BaseComponent } from '@moduurnv2/libs-orderingapp/src';

@Component({
  selector: 'loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
})
export class LoaderComponent extends BaseComponent {
  @Input() isProcessing:boolean;  
}
