/**
 * Usage: value | distanceConverter :value  *
 **/

import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {
  WhitelabelState,
  Whitelabel,
  LocationDefaultState,
  LocationDefaults,
} from '@moduurnv2/libs-orderingapp/src/core';

import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { stringify } from '@angular/compiler/src/util';
@Pipe({
  name: 'formattedLanguage',
  pure: false,
})
export class LanguageLocalize implements PipeTransform {
  whitelabel;
  allowNoPaymentOrdering;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  constructor(private translate: TranslateService) {
    this.whitelabel$.subscribe((response) => {
      this.whitelabel = response;
    });
  }
  transform(value: string, translation: string, isName: boolean = true) {
    if(this.whitelabel)
    {
    let defalutLanguage =
      this.whitelabel && this.whitelabel.language
        ? this.whitelabel.language
        : undefined;
    let currentLanguage =
      this.translate && this.translate.currentLang
        ? this.translate.currentLang
        : 'en';
        if(defalutLanguage==undefined && currentLanguage=='en')
          return value;
    //let currentLanguage = 'es';
    if (currentLanguage == 'en') {
      if (value != '' && value!=undefined) return value;
      else {
        if (translation != undefined && defalutLanguage!=undefined) {
          if (isName) return translation[stringify(defalutLanguage)].name;
          else return translation[stringify(defalutLanguage)].description;
        } else return '';
      }
    }
    if(translation==undefined)
    {
      if (value != '' && value!=undefined) return value;
      else
      return '';
    }
    if (translation && currentLanguage != 'en') {
      if (currentLanguage == 'fr') {
        if (
          translation['LANGUAGE01'] &&
          translation['LANGUAGE01'].name == null
        ) {
          if (defalutLanguage != undefined) {
            if (isName) return translation[stringify(defalutLanguage)].name;
            else return translation[stringify(defalutLanguage)].description;
          } else return value;
        } else {
          if (translation['LANGUAGE01']) {
            if (isName) return translation['LANGUAGE01'].name;
            else return translation['LANGUAGE01'].description;
          }
          else  if (defalutLanguage != undefined) {
            if (isName) return translation[stringify(defalutLanguage)].name;
            else return translation[stringify(defalutLanguage)].description;
          }
           else return value;

        }
      } else if (currentLanguage == 'es') {
        if (
          translation['LANGUAGE02'] &&
          translation['LANGUAGE02'].name == null
        ) {
          if (defalutLanguage != undefined) {
            if (isName) return translation[stringify(defalutLanguage)].name;
            else return translation[stringify(defalutLanguage)].description;
          } else return value;
        } else {
          if (translation['LANGUAGE02']) {
            if (isName) return translation['LANGUAGE02'].name;
            else return translation['LANGUAGE02'].description;
          }
          if (translation != undefined && defalutLanguage!=undefined) {
            if (isName) return translation[stringify(defalutLanguage)].name;
            else return translation[stringify(defalutLanguage)].description;
          } else return '';
        }
      } else if (currentLanguage != 'es' && currentLanguage != 'fr') {
        if (translation != undefined) {
          if (isName) return translation[stringify(defalutLanguage)].name;
          else return translation[stringify(defalutLanguage)].description;
        } else return '';
      }
    }
  }
  else
  return "";
}
}