import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import {
  BaseComponent,
  LocationState,
  Location,
  SweetAlertService,
  WhitelabelState,
  Whitelabel,
  environment,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { Store, Select } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wj-app-header',
  templateUrl: './wj-header.component.html',
  styleUrls: ['./wj-header.component.scss'],
})
export class WJHeaderComponent extends BaseComponent implements OnInit {
  @Select(LocationState.getSelectedLocation) location$: Observable<Location>;
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  location: Location;
  organizationLogo: string = environment.image_basepath+'WJ_Logo.svg';

  logoIcon: string = environment.image_basepath + environment.images.logo_big;
  constructor(
    private router: Router,
    private store: Store,
    private sweetAlert: SweetAlertService
  ) {
    super();
    this.checkRoute();
    this.subscribeLocation();
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    // this.organizationLogo = environment.imagebaseurl + whitelabel.restaurantLogo;
  }

  ngOnInit() {}

  subscribeLocation() {
    this.location$.pipe(takeUntil(this.destroy$)).subscribe((location) => {
      if (location) {
        this.location = location;
      }
    });
  }
  checkRoute() {
    this.router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
      }
    });
  }
}
