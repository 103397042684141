import { Router } from '@angular/router';
import { OnInit, EventEmitter, Output } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

import {
  BaseComponent,
  environment,
  Regex,
  setUserDetailsOnLogin,
  ClearLoginDetails,
  AuthEmailState,
  AuthEmailLoginResponse,
  setExistingLoginData,
  CartState,
  LocationState,
  SharedService,
  ClearExistingLoginData,
  FetchSavedCards
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import * as CryptoJS from 'crypto-js';
import { Subscription } from 'rxjs';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
interface Credential {
  email: string;
  password: string;
}

export class EmailLoginBaseComponent extends BaseComponent implements OnInit {
  isRememberMe: Boolean = false;
  loginLeftArrowIcon: string =
  environment.image_basepath + environment.images.arrow_left_icon;
  emailRegex = Regex.email;
  userName: string = '';
  userLoggedIn: boolean = false;
  decryptedUserData;
  cipher = Config.cipher;
  authEmailSubscription: Subscription;
  existingDataSubscription: Subscription;
  formSubmitted: boolean = false;
  errorInLogin: string = '';
  _modalClose: any = null;
  isMobileView:boolean = this._shared.isMobileScreen();;
  loginForm = new FormGroup({
    email: new FormControl(
      '',
      Validators.compose([
        Validators.required,
        this._shared.emailpatternValidator(),
      ])
    ),
    password: new FormControl('', Validators.required),
    isRememberMe: new FormControl(this.isRememberMe),
  });
  credential: Credential = {
    email: '',
    password: '',
  };
  existinguserData;
  @Output() modalValue: EventEmitter<any> = new EventEmitter<any>();
  @Select(AuthEmailState.getFullLoginDetails) authEmail$: Observable<
    any
  >;
  @Select(AuthEmailState.getLoginData) loginData$: Observable<string>;

  constructor(
    public store: Store,
    public router: Router,
    private _shared: SharedService,
    public modal: ModalService
  ) {
    super();
    this.existingDataSubscription =this.loginData$.subscribe((res) => {
      this.existinguserData = res;
      if (this.existinguserData) {
        this.populateRememberedData(this.existinguserData);
      }
    });
  }

  populateRememberedData(loggedInData) {
    let loginData = this.decryptUserCredentials(loggedInData);
    this.loginForm.controls['email'].setValue(loginData.username);
    this.loginForm.controls['password'].setValue(loginData.password);
    this.loginForm.controls['isRememberMe'].setValue(true);
  }

  ngOnInit() {

  }

  ngOnDestroy() {
    if (this.authEmailSubscription) this.authEmailSubscription.unsubscribe();
    if (this.existingDataSubscription) this.existingDataSubscription.unsubscribe();
  }

  get loginFormControl() {
    return this.loginForm.controls;
  }

  loginUsingEmail() {
    this.formSubmitted = true;
    this.errorInLogin = '';
    this.store.dispatch(new ClearLoginDetails());
    if (this.loginForm.valid) {
      this.credential = this.loginForm.value;
      const loginData: any = Object.assign({}, this.credential);
      if (this.loginForm.value.isRememberMe) {
        this.saveUserCredentials(
          this.credential.email,
          this.credential.password
        );
      } else {
        this.store.dispatch(new ClearExistingLoginData());
      }
      loginData.username = loginData.email.toLowerCase();
      const selectedCart = this.store.selectSnapshot(CartState.getCart);
      if (selectedCart && selectedCart.cart_id) {
        loginData.cart_id = selectedCart.cart_id;
      }
      this.store.dispatch(new setUserDetailsOnLogin(loginData));
      this.authEmailSubscription = this.authEmail$.subscribe(
        (response) => {
          if (response) {
            if (
              response.status !== 401 &&
              response.status !== 409 &&
              response.status !== 404
            ) {
              this.userName = '';
              if (response.fname || response.lname) {
                this.userName += response.fname ? response.fname : '';
                this.userName += response.lname ? ' ' + response.lname : '';
              } else {
                this.userName = response.username;
              }
              this.userLoggedIn = response.isLoggedIn;
              response.username = this.credential.email;
              const selectedLocation = this.store.selectSnapshot(
                LocationState.getSelectedLocation
              );
              this.modal.closeAllModals();
              // this.store.dispatch(new ClearSelectedAddress());
              if(this.isMobileView)
              this.router.navigate([`/locations/${selectedLocation._id}/home`]);
            } else if(response['status']==401) {
              if(response['message'])
              this.errorInLogin = response['message'];
              else
              this.errorInLogin = 'Email ID/Password is incorrect';
            } else {
              this.errorInLogin = response['msg'];

            }
           if(response.status ===200)
            this.store.dispatch(new FetchSavedCards());
          }
        },
        (error) => {
          this.errorInLogin = error['msg'];
        }
      );
    }
  }

  saveUserCredentials(username, password) {
    let userdetails = {
      username: username,
      password: password,
    };
    let ciphertext = CryptoJS.AES.encrypt(
      JSON.stringify(userdetails),
      this.cipher
    );
    this.store.dispatch(new setExistingLoginData(ciphertext.toString()));
  }

  decryptUserCredentials(res) {
    let userDetailsStored = res;
    let bytes = CryptoJS.AES.decrypt(userDetailsStored, this.cipher);
    let userDetailsStr = bytes.toString(CryptoJS.enc.Utf8);
    let userDetails = JSON.parse(userDetailsStr);
    return userDetails;
  }

  //to be added to user profiel page
  userLogout() {
    this.store.dispatch(new ClearLoginDetails());
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    this.router.navigate([`/locations/${selectedLocation._id}/home`]);
  }
  closLogin() {
    this.modal.closeAllModals();
  }
  goBack() {
    this.modalValue.emit('CLOSE');
    this.closLogin();
  }
}
