import { Component } from '@angular/core';
import { ListCartBaseComponent } from '@moduurnv2/libs-orderingapp/src/features';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import {
  SetItemDetails,
  CartState,
  DeliveryState,
} from '@moduurnv2/libs-orderingapp/src';
import { WJModifierComponent } from '../../../shared/wj-modifier/wj-modifier.component';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { SharedService } from '@moduurnv2/libs-orderingapp';
@Component({
  selector: 'wj-list-cart',
  templateUrl: './wj-list-cart.component.html',
  styleUrls: ['./wj-list-cart.component.scss'],
})
export class WJListCartComponent extends ListCartBaseComponent {
  // deliveryType: string;
  // showModifier: boolean = false;
  // menuId:string;
  constructor(
    private store: Store,
    private router: Router,
    private modal: ModalService,
    private shared: SharedService
  ) {
    super();
    // this.deliveryType = this.store.selectSnapshot(
    //   DeliveryState.getSelectedDelivery
    // ).textCode;
  }

  editListCart(item) {
    const menuDetails = {
      icons: item.menuItem.icons,
      description: item.menuItem.description,
      imageURL: item.menuItem.image,
      name: item.menuItem.name,
      _id: item.menuItem._id,
      cartDetails: item,
      translation:item.menuItem.translation
    };

    this.store.dispatch(new SetItemDetails(menuDetails));
    if (item.menuItem._id) {
        // this.showModifier = true;
        // this.menuId = item.menuItem._id;
        this.modal.showModifierModal(WJModifierComponent,{
          data:{
            menuItemId:item.menuItem._id,
            isEdit:true
        }})
    }
  }

  checkModifier(item) {
    const { menuItem } = item;
    if (menuItem)
      return menuItem.modifiers.length > 0 || menuItem.portions.length > 1;
    return false;
  }
  getItemId(portion) {
    if (portion) return portion._id;
    else return null;
  }
  itemTotalPrice(item) {
    const cartItemPrice = this.store.selectSnapshot(
      CartState.getCartPriceItems
    );
    if (!cartItemPrice) return 0;
    // Need clarification from sonia on MV2-407 point 9 before removing the comment
    // let matchingItem;
    // if (item.portion)
    //   matchingItem = cartItemPrice.find(
    //     (eachItem) =>
    //       eachItem.itemId === item.menuItem._id &&
    //       eachItem.portion &&
    //       item.portion._id === this.getItemId(eachItem.portion)
    //   );
    // else {
    //   matchingItem = cartItemPrice.find(
    //     (eachItem) => eachItem.itemId === item.menuItem._id
    //   );
    // }
    if(cartItemPrice) {
      const matchingItem = cartItemPrice.find(
        (eachItem) => eachItem.id === item._id
      );
      if (matchingItem) return matchingItem.totalBeforetax;
    }

    return 0;
  }

  itemPrice(item) {
    const cartItemPrice = this.store.selectSnapshot(
      CartState.getCartPriceItems
    );
    if (cartItemPrice) {
      const matchingItem = cartItemPrice.find(
        (eachItem) => eachItem.id === item._id
      );
      if (matchingItem) return matchingItem.price;
    }
    return 0;
  }

  // closeModal(){
  //   this.showModifier=false;
  // }
}
