import { Output, Input, EventEmitter, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import {
  BaseComponent,
  environment,
  FetchAddressList,
  AddressState,
  Address,
  ChangeMapPopupStatus,
  AuthState,
  Auth,
  LocationState,
  FetchDeliveryAddressDetails,
  AddressData,
} from '@moduurnv2/libs-orderingapp/src/core';
import { takeUntil } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
export class AddressListBaseComponent extends BaseComponent implements OnInit {
  @Output() closeEvent = new EventEmitter();
  viewMapModalStatus: boolean = false;
  @Input() modalChangeAdrsStatus: boolean;
  @Input() isAddressBook: boolean = false;
  @Select(AddressState.getMapPopUpStatus) getMapPopUpStatus$: Observable<
    boolean
  >;
  @Select(AddressState.getAddressList) addressList$: Observable<Address[]>;
  @Select(AddressState.getAddressListFromLocal)
  addressListLocal$: Observable<Address[]>;
  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;
  adrsList: any;
  adrsDetailsList: Address;
  imgBasePath: string = environment.image_basepath;
  icon = {
    add: this.imgBasePath + environment.images.add,
    close: this.imgBasePath + environment.images.close,
    back: this.imgBasePath + environment.images.back_arrow,
    add_red: this.imgBasePath + environment.images.add_red,
  };
  loggedInUserDetails: any;
  ngOnInit() {
    this.subscribeMapPopupStatus();
    this.subscribeaddressList();
  }
  constructor(
    public store: Store,
    private httpresult: HttpClient,
    public modal: ModalService
  ) {
    super();
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer && customer.customerId) {
      this.store.dispatch(new FetchAddressList());
    }
  }
  openMap() {
    this.store.dispatch(new ChangeMapPopupStatus(true));
  }
  closeMap() {
    this.store.dispatch(new ChangeMapPopupStatus(false));
  }
  subscribeMapPopupStatus() {
    this.getMapPopUpStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.viewMapModalStatus = status;
      });
  }
  subscribeaddressList() {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer && customer.customerId) {
      this.addressList$.pipe(takeUntil(this.destroy$)).subscribe((data) => {
        this.adrsList = data;
        if (this.adrsList) {
          this.calculateDistance();
        }
      });
    } else {
      this.addressListLocal$
        .pipe(takeUntil(this.destroy$))
        .subscribe((data) => {
          this.adrsList = data;
          if (this.adrsList) {
            this.calculateDistance();
          }
        });
    }
  }
  calculateDistance() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let adrsdataObj: AddressData = {
      locationId: selectedLocation._id,
      locationCoordinates: selectedLocation['loc'],
      addressCoordinates: [],
    };
    for (let i = 0; i < this.adrsList.length; i++) {
      adrsdataObj.addressCoordinates.push({
        id: this.adrsList[i]._id,
        loc: {
          lng: this.adrsList[i]['loc'][0],
          lat: this.adrsList[i]['loc'][1],
        },
      });
    }
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (customer && customer.customerId) {
      this.store
        .dispatch(new FetchDeliveryAddressDetails(adrsdataObj))
        .toPromise()
        .then((data) => (this.adrsDetailsList = data.address.addressList));
    } else {
      this.store
        .dispatch(new FetchDeliveryAddressDetails(adrsdataObj))
        .toPromise()
        .then((data) => (this.adrsDetailsList = data.address.addressListLocal));
    }
  }
}
