import {
  BaseComponent,
  ClearAllVenueDatas,
  environment,
  FetchLocationDefaults,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, Input } from '@angular/core';
import {
  SetSelectedLocation,
  ClearCart,
  WhitelabelState,
  CartState,
  Cart,
  LocationState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core';
import { TranslateService } from '@ngx-translate/core';
export class LocationBaseComponent extends BaseComponent {
  constructor(
    public router: Router,
    public store: Store,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService
  ) {
    super();
  }
  @Input() location;
  // showLocationInfoModal: boolean = false;
  imgBasePath: string = environment.image_basepath;
  icon = {
    orderHere: this.imgBasePath + environment.images.order_here,
    orderHereWhite: this.imgBasePath + environment.images.order_here_white,
    favoriteIcon: this.imgBasePath + environment.images.favorite_icon,
    nonFavoriteIcon: this.imgBasePath + environment.images.nonFavorite_icon,
    orderForLater: this.imgBasePath + environment.images.order_for_later,
    orderForLaterInactive:
      this.imgBasePath + environment.images.order_for_later_inactive,
    phone: this.imgBasePath + environment.images.phone,
  };
  unitOfDistance: string = 'miles';
  ngOnInit(): void {
    let whiteLabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    this.unitOfDistance = whiteLabel.distance;
  }
  toggleLocationFavorite(locId, isFavorite) {
    //ADD API TO MAKE LOCATION FAVORITE OR NOT
  }
  async orderFromOrganization(location) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (location.isLocationOpen) {
      if (selectedLocation && selectedLocation._id != location._id) {
        let selectedCart = this.store.selectSnapshot(CartState.getCart);
        if (selectedCart) {
          let vm = this;
          await this.sweetAlert
            .confirm(
              this.translate.instant('are-you-sure'),
              this.translate.instant('loc-change-clear-cart')
            )
            .then((choice) => {
              if (choice) {
                this.router.navigate([`locations/${location._id}/home`]);
                this.store.dispatch(new SetSelectedLocation(location._id));
                this.store.dispatch(new FetchLocationDefaults());
                this.store.dispatch(new ClearAllVenueDatas());
              } else {
                return;
              }
            });
        } else {
          this.router.navigate([`locations/${location._id}/home`]);
          this.store.dispatch(new SetSelectedLocation(location._id));
          this.store.dispatch(new FetchLocationDefaults());
          this.store.dispatch(new ClearAllVenueDatas());
        }
      } else {
        this.store.dispatch(new SetSelectedLocation(location._id));
        this.store.dispatch(new FetchLocationDefaults());
        this.router.navigate([`locations/${location._id}/home`]);
        this.store.dispatch(new SetSelectedLocation(location._id));
      }
    }
  }
}
