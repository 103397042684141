import {
  NgModule,
  APP_INITIALIZER,
} from '@angular/core';
import { CommonModule } from '@angular/common';
// libs
import {
  AppInitService,
  environment,
} from '@moduurnv2/libs-orderingapp/src/core';
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin';

// app
import { CoreModule } from './core/core.module';
import { SharedModule } from './features/shared/shared.module';
import { WJAppRoutingModule } from './wj-app.routing';
import { WJAppComponent } from './wj-app.component';
import { Config } from '../config';
import { UISharedModule } from '@moduurnv2/features';
import { NgxsModule } from '@ngxs/store';

import { AnalyticsHandler } from '@moduurnv2/core/services';
import {
  MainMenuState,
  ClearLoginDetails,
  CartState,
  LocationDefaultState,
  VenueManagementState
} from '@moduurnv2/libs-orderingapp/src/core';

import { NGXS_PLUGINS } from '@ngxs/store';
import { getActionTypeFromInstance } from '@ngxs/store';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxSpinnerModule } from 'ngx-spinner';
export function performStoreOperations(state, action, next) {
  if (getActionTypeFromInstance(action) == ClearLoginDetails.type) {
    // if we are a logout type, lets erase all the state
    if(state.authEmail){
    state.authEmail.login = {};
    state.authEmail.signUp = {};
    state.authEmail.forgotPassword = undefined;
    state.authEmail.resetPasswordLinkValidity = undefined;
    state.authEmail.resetPassword = undefined;
    state.authEmail.customerId = undefined;
    }
    if(state.paymentMethods){
    state.paymentMethods.paymentList = undefined;
    state.paymentMethods.selectedPayment = undefined;
    state.paymentMethods.savedCards = undefined;
    }
    if(state.cart){
    state.cart.cart = undefined;
    state.cart.cartItems = undefined;
    state.cart.cartResponse = undefined;
    state.cart.tip = undefined;
    state.cart.paymentGateway = undefined;
    }
    state.auth = {};
    localStorage.setItem('selectedAdrsLocation', undefined);
    if(state.address){
    state.address.isDeliverable = false;
    }
  }

  return next(state, action);
}
import { environment as QA } from '../environments/environment';
import { ModalModule } from '@moduurnv2/web/features/ui/components/modal/modal.module';
import { WJTableEmptyComponent } from './features/wj-table-empty/wj-table-empty.component';

@NgModule({
  imports: [
    NgxsModule.forFeature([
      MainMenuState,
      CartState,
      LocationDefaultState,
      VenueManagementState
    ]),
    CoreModule,
    CommonModule,
    SharedModule,
    WJAppRoutingModule,
    UISharedModule,
    NgxsReduxDevtoolsPluginModule.forRoot(),
    BrowserAnimationsModule,
    NgxSpinnerModule,
    ModalModule,
  ],
  declarations: [WJAppComponent, WJTableEmptyComponent],
  bootstrap: [WJAppComponent],
  providers: [
    {
      provide: APP_INITIALIZER,
      useFactory: (initService: AppInitService) => () =>
        initService.load(Config.organizationId).toPromise(),
      deps: [AppInitService],
      multi: true,
    },
    AnalyticsHandler,
    {
      provide: NGXS_PLUGINS,
      useValue: performStoreOperations,
      multi: true,
    },
  ],
  // schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class WJAppModule {}
