import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { PlaceOrder, OrderResponse, Venue } from '../models';
import {
  SetOrder,
  ClearOrderResponseMessage,
  SetOrderDetails,
  changeShowStatus,
  fromOrderSuccess,
  ChangeCategory,
} from '../actions';
import { VenueManagementState } from './venue-management.state';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { OrderService, VenueManagementService } from '../services';

export class OrderStateModel {
  orderDetails: any;
  placeOrder: PlaceOrder;
  orderResponse: OrderResponse;
  showOrderStatus: boolean;
  isFromOrderSuccess: boolean;
}

@State<OrderStateModel>({
  name: 'order',
  defaults: {
    orderDetails: null,
    placeOrder: {
      pushNotification: {
        key: '',
        icon: '',
        click_action: '',
      },
      paymentType: '',
      locationId: '',
      order: {
        orderVia: '',
        orderedBy: {
          guest: false,
          name: '',
          phone: '',
          userId: '',
          email: '',
        },
        orderLater: false,
        cartId: '',
        organizationId: '',
        locationId: '',
        specialInstructions: '',
        deliveryMethod: '',
        deliveryTime: '',
        deliveryAddress: {
          instructions: '',
          buzzerNumber: '',
          unitNumber: '',
          postalcode: '',
          country: '',
          state: '',
          city: '',
          streetAddress: '',
          _id: '',
          loc: [],
          addressType: '',
          deliverable: false,
          distance: '',
          regionCode : '',
        },
      },
      additionalParams: null,
      timeInfo: {
        locationId: '',
        type: '',
        currentTime: '',
        future: '',
        timezoneOffset: 0,
        selectedTime: '',
      },
    },
    orderResponse: {
      msg: '',
      orderNumber: '',
      order_id: '',
      status: 0,
      message: '',
      errorCode: '',
    },
    showOrderStatus: false,
    isFromOrderSuccess: false,
  },
})
@Injectable()
export class OrderState {
  constructor(
    private orderService: OrderService,
    private store: Store,
    private _venue: VenueManagementService
  ) {}

  @Selector()
  static getOrderResponse(state: OrderStateModel) {
    return state.orderResponse;
  }
  @Selector()
  static getOrderDetails(state: OrderStateModel) {
    return state.orderDetails;
  }
  @Selector()
  static getShowstatus(state: OrderStateModel) {
    return state.showOrderStatus;
  }
  @Selector()
  static getOrderSuccess(state: OrderStateModel) {
    return state.isFromOrderSuccess;
  }
  @Action(SetOrder)
  SetOrder(
    { patchState }: StateContext<OrderStateModel>,
    { payload }: SetOrder
  ) {
    if (
      payload?.order?.deliveryMethod &&
      (payload.order.deliveryMethod == 'DELIVERYTYPE03' ||
        payload.order.deliveryMethod == 'DELIVERYTYPE08' ||
        payload.order.deliveryMethod == 'DELIVERYTYPE09' ||
        payload.order.deliveryMethod == 'DELIVERYTYPE10')
    ) {
      payload.order['table'] = this.setVenueData(payload.order.deliveryMethod);
      if(payload.order.deliveryMethod == 'DELIVERYTYPE08')
      payload.paymentType = "payLater";
    }
    return this.orderService.placeOrder(payload).pipe(
      tap((response) => {
        if (response) {
          patchState({
            orderResponse: response,
          });
          if (response.status == 1) {
            this.orderService.clearCurrentCart(
              payload.locationId,
              payload.order.cartId
            );
            this.store.dispatch(new ChangeCategory(null));
          }
        } else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  setVenueData(deliveryMethodTextCode) {
    let selectedVenue: Venue;
    selectedVenue = this.getVenueByDeliveryTypeTextCode(deliveryMethodTextCode);
    let venue = undefined;
    if (selectedVenue) {
      venue = {
        name: selectedVenue.name,
        guestCount: selectedVenue.guestCount ? selectedVenue.guestCount : undefined,
        type: selectedVenue.type ? selectedVenue.type['_id'] : undefined,
      };
    }
    return venue;
  }

  getVenueByDeliveryTypeTextCode(deliveryType) {
    let selectedVenue;
    switch (deliveryType) {
      case 'DELIVERYTYPE08':
        selectedVenue = this.store.selectSnapshot(
          VenueManagementState.getSelectedLoungeTable
        );
        break;
      case 'DELIVERYTYPE09':
        selectedVenue = this.store.selectSnapshot(
          VenueManagementState.getSelectedPickUpLocation
        );
        break;
      case 'DELIVERYTYPE10':
        selectedVenue = this.store.selectSnapshot(
          VenueManagementState.getSelectedRoom
        );
        break;
      default:
        break;
    }
    return selectedVenue;
  }

  @Action(SetOrderDetails)
  SetOrderDetails({ patchState }: StateContext<OrderStateModel>, { payload }) {
    patchState({
      orderDetails: payload,
    });
  }

  @Action(ClearOrderResponseMessage)
  ClearOrderResponseMessage(
    { getState, patchState }: StateContext<OrderStateModel>,
    {}: ClearOrderResponseMessage
  ) {
    patchState({
      orderResponse: {
        msg: '',
        orderNumber: '',
        order_id: '',
        status: 0,
        message: '',
        errorCode: '',
      },
    });
  }

  @Action(changeShowStatus)
  changeShowStatus(
    { setState }: StateContext<OrderStateModel>,
    { payload }: changeShowStatus
  ) {
    setState(
      patch({
        showOrderStatus: payload,
      })
    );
  }
  @Action(fromOrderSuccess)
  fromOrderSuccess(
    { setState }: StateContext<OrderStateModel>,
    { payload }: fromOrderSuccess
  ) {
    setState(
      patch({
        isFromOrderSuccess: payload,
      })
    );
  }
}
