import { State, Selector, StateContext, Action ,Store} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { ViewAllOrders } from '../models';
import { FetchViewAllOrdersList,FetchInProgressOrderList } from '../actions';
import { Injectable } from '@angular/core';
import { ViewAllOrdersService } from '../services';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import {AuthState} from './auth.state';
import {SelectedOrderState} from './selected-order.state';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';

export class ViewAllOrdersStateModel {
  viewAllOrdersList: ViewAllOrders[];
  inProgressOrderList:ViewAllOrders[]
}

@State<ViewAllOrdersStateModel>({
  name: 'viewallorders',
  defaults: {
    viewAllOrdersList: undefined,
    inProgressOrderList: undefined
  },
})
@Injectable()
export class ViewAllOrdersState {

  constructor(private viewAllOrders: ViewAllOrdersService,private store:Store) { }

  @Selector()
  static getViewAllOrdersList(state: ViewAllOrdersStateModel) {
    return state.viewAllOrdersList;
  }

  @Selector()
  static getInProgressOrderList(state: ViewAllOrdersStateModel) {
    return state.inProgressOrderList;
  }

  // @Selector()
  // static getSelectedLocation(state: ViewAllOrdersStateModel) {
  //   return state.selectedLocation;
  // }

  @Action(FetchViewAllOrdersList)
  fetchViewAllOrders({ setState }: StateContext<ViewAllOrdersStateModel>,{ payload }: any) {
    let custId=payload.custId//;// = '5e706d159c7d555586050ba4';
    let orgId=payload.orgId;// = '5a5480ee2c393854d50189e4';
    if(custId){
    return this.viewAllOrders.fetchViewAllOrders(custId,orgId).pipe(
      tap((response) => {
        if (response) {
          setState(
            patch({
              viewAllOrdersList: response,
            })
          );
        }
        else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }
}

@Action(FetchInProgressOrderList)
  fetchInProgressOrderList({ setState }: StateContext<ViewAllOrdersStateModel>,{ payload }: any) {
   let body ={
    customerId: payload && payload.custId ? payload.custId :null,
    orderIds: payload,
    organizationId: null
   }
  return this.viewAllOrders.fetchInprogressOrders(body).pipe(
    tap((response) => {
      if (response && response['orders']) {
        setState(
          patch({
            inProgressOrderList: response['orders'],
          })
        );
      }
      else throw response;
    }),
    catchError((error) => {
      return Observable.throw(error);
    })
  );
}

}
