import { State, Selector } from '@ngxs/store';
import { Category } from '../models';
import { Injectable } from '@angular/core';

export class CategoryStateModel {
    category: Category;
    selectedCategory: Category;
}

@State<CategoryStateModel>({
  name: 'category',
  defaults: {
    selectedCategory: null,
    category: { _id: '', name: '', description: '', items: undefined, translation:'' },
  }
})
@Injectable()
export class CategoryState {
  constructor() {}

  @Selector()
  static getCategoryList(state: CategoryStateModel) {
    return state.category;
  }
  
}