import { Output, Input } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import {
  BaseComponent,
  PaymentMethodState,
  SetPaymentMethod,
  FetchSavedCards,
  SetSelectedCard,
  TokenizeCard,
  LocationState,
  NewCard,
  ClearSelectedCard,
  RemoveCard,
  SavedCard,
  SweetAlertService,
  AuthState,
  LocationDefaultState,
  LocationDefaults,
  FetchLocationDefaults,

} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import {
  paymentCardType,
  paymentMethods,
} from './../../../core/services/constant';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { Auth } from './../../../core/models';
import { Subscription } from 'rxjs';
export class PaymentMethodBaseComponent extends BaseComponent {
  @Output() closeEvent = new EventEmitter();
  @Output() desktopView = new EventEmitter();
  @Input() paymentView: boolean = true;
  @Input('cards') cards:any;
  @Select(PaymentMethodState.getPaymentsList) paymentList$;
  @Select(PaymentMethodState.getSavedCards) savedCards$: Observable<
    SavedCard[]
  >;
  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;

  authSubscription: Subscription;
  loggedInUserDetails;
  @Select(LocationDefaultState.getLocationDefaults) locationDefaults$: Observable<
  LocationDefaults
>;

  bannerImage = 'assets/images/Payment-Banner.png';

  selectedCard = {
    card_id: '',
    function: '',
    name: '',
    number: '',
    expiry_month: '',
    expiry_year: '',
    card_type: '',
  };

  paymentDetails = [];
  paymentList;
  isMobileView:boolean=true;
  card: NewCard;
  paymentMethods = [];
  isProfile = false;
  hideSavedCard:boolean= false;
  constructor(
    public store: Store,
    private router: Router,
    public sweetAlert: SweetAlertService,
    private shared: SharedService,
    public modal: ModalService
  ) {
    super();
    this.authSubscription = this.loggedInUserDetails$.subscribe((res) => {
      this.loggedInUserDetails = res;
    });
    this.paymentList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentList) => {
        if (paymentList) {
          this.paymentList = paymentList;
        }
      });

    this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((cardList) => {
      if (cardList) {
        this.paymentDetails = cardList;
        if(this.isProfile && this.paymentDetails.length){
         // this.selectedCard =  this.paymentDetails[0]; open default
        }
        else{
          this.selectedCard = {
            card_id: '',
            function: '',
            name: '',
            number: '',
            expiry_month: '',
            expiry_year: '',
            card_type: '',
          };

        }
      }
    });
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    if(selectedDelivery.textCode == 'DELIVERYTYPE02') {
      paymentMethods.forEach(item => {
        if(item.id == 5) {
        item.text =  'Pay at Location';
        item.name =  'Pay at Location';
        }
      });
    }else if(selectedDelivery.textCode == 'DELIVERYTYPE08' || selectedDelivery.textCode == 'DELIVERYTYPE09') {
      paymentMethods.forEach(item => {
        if(item.id == 5) {
        item.text =  'Pay the Server';
        item.name =  'Pay the Server';
        }
      });
    } else {
      paymentMethods.forEach(item => {
        if(item.id == 5) {
        item.text =  'Cash on Delivery';
        item.name =  'Cash on Delivery';
        }
      });
    }
    this.locationDefaults$.pipe(takeUntil(this.destroy$)).subscribe((locationDefaults) => {
      if (location) {
      let modes = locationDefaults.locationData.orderSettings.paymentModes;

      this.paymentMethods = paymentMethods.map(value => {
          if(modes[value.type]) {
              value["acive"] = true;

          }
          else{
              value["acive"] = false;
          }
          return value;
        });

      }
    });
    this.checkSavedCards();
    this.isProfile = this.router.url.split('/').pop() === 'payment-profile';
  }

    ngOnInit(): void {
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      if(customer && customer.customerId){
        this.store.dispatch(new FetchSavedCards());
      }
      this.store.dispatch(new FetchLocationDefaults());
      this.isMobileView = this.shared.isMobileScreen();
    }

  onClick(item) {
    if(item.id===5)
    this.hideSavedCard =true;
    else this.hideSavedCard = false;
    switch (true) {
      case item.id == 1: {
        break;
      }

      case item.id == 2: {
        break;
      }
      case item.id == 3: {
        this.isMobileView = this.shared.isMobileScreen();
        this.store.dispatch(new ClearSelectedCard());
        this.desktopView.emit({isdesktop:this.isMobileView,type:3});
        if(this.isMobileView)
        this.addCard();
        break;
      }

      case item.id == 4: {
        break;
      }
      case item.id == 5:
        let paymentMethod = this.paymentMethods.find(
          (method) => method.id == 5
        );
        this.store.dispatch(new ClearSelectedCard());
        this.store.dispatch(new SetPaymentMethod(paymentMethod));
        const selectedLocation = this.store.selectSnapshot(
          LocationState.getSelectedLocation
        );
        this.desktopView.emit({isdesktop:this.isMobileView,type:5,savedCard:false});
        if (selectedLocation._id) {
          if(this.isMobileView)
          this.router.navigate([`/locations/${selectedLocation._id}/order`]);
        }
        break;
    }
  }

  addCard() {
    this.router.navigate([`/payment/add-card`]);
  }

  getCardStyle(index: number) {
    this.paymentList$.paylater = false;

    if (index % 2 == 0) {
      return {
        background: `linear-gradient( 243deg, #ffd1cc 0%, #ccf0ff 100% )`,
      };
    } else {
      return {
        background: `linear-gradient( 243deg, #ccffd5 0%, #daccff 100% )`,
      };
    }
  }
  checkSavedCards() {
    this.savedCards$.pipe(takeUntil(this.destroy$)).subscribe((cardList) => {
      if (cardList) {
        this.paymentDetails = cardList;
      }
    });
  }

  getCard(card_type) {
    //  visa

    if (card_type == 'VI') {
      return paymentCardType.visa;
    }

    // Mastercard

    if (card_type == 'MC') {
      return paymentCardType.mastercard;
    }

    // AMEX
    if (card_type == 'AM') {
      return paymentCardType.amex;
    }

    // Discover

    if (card_type == 'NN') {
      return paymentCardType.discover;
    }

    return null;
  }

  selectCard(card) {
    if(this.isProfile){
    this.selectedCard = card;
    return;
    }
    card.cardType = this.getCard(card.card_type).type;
    this.store.dispatch(new SetSelectedCard(card));
    let paymentMethod = this.paymentMethods.find((method) => method.id == 3);
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
    this.closeEvent.emit();
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if(!this.isMobileView) {
      this.desktopView.emit({isdesktop:this.isMobileView,type:5,savedCard:true});
    }
   else if (selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/order`]);
    }
  }

  payUsingNewCard(card) {
    this.store.dispatch(new TokenizeCard(card));
  }

  deleteCard(card) {
    this.sweetAlert
      .confirmDelete('Are you sure?', 'Do you want to remove this card ?')
      .then((choice) => {
        if (choice) {
          this.store.dispatch(new RemoveCard(card));
        }
      });
  }
  unselectCard(card) {
    this.selectedCard = {
      card_id: '',
      function: '',
      name: '',
      number: '',
      expiry_month: '',
      expiry_year: '',
      card_type: '',
    };
  }
  ngOnDestroy() {
    if (this.authSubscription) this.authSubscription.unsubscribe();
  }
}
