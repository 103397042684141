// angular
import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import {
  SetCartItems,
  FetchCart,
  FetchCartMenuItems,
} from '../actions/cart.actions';
import { Observable, throwError } from 'rxjs';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { CartResponse } from '../models';
import { switchMap, map } from 'rxjs/operators';
import { FetchHomeData, FetchMenuList } from '../actions';
@Injectable({
  providedIn: 'root',
})
export class CartService {
  constructor(private http: HttpClient, private store: Store) {}

  getCartItems(cart_id, organizationId) {
    const cartitems = {
      cartId: cart_id,
      organizationId: organizationId,
    };
    this.store.dispatch(new SetCartItems(cartitems));
  }
  createNewCart(payload, cart_id) {
    let url: string = `${environment.cartApiUrl}`;
    let locationId = payload.locationId;
    if (cart_id) {
      url = `${environment.cartApiUrl}${cart_id}/menuItems`;
      payload = payload.menuItems;
    }
    return Observable.create((observer) => {
      this.http.post(url, payload).subscribe(
        (result) => {
          if (!cart_id) cart_id = result['cart_id'];
          if (!cart_id) return observer.next(result);
          this.getCartDetails(cart_id, locationId).subscribe(
            (res) => {
              observer.next(res);
            },
            (error) => {
              observer.next(error);
            }
          );
        },
        (error) => {
          observer.next(error);
        }
      );
    });
  }
  updateCart(payload) {
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    const url = `${environment.cartApiUrl}${payload['cartId']}/menuItem/${payload['menuItemId']}`;
    const data = { count: payload['count'] };

    if (payload['count'] === 0) {
      return Observable.create((observer) => {
        this.http.delete(url).subscribe(
          (deleteResult) => {
            this.getCartDetails(payload['cartId'], locationId).subscribe(
              (res) => {
                observer.next(res);
              },
              (error) => {
                observer.next(error);
              }
            );
          },
          (error) => {
            observer.next(error);
          }
        );
      });
    } else {
      return Observable.create((observer) => {
        this.http.put(url, data).subscribe(
          (result) => {
            this.getCartDetails(payload['cartId'], locationId).subscribe(
              (res) => {
                observer.next(res);
              },
              (error) => {
                observer.next(error);
              }
            );
          },
          (error) => {
            observer.next(error);
          }
        );
      });
    }
  }

  updateCount(payload) {
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    const { cartId, menuItemId, count } = payload;
    const url = `${environment.cartApiv3Url}${cartId}/menuItem/${menuItemId}`;
    const deleteUrl = `${environment.cartApiUrl}${cartId}/menuItem/${menuItemId}`;
    if (count === 0)
      return this.http.delete(deleteUrl).pipe(
        switchMap((result) => {
          return this.getCartDetails(payload['cartId'], locationId);
        })
      );
    else
      return this.http.put(url, { count }).pipe(
        switchMap((result) => {
          return this.getCartDetails(payload['cartId'], locationId);
        })
      );
  }

  getCartDetails(cartId, locationId) {
    let deliveryLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    let lat = deliveryLocation ? deliveryLocation['loc'][1] : undefined;
    let long = deliveryLocation ? deliveryLocation['loc'][0] : undefined;

    let distance = localStorage.getItem('locationDistance')
      ? JSON.parse(localStorage.getItem('locationDistance'))
      : undefined;
    let url = `${environment.cartApiUrl}${cartId}?locationId=${locationId}`;
    if (lat && long && distance)
      url += `&lat=${lat}&long=${long}&distance=${distance * 1000}`;
    const { futureDate } = this.store.selectSnapshot((app) => app.cart);
    if (futureDate)
      url += `&selectedTime=${new Date(futureDate).toISOString()}`;
    return this.http.get(url);
  }

  getCartItemDetails(payload) {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    let url = `${environment.cartApiUrl}${payload.cartId}/organization/${Config.organizationId}?getOrgConfigs=true`;
    const { futureDate } = this.store.selectSnapshot((app) => app.cart);
    if (futureDate)
      url += `&selectedTime=${new Date(futureDate).toISOString()}`;
    if (selectedDelivery && selectedDelivery.textCode)
      url += `&delivery=${selectedDelivery.textCode}`;

    let deliveryLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    if (selectedDelivery && selectedDelivery.textCode == 'DELIVERYTYPE01')
      url += `&lat=${deliveryLocation['loc'][1]}&lng=${deliveryLocation['loc'][0]}`;
    return this.http.get(url);
  }

  addTip(payload) {
    const cart = this.store.selectSnapshot((app) => app.cart.cart);
    const url = `${environment.cartApiUrl}${cart.cart_id}`;
    return this.http.put<CartResponse>(url, payload);
  }

  checkFuture(futureTime, locationId, cartId) {
    const url = `${environment.mobileStoreApiUrl}stores/location/${locationId}/checkFuture`;
    const data = {
      futureDate: futureTime,
      offset: new Date().getTimezoneOffset(),
    };
    if (cartId && !cartId['errorCode'])
      return this.http.post(url, data).pipe(
        switchMap((response) => {
          const { isBlackout } = response as any;
          if(isBlackout !== true){
            const url = `${environment.cartApiUrl}${cartId}/futurePickupTime`;
            const data = {
              pickupTime: futureTime,
              offset: new Date().getTimezoneOffset(),
              isFuture: true,
            };
            return this.http.put(url, data);
          }else{
            return throwError(response);
          }
        })
      );
    else return this.http.post(url, data);
  }

  fetchPrepTime(url: string) {
    return this.http.get(url);
  }

  updateCartItem(updatedItem, cartId) {
    const url = `${environment.cartApiUrl}${cartId}/menuItems`;
    return this.http.put(url, updatedItem);
  }

  fetchETA() {
    let location = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    let delivery = this.store.selectSnapshot(
      (app) => app.delivery.selectedDelivery
    ).textCode;
    const url = `${environment.mobileStoreApiUrl}stores/location/${location._id}/prep?deliveryMethod=${delivery}`;
    return this.http.get(url);
  }

  futureDateChanged() {
    const state = this.store.selectSnapshot((app) => app);
    const { home, menulist, cart } = state;
    if (home) this.store.dispatch(new FetchHomeData());
    if (menulist && menulist.menuList) this.store.dispatch(new FetchMenuList());
    if (cart && cart.cart) {
      this.store.dispatch(new FetchCart());
      this.store.dispatch(new FetchCartMenuItems({}));
    }
  }
}
