import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { VenueManagementState } from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { WJPersonCountComponent } from '../wj-app/features/wj-home/wj-person-count/wj-person-count.component';
@Injectable()
export class WJPersonPerTableGuardService implements CanActivate {
  personPerTableEntered: Boolean = true;
  CustomerNoTable: Number;
  PersonNumber;
  @Select(VenueManagementState.getCustomersPerTable)
  customerNoPerTable$: Observable<any>;
  constructor(
    public store: Store,
    public router: Router,
    private modal: ModalService
  ) {
    this.customerNoPerTable$.subscribe((persons) => {
      if (persons) {
        this.personPerTableEntered = true;
      } else {
        this.personPerTableEntered = false;
      }
    });
  }

  customerNoPerTable: any = {};
  _modalClose: any = null;
  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
  canActivate(): any {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const totalPersonPerTable = this.store.selectSnapshot(
      (app) => app && app.venuemanagement && app.venuemanagement.totalPersonPerTable
    );
    
    if(!this.personPerTableEntered){
      this.personPerTableEntered = totalPersonPerTable && totalPersonPerTable.count ? true : false
    }
    if (selectedDelivery.textCode == 'DELIVERYTYPE08') {
      if (this.personPerTableEntered) {
        return true;
      } else {
        this.modal.showSmallFormModal(WJPersonCountComponent);
        return false;
      }
    } else {
      return true
    }
  }
}
