<div class="modifier-container">
  <div class="header" [ngStyle]="getStyle()">
    <div
      class="close"
      (click)="closePopup()"
      data-qa="close-button"
      aria-label="close"
    >
      <span class="text">{{ 'close' | translate }}</span>
      <img [src]="close" />
    </div>
  </div>
  <ng-container
    *ngIf="selectedMenuItem$ | async as selectedMenuItem; else modifierLoader"
  >
    <div class="modifier-wrapper">
      <ng-container *ngIf="details$ | async as itemDetail">
        <div
          *ngIf="itemDetail?.imageURL"
          class="modifier-banner"
          [bg-image]="imageBase + itemDetail?.imageURL"
        ></div>
        <div class="modifier-header" [class.no-img-header]="!itemDetail.imageURL">
          <div class="item-name" [attr.data-qa]="selectedMenuItem?.name" [class.margin-b-zero]="itemDetail.icons && itemDetail.icons.length==0">
            {{ itemDetail.name | formattedLanguage: itemDetail.translation }}
          </div>
          <wj-icon-list
          [icons]="itemDetail?.icons"
          [showText]="true"
          ></wj-icon-list>
        </div>
        <div class="modifier-details">
          <div class="option-list">
            <!-- Portion -->
            <div
              class="option-item"
              *ngIf="selectedMenuItem?.portions?.length > 0"
            >
              <div class="option-heading">
                {{
                  selectedMenuItem.portionActionCaption
                    | formattedLanguage: selectedMenuItem.captiontranslation
                }}
              </div>
              <div class="option-requirements">
                {{ 'select-option' | translate }}
              </div>
              <div class="portions">
                <wj-portion-list
                  [portions]="selectedMenuItem.portions"
                ></wj-portion-list>
              </div>
            </div>
            <!-- Modifiers -->
            <ng-container *ngFor="let modifier of selectedMenuItem?.modifiers">
              <div class="modifier-option" [attr.data-qa]="modifier._id">
                <div class="option-header" [id]="modifier._id">
                  <div class="option-heading">
                    {{ getActionCaption(modifier) }}
                  </div>
                  <div class="option-requirements" *ngIf="choiceText(modifier)">
                    {{ choiceText(modifier) }}
                  </div>
                  <div
                    *ngIf="headerErrors(modifier.valid, modifier._id)"
                    class="option-error"
                  >
                    <img src="assets/images/WJ_Error_Warning.svg" />
                    <span>{{ choiceText(modifier) }}</span>
                  </div>
                </div>

                <ng-container
                  *ngFor="let modifierItem of modifier?.modifierItems"
                >
                  <wj-modifier-list
                    [modifierItem]="modifierItem"
                    [modifier]="modifier"
                  ></wj-modifier-list>
                </ng-container>
              </div>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="modal-footer">
      <div
        class="button-container show-count-container"
        *ngIf="count$ | async as count"
      >
        <button
          (click)="decreaseCounter()"
          data-qa="modifier-count-dec"
          class="action-btn count-dec"
          aria-label="{{ 'subtract' | translate }}"
        >
          <img src="assets/images/WJ_Minus_Icon.svg" />
        </button>
        <div class="count-value" ng-model="count" data-qa="count">
          {{ count }}
        </div>
        <button
          class="action-btn"
          (click)="increaseCounter()"
          data-qa="modifier-count-inc"
          aria-label="{{ 'add' | translate }}"
        >
          <img src="assets/images/WJ_Plus_Icon.svg" />
        </button>
      </div>
      <div class="add-button-wrapper">
        <button
          class="btn-primary add-btn"
          (click)="submitMenuItem()"
          data-qa="add-modifier"
          aria-label="add modifier"
        >
          <span *ngIf="isEdit; else addToBag">
            {{ 'update' | translate }}
          </span>
          <ng-template #addToBag>{{ 'add' | translate }}</ng-template>
        </button>
      </div>
    </div>
  </ng-container>
  <ng-template #modifierLoader>
    <div class="loading">
      <div class="wrapper-cell">
        <div class="image"></div>
        <div class="text">
          <div class="text-line short"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
          <div class="text-line"></div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
