import { MenuList } from '../models';
import {
  SubSectionList,
  CategoryList,
  MenuItemList,
} from '../models/menu-list.interface';

export class FetchMenuList {
  static readonly type = '[MENULIST] FetchList';

  constructor(public payload?: string) {}
}

export class SetCategory {
  static readonly type = '[MENULIST] SetCategory';

  constructor(public payload: CategoryList) {}
}
export class ChangeCategory {
  static readonly type = '[MENULIST] ChangeCategory';

  constructor(public payload: CategoryList) {}
}

export class SetMenuItem {
  static readonly type = '[MENULIST] SetMenuItem';

  constructor(public payload: MenuItemList) {}
}

export class ClearMenuList {
  static readonly type = '[MENULIST] ClearMenuList';

  constructor() {}
}