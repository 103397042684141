import { State, Selector, StateContext, Action } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { PromotionalCategory, PromotionalCategoryItems } from '../models';
import { FetchPromotionalCategory } from '../actions';
import { Injectable } from '@angular/core';
import { PromotionalCategoryService } from '../services';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class PromotionalCategoryStateModel {
  promotionalCategory: PromotionalCategory;
}

@State<PromotionalCategoryStateModel>({
  name: 'promotionalcategory',
  defaults: {
    promotionalCategory: { _id: '', name: '', items: undefined },
  },
})
@Injectable()
export class PromotionalCategoryState {
  constructor(
    private _promotionalCategoryService: PromotionalCategoryService
  ) {}

  @Selector()
  static getPromotionalCategory(state: PromotionalCategoryStateModel) {
    return state.promotionalCategory;
  }

  @Action(FetchPromotionalCategory)
  fetchPromotionalCategory(
    { setState }: StateContext<PromotionalCategoryStateModel>,
    { payload }: FetchPromotionalCategory
  ) {
    return this._promotionalCategoryService
      .fetchPromotionalCategory(payload)
      .pipe(
        tap((response) => {
          if (response)
            setState(
              patch({
                promotionalCategory: response,
              })
            );
          else throw response;
        }),
        catchError((error) => {
          return Observable.throw(error);
        })
      );
  }
}
