export const Icons = [
  { id: 5, name: 'Made Without Gluten', filename: 'Gluten_Icon' },
  { id: 2, name: "Heart Smart", filename: 'heart-smart' },
  { id: 16, name: "Low Fat", filename: 'low-fat' },
  { id: 11, name: "Low Carb", filename: 'low-carb' },
  { id: 6, name: "Shellfish", filename: 'shellfish' },
  { id: 10, name: "Nuts", filename: 'nuts' },
  { id: 7, name: "Soy", filename: 'soybean' },
  { id: 8, name: "Lactose", filename: 'lactose' },
  { id: 9, name: "Eggs", filename: 'eggs' },
  { id: 17, name: "No Sugar", filename: 'sugar' },
  { id: 18, name: "Vegetarian", filename: 'Veg_Icon' },
  { id: 15, name: "Vegan", filename: 'Vegan_Icon' },
  { id: 12, name: "Spicy L1", filename: 'spicy-l1' },
  { id: 13, name: "Extra Spicy", filename: 'Chillies' },
  { id: 14, name: "Spicy L3", filename: 'spicy-l3' },
  { id: 4, name: "Ocean Wise", filename: 'oceanwise' },
  { id: 3, name: "Kosher", filename: 'kosher' },
  { id: 1, name: "Halal", filename: 'halal' },
  { id: 19, name: "CBD", filename: "CBD-Icons" },
  { id: 20, name: "Hybrid", filename: "Hybrid-Icons" },
  { id: 21, name: "Indica", filename: "Indica-Icons" },
  { id: 22, name: "Sativa", filename: "Sativa-Icons" },
  { id: 23, name: "THC", filename: "THC-Icons" },
];

export const paymentCardType = {
  'amex': {
    type: 'amex',
    icon: 'assets/images/Amex-Logo.svg',
    regex: "^3[47]",
    minlength: 18,
    cvvLength :4,

  },
  'discover': {
    type: 'discover',
    icon: 'assets/images/Discover-Logo.svg',
    regex: "^(6011|622(12[6-9]|1[3-9][0-9]|[2-8][0-9]{2}|9[0-1][0-9]|92[0-5]|64[4-9])|65)",
    minlength: 19,
    cvvLength :3,

  },
  'mastercard': {
    type: 'mastercard',
    icon: 'assets/images/MasterCard-Logo.svg',
    regex: "^5[0-9]{5,}$|^222[1-9][0-9]{3,}$|^22[3-9][0-9]{4,}$|^2[3-6][0-9]{5,}$|^27[01][0-9]{4,}$|^2720[0-9]{3,}$",
    minlength: 19,
    cvvLength :3,

  },
  'visa': {
    type: 'visa',
    icon: 'assets/images/Visa-Logo.svg',
    regex: "^4",
    minlength: 19,
    cvvLength :3,

  }
  };

  export const   paymentMethods = [
    {
      id:5,
      name:'Cash on Delivery',
      text:'Cash on Delivery',
      icon:'assets/images/Pay-on-Delivery-Icon.svg',
      type:'payLater'
    },
    {
      id:3,
      name:'Add Credit/Debit Card',
      text:'Credit Card',
      icon:'assets/images/Credit-Card-Logo.svg',
      type:'payNow'
    },
    // {
    //   id:1,
    //   name:'Apple Pay',
    //   text:'Apple Pay',
    //   icon:'assets/images/Apple-Pay-Logo.svg',
    //   type:''
    // },
    // {
    //   id:2,
    //   name:'Google Pay',
    //   text:'Google Pay',
    //   icon:'assets/images/Google-Pay-Logo.svg',
    //   type:''
    // },
    // {
    //   id:4,
    //   name:'Gift/Loyalty Card',
    //   text:'Gift/Loyalty Card',
    //   icon:'assets/images/Gift-Card-Icon.svg',
    //   type:'payGiftAndLoyalty'
    // }
  ];
export const Regex = {
  email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  phone: '\D*([2-9]\d{2})(\D*)([2-9]\d{2})(\D*)(\d{4})\D*',
  deliveryAddress : {
      unitNumber : /^([a-zA-Z0-9-/]){1,8}$/,
      buzzerNumber : /^([0-9]){1,6}$/,
  },
};
