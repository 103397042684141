import { State, Selector, StateContext, Action } from '@ngxs/store';
import { Whitelabel } from '../models';
import { SetWhitelabel, SetOther, SetLandingPage } from '../actions';
import { Injectable } from '@angular/core';

export class WhitelabelStateModel {
    whitelabel: Whitelabel;
    other: any;
    landingPage:any;
}

@State<WhitelabelStateModel> ({
    name: 'whitelabel',
    defaults: {
        whitelabel: null,
        other: null,
        landingPage: null
    }
})

@Injectable()
export class WhitelabelState {

    @Selector()
    static getWhitelabel(state: WhitelabelStateModel){
        return state.whitelabel;
    }

    @Selector()
    static getOther(state: WhitelabelStateModel){
        return state.other;
    }
    @Selector()
    static getLandingPage(state: WhitelabelStateModel){
        return state.landingPage;
    }

    @Action(SetWhitelabel)
    setWhitelabel({ patchState }: StateContext<WhitelabelStateModel>, { payload }: SetWhitelabel) {
        patchState({
            whitelabel: payload
        });
    }

    @Action(SetOther)
    setOther({ patchState }: StateContext<WhitelabelStateModel>, { payload }: SetOther) {
        patchState({
            other: payload
        });
    }
    @Action(SetLandingPage)
    setLandingPage({ patchState }: StateContext<WhitelabelStateModel>, { payload }: SetOther) {
        patchState({
            landingPage: payload
        });
    }
    
}