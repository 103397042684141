import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from './../environments/environment';
import { AbstractControl,FormGroup  } from '@angular/forms';
import {Regex} from './../services/constant';
@Injectable({
  providedIn: 'root'
})
export class SharedService {
  //orgId : string = '5a5480ee2c393854d50189e4'
  emailRegex = Regex.email;
  constructor(private http: HttpClient) {
   }
  public logData(logDetails){
    // const body = logDetails;
    // const loggingUrl = environment.baseurl + 'logging/organization/' + this.orgId;
    // return this.http.post(loggingUrl,body);
  }
  public getLocations(organizationId:string,deliveryType:string,timezoneOffset){
    let getLocUrl = environment.mobileStoreApiUrl +'stores/organization/' +organizationId +'/stores';
    getLocUrl += `?type=${deliveryType}`;
    getLocUrl += `&timezoneOffset=${timezoneOffset}`;
    return this.http.get(getLocUrl);
  }

  emailpatternValidator() {
    return (control: AbstractControl): { [key: string]: any } => {
      if (!control.value) {
        return null;
      }
      const valid = this.emailRegex.test(control.value);
      return valid ? null : { inValidEmail: true };
    };
  }

  MatchPassword(password: string, confirmPassword: string) {
    return (formGroup: FormGroup) => {
      const passwordControl = formGroup.controls[password];
      const confirmPasswordControl = formGroup.controls[confirmPassword];

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      if (confirmPasswordControl.errors && !confirmPasswordControl.errors.passwordMismatch) {
        return null;
      }

      if (passwordControl.value !== confirmPasswordControl.value) {
        confirmPasswordControl.setErrors({ passwordMismatch: true });
      } else {
        confirmPasswordControl.setErrors(null);
      }
    }
  }

  passwordValidator(userControl: AbstractControl) {
    return new Promise(resolve => {
      setTimeout(() => {
        if (this.validatePassword(userControl.value)) {
          resolve(null);
        } else {
          resolve({ passwordValidationError: true });
        }
      }, 500);
    });
  }

  validatePassword(password: string) { 
    return (password.length >= 6);
  }

  isMobileScreen() {
    return screen.availWidth < 770;
  }
}
