<div class="review-container">
  <wj-app-header></wj-app-header>
  <div class="review-header">
    <div (click)="closeReview()" data-qa="back" aria-label="back">
      <img class="back-icon" src="assets/images/WJ_Back_Icon.svg" />
      <span class="back">
        {{ 'back' | translate }}
      </span>
    </div>
    <div class="head-text">{{ 'ReviewOrder' | translate }}</div>
  </div>
  <div class="review-content">
    <div class="ordering-at">
      <div class="header">{{ 'ordering-at' | translate }}</div>
      <div class="table">
        {{ 'table' | translate }} {{ selectedTable?.name }}
      </div>
      <p class="order-btn-cnfrm"> {{ 'placeorder-confirm' | translate }}</p>
      <div class="prepare-time">
        <span class="eta">{{ 'estimated-time' | translate }}</span>
        <span class="separator">&bull;</span>{{ orderSettings?.minPrepTime }}
        {{
          orderSettings?.minPrepTime >= 0 && orderSettings?.maxPrepTime >= 0
            ? '-'
            : ''
        }}
        {{ orderSettings?.maxPrepTime }}min
      </div>

      <button
        class="btn-primary order-btn"
        (click)="checkAndPlaceOrder()"
        data-qa="place-order-button"
        aria-label="{{ 'PlaceOrder' | translate }}"
      >
        {{ 'PlaceOrder' | translate }}
      </button>
    </div>
    <div class="cart">
      <p class="header">{{ 'items' | translate }}</p>
      <wj-list-cart
        [items]="cartItems[0].menuItems"
        *ngIf="cartItems && cartItems[0].menuItems.length > 0"
      >
      </wj-list-cart>
    </div>
    <div
      class="special-instruction"
      (click)="openSpecialInstruction()"
      data-qa="special-instruction-button"
      aria-label="special instruction"
    >
      <span class="alert">
        <img src="assets/images/WJ_Warning_Icon.svg" />
      </span>
      <!-- <label class="text">
        {{
          (specialInstructions ? specialInstructions : 'special-instruction')
            | translate
        }}</label
      > -->
      <label class="text">{{'special-instruction' | translate}}</label>
    </div>
    <div class="show-special-instruction" *ngIf="isSpecialInstructionPresent">
      {{ specialInstructionText }}
    </div>
    <!-- <button
      class="btn-primary order-btn"
      (click)="checkAndPlaceOrder()"
      data-qa="place-order-button"
      aria-label="{{ 'PlaceOrder' | translate }}"
    >
      {{ 'PlaceOrder' | translate }}
    </button>
    <p class="order-btn-cnfrm"> {{ 'placeorder-confirm' | translate }}</p> -->
  </div>
  <!-- <wj-special-instructions
    [modalStatus]="isSpecialInstruction"
    [heading]="
      specialInstructionPopupHeading
        ? specialInstructionPopupHeading
        : 'Special Instructions'
    "
    [topMargin]="'0'"
    class="special-instructions-window"
    (closePopupEvent)="closeSpecialInstructionView()"
    *ngIf="isSpecialInstruction"
  > -->
  <wj-special-instructions
  [modalStatus]="isSpecialInstruction"
  [heading]="'special-instruction-popup-heading'"
  [topMargin]="'0'"
  class="special-instructions-window"
  (closePopupEvent)="closeSpecialInstructionView()"
  *ngIf="isSpecialInstruction"
>
    <!-- <textarea
      #specialInstruction
      id="specialInstruction"
      rows="1"
      [ngClass]="{ errorBox: isError }"
      name="specialInstruction"
      class="form__field"
      (keyup)="checkValue($event)"
      data-qa="special-instruction-text"
      aria-label="special instruction text"
      [placeholder]="
        specialInstructionPopupPlaceholder
          ? specialInstructionPopupPlaceholder
          : 'special-instruction-popup-placeholder'
      "
      >{{ specialInstructionText }}</textarea
    > -->
    <textarea
    #specialInstruction
    id="specialInstruction"
    rows="1"
    [ngClass]="{ errorBox: isError }"
    name="specialInstruction"
    class="form__field"
    (keyup)="checkValue($event)"
    data-qa="special-instruction-text"
    aria-label="special instruction text"
    [placeholder]="'special-instruction-popup-placeholder'"
    >{{ specialInstructionText }}</textarea>
    <label
      for="specialInstruction"
      [theme]="themeLabel"
      [ngClass]="{
        errorLabel: isError,
        normalLabel: specialInstruction.value != ''
      }"
      class="form__label"
      >
      <!-- {{ specialInstructionPopupPlaceholder }} -->
      {{'special-instruction-popup-placeholder' | translate}}
      </label
    >
    <span
      class="clear-text"
      (click)="specialInstruction.value = ''; clearValue = !clearValue"
      *ngIf="clearValue"
      [theme]="themeLabel"
      >{{ 'clear' | translate }}</span
    >
    <!-- <div class="instruction">{{ specialInstructionPopupMessage }}</div> -->
    <div class="instruction">{{ 'special-instruction-popup-message' | translate }}</div>
    <button
      type="button"
      (click)="saveSpecialAllergyInstruction(specialInstruction.value)"
      [theme]="themeOptions"
      class="btn-primary"
      data-qa="add-special-instruction-button"
      attr.aria-label="{{
        isSpecialInstructionPresent ? ('save' | translate) : ('add' | translate)
      }}"
    >
      {{
        isSpecialInstructionPresent
          ? ('save-instruction' | translate)
          : ('add-instruction' | translate)
      }}
    </button>
  </wj-special-instructions>
</div>
