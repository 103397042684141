import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { CartCreation, Cart, CartItems, CartResponse } from '../models';
import {
  FetchCart,
  SetSelectedCart,
  SetCartItems,
  AddItemToCart,
  UpdateCart,
  SetCartTip,
  ClearCart,
  SetFutureDate,
  UpdateCount,
  FetchPrepTime,
  UpdateCartItem,
  FetchCartMenuItems,
  SetSpecialInstruction,
  ClearFutureDate,
  SetDefaultTipApplied,
  UpdateCartTotal,
  FetchETA,
} from '../actions';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Injectable } from '@angular/core';
import { tap, catchError, switchMap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { CartService } from '../services';
import { Tip } from '../models/cart.interface';
import { LocationState } from './location.state';
import { OrderService } from '../services';
import { SetCartId } from '../actions/auth.actions';
import { forkJoin } from 'rxjs';

export class CartStateModel {
  cartCreation: CartCreation;
  selectedCart: CartCreation;
  cart: Cart;
  cartItems: CartItems[];
  cartResponse: CartResponse;
  tip: any;
  paymentGateway: any;
  futureDate: string;
  prepTime: any;
  specialInstruction: string;
  eta: string;
  defaultTipApplied: boolean;
}

@State<CartStateModel>({
  name: 'cart',
  defaults: {
    defaultTipApplied: false,
    cart: undefined,
    cartItems: undefined,
    selectedCart: null,
    cartCreation: {
      organizationId: '',
      locationId: '',
      deliveryMethod: '',
      guest: true,
      combos: undefined,
      futureOrderDetails: undefined,
      menuItems: undefined,
      totalPrice: 100,
    },
    cartResponse: undefined,
    tip: undefined,
    paymentGateway: undefined,
    futureDate: null,
    prepTime: null,
    specialInstruction: '',
    eta: null,
  },
})
@Injectable()
export class CartState {
  constructor(
    private cartservice: CartService,
    private store: Store,
    private orderService: OrderService
  ) {
  }

  @Selector()
  static getCart(state: CartStateModel) {
    return state.cart;
  }
  @Selector()
  static getCartPriceItems(state: CartStateModel) {
    return state.cart.itemPrice;
  }
  @Selector()
  static getCartItems(state: CartStateModel) {
    return state.cartItems;
  }
  @Selector()
  static getCartResponse(state: CartStateModel) {
    return state.cartResponse;
  }
  @Selector()
  static getCartTip(state: CartStateModel) {
    return state.tip;
  }
  @Selector()
  static getPaymentGateway(state: CartStateModel) {
    return state.paymentGateway;
  }

  @Selector()
  static getPrepTime(state: CartStateModel) {
    return state.prepTime;
  }
  @Selector()
  static getFutureDate(state: CartStateModel) {
    return state.futureDate;
  }
  @Selector()
  static getSpecialInstruction(state: CartStateModel) {
    return state.specialInstruction;
  }
  @Selector()
  static getETA(state: CartStateModel) {
    return state.eta;
  }
  @Selector()
  static getDeaultTipApplied(state: CartStateModel) {
    return state.defaultTipApplied;
  }
  @Action(AddItemToCart)
  addItemToCart(
    { getState }: StateContext<CartStateModel>,
    { payload }: AddItemToCart
  ) {
    const { futureDate } = getState();
    const menuItem = payload;
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    const newCart: CartCreation = {
      organizationId: Config.organizationId,
      locationId: locationId,
      deliveryMethod: selectedDelivery._id,
      guest: true,
      menuItems: menuItem,
      combos: [],
      futureOrderDetails: { isFuture: false, pickupTime: '' },
      totalPrice: 100,
    };
    if (futureDate) {
      newCart.futureOrderDetails.isFuture = true;
      newCart.futureOrderDetails.pickupTime = new Date(
        futureDate
      ).toISOString();
    }
    this.store.dispatch(new SetSelectedCart(newCart));
    this.store.dispatch(new FetchETA());
  }

  @Action(UpdateCart)
  updateCart(
    { setState }: StateContext<CartStateModel>,
    { payload }: UpdateCart
  ) {
    const getcart = this.store.selectSnapshot(CartState.getCart);
    return this.cartservice.updateCart(payload).pipe(
      tap((response) => {
        if (response) {
          setState(
            patch({
              cart: response,
            })
          );
          this.cartservice.getCartItems(getcart.cart_id, Config.organizationId);
        } else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  @Action(UpdateCount)
  updateCount(
    { setState }: StateContext<CartStateModel>,
    { payload }: UpdateCount
  ) {
    const getcart = this.store.selectSnapshot(CartState.getCart);
    return this.cartservice.updateCount(payload).pipe(
      tap((response) => {
        if (response) {
          setState(
            patch({
              cart: response,
            })
          );
          this.cartservice.getCartItems(getcart.cart_id, Config.organizationId);
        } else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  @Action(SetSelectedCart)
  setCart(
    { setState }: StateContext<CartStateModel>,
    { payload }: SetSelectedCart
  ) {
    const getcart = this.store.selectSnapshot(CartState.getCart);
    return this.cartservice
      .createNewCart(payload, getcart ? getcart.cart_id : undefined)
      .pipe(
        tap((response) => {
          if (response) {
            if (response['cart_id'])
              this.store.dispatch(new SetCartId(response['cart_id']));
            setState(
              patch({
                cart: response,
              })
            );
            this.cartservice.getCartItems(
              response['cart_id'],
              payload['organizationId']
            );
          } else throw response;
        }),
        catchError((error) => {
          return Observable.throw(error);
        })
      );
  }
  @Action(SetCartItems)
  setCartItems({ setState }, { payload }: SetCartItems) {
    if (payload.cartId)
      return this.cartservice.getCartItemDetails(payload).pipe(
        tap((response) => {
          if (response) {
            setState(
              patch({
                cartItems: response,
                paymentGateway: response[0].paymentGateway,
              })
            );
          } else throw response;
        }),
        catchError((error) => {
          return Observable.throw(error);
        })
      );
    else return console.error({ message: 'no cart id' });
  }
  @Action(FetchCart)
  fetchCartItems({ patchState, getState }: StateContext<CartStateModel>) {
    const { cart } = getState();
    if (cart && cart.cart_id && cart.locationId) {
      const cartId = cart.cart_id;
      const locationId = cart.locationId;
      this.cartservice
        .getCartDetails(cartId, locationId)
        .subscribe((response) => {
          const cartResponse: any = response;
          patchState({
            cart: cartResponse,
          });
        });
    }
  }

  @Action(ClearCart)
  ClearCart(
    { patchState }: StateContext<CartStateModel>,
    { payload }: ClearCart
  ) {
    const selectedCart = this.store.selectSnapshot(CartState.getCart);
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let selectedLocationId = undefined;

    if (payload) {
      selectedLocationId = payload;
    } else if (selectedLocation && selectedLocation._id) {
      selectedLocationId = selectedLocation._id;
    }
    if (selectedLocationId && selectedCart && selectedCart.cart_id) {
      this.orderService.clearCurrentCart(
        selectedLocationId,
        selectedCart.cart_id
      );
    }

    patchState({
      defaultTipApplied: false,
      cart: undefined,
      cartItems: undefined,
      selectedCart: null,
      cartCreation: {
        organizationId: '',
        locationId: '',
        deliveryMethod: '',
        guest: true,
        combos: undefined,
        futureOrderDetails: undefined,
        menuItems: undefined,
        totalPrice: 100,
      },
      cartResponse: undefined,
      tip: undefined,
      specialInstruction: '',
      eta: null,
    });
  }
  @Action(SetCartTip)
  setCartTip(
    { patchState, getState }: StateContext<CartStateModel>,
    { payload }: SetCartTip
  ) {
    let selectedCartItem = this.store.selectSnapshot(CartState.getCartItems);
    return this.cartservice.addTip(payload).pipe(
      tap((response) => {
        if (response) {
          if (response && response.status == 1) {
            let selectedTip = {} as Tip;
            selectedTip.type = payload.tip['type'];
            selectedTip.value = payload.tip['value'];
            selectedCartItem[0].tip = selectedTip;

            patchState({
              cartResponse: response,
              cartItems: selectedCartItem,
            });
          }
        } else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  @Action(SetFutureDate)
  setFuture(
    { patchState, getState }: StateContext<CartStateModel>,
    { payload }: SetFutureDate
  ) {
    if (payload) {
      const { location, futureTime } = payload;
      const { cart } = getState();
      let cart_id = null;
      if (cart) cart_id = cart.cart_id;
      if (futureTime) {
        this.cartservice
          .checkFuture(futureTime, location._id, cart_id)
          .subscribe(
            (response) => {
              if (response) {
                const { status, isBlackout, message } = response as any;
                if (status === 200) {
                  if (!isBlackout) {
                    this.cartservice.futureDateChanged();
                    patchState({
                      futureDate: futureTime,
                    });
                  }
                } else {
                  console.error(message);
                }
              }
            },
            (err) => {
              console.error(err);
            }
          );
      } else {
        this.cartservice.futureDateChanged();
        patchState({
          futureDate: futureTime,
        });
      }
    }
  }

  @Action(FetchPrepTime)
  fetchPrepTime(
    { setState }: StateContext<CartStateModel>,
    { payload }: FetchPrepTime
  ) {
    return this.cartservice.fetchPrepTime(payload).pipe(
      tap((response) => {
        setState(
          patch({
            prepTime: response,
          })
        );
      })
    );
  }

  @Action(UpdateCartItem)
  updateCartItem(
    { getState }: StateContext<CartStateModel>,
    { payload }: UpdateCartItem
  ) {
    const { cart } = getState();
    const cartId = cart.cart_id;
    return this.cartservice.updateCartItem(payload, cartId).pipe(
      switchMap((response) =>
        forkJoin([
          this.store.dispatch(new FetchCart()),
          this.store.dispatch(
            new SetCartItems({
              cartId,
              organizationId: Config.organizationId,
            })
          ),
        ])
      )
    );
  }

  @Action(FetchCartMenuItems)
  fetchCartMenuItems({ getState, setState }: StateContext<CartStateModel>) {
    const getcart = this.store.selectSnapshot(CartState.getCart);
    if (getcart && getcart.cart_id)
      this.cartservice.getCartItems(getcart.cart_id, Config.organizationId);
  }

  @Action(SetSpecialInstruction)
  setSpecialInstruction(
    { setState }: StateContext<CartStateModel>,
    { payload }: SetSpecialInstruction
  ) {
    setState(
      patch({
        specialInstruction: payload,
      })
    );
  }

  @Action(SetDefaultTipApplied)
  SetDefaultTipApplied(
    { setState }: StateContext<CartStateModel>,
    { payload }: SetDefaultTipApplied
  ) {
    setState(
      patch({
        defaultTipApplied: payload,
      })
    );
  }
  @Action(UpdateCartTotal)
  updateCartTotal(
    { setState }: StateContext<CartStateModel>,
    { payload }: UpdateCartTotal
  ) {
    setState(
      patch({
        cart: payload,
      })
    );
  }

  @Action(FetchETA)
  fetchETA({ setState }: StateContext<CartStateModel>) {
    return this.cartservice.fetchETA().pipe(
      tap((response) => {
        setState(
          patch({
            eta: response,
          })
        );
      })
    );
  }
  @Action(ClearFutureDate)
  ClearFutureDate({ setState }: StateContext<CartStateModel>) {
    setState(
      patch({
        futureDate: null,
      })
    );
  }
}
