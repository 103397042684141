// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { LocationDefaults } from '../models/location-defaults.interface';
import {
  OperatingHours,
  LocationInfo,
  LocationStatus,
} from '../models/location.interface';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Store } from '@ngxs/store';
// import { CartState } from '../state';

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  constructor(private http: HttpClient, private store: Store) {}

  fetchLocations(paylod) {
    const orgId = Config.organizationId;
    let url = `${environment.locationApiUrl}locations/${orgId}/locations?`;
    url += '&timezoneOffset=' + new Date().getTimezoneOffset();
    if (paylod != undefined) {
      let params = new HttpParams().set('lat', paylod[1]).set('lng', paylod[0]); //Create new HttpParams
      return this.http.get(url, { params: params }); //5a5480ee2c393854d50189e4
    } else return this.http.get(url);
  }
  fetchLocationDefaults(selectedLocationId, selectedDelivery) {
    let selectedDate = new Date();
    const { futureDate } = this.store.selectSnapshot((app) => app.cart);
    if (futureDate) selectedDate = new Date(futureDate);
    const selectedTime = selectedDate.toISOString();
    const currDate = new Date();
    let url = environment.mobileStoreApiUrl + 'stores/location/' + selectedLocationId +'/shifts?type=' + selectedDelivery + '&currentTime=' + currDate.toISOString() + '&timezoneOffset=' + currDate.getTimezoneOffset();
    if (futureDate) {
      url = environment.mobileStoreApiUrl + 'stores/location/' + selectedLocationId + '/shifts?type=' + selectedDelivery + '&currentTime=' + currDate.toISOString() + '&future=true&selectedTime=' + selectedTime + '&timezoneOffset=' + currDate.getTimezoneOffset();
    }
    return this.http.get<LocationDefaults>(url);
  }

  fetchLocationInfo(locationId?: string): Observable<any> {
    let url = `${environment.locationApiUrl}locations/${locationId}/shifts`;
    return this.http.get<LocationDefaults>(url);
  }

  fetchLocationStatus(url: string) {
    return this.http.get<LocationStatus>(url);
  }

  updateLocationDistance(locationId) {
    let selectedLocation = localStorage.getItem('selectedLocation')
      ? JSON.parse(localStorage.getItem('selectedLocation'))
      : undefined;

    let deliveryLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    if (selectedLocation && deliveryLocation && deliveryLocation['loc']) {
      let lat = deliveryLocation['loc'][1];
      let long = deliveryLocation['loc'][0];
      if (lat && long) {
        let body = {
          locationId: locationId,
          locationCoordinates: selectedLocation['loc'],
          addressCoordinates: [],
        };
        body.addressCoordinates.push({
          id: 'id',
          loc: {
            lng: long,
            lat: lat,
          },
        });
        const url = `${environment.customerApiv3Url}customers/addressCheck`;
        return this.http.post(url, body);
      } else return of(null);
    } else return of(null);
  }

  fetchFreshSelectedLocation(locationId) {
    const url = `${environment.locationApiUrl}locations/${locationId}`;
    let selectedLocation = localStorage.getItem('selectedLocation')
      ? JSON.parse(localStorage.getItem('selectedLocation'))
      : undefined;
    let deliveryLocation =
      localStorage.getItem('selectedAdrsLocation') &&
      localStorage.getItem('selectedAdrsLocation') != 'undefined'
        ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
        : undefined;
    let params;
    if (selectedLocation && deliveryLocation && deliveryLocation['loc']) {
      let lat = deliveryLocation['loc'][1];
      let long = deliveryLocation['loc'][0];
      params = new HttpParams().set('lat', lat).set('lng', long); //Create new HttpParams
    }
    return this.http.get(url, { params: params });
  }
}
