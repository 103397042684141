import {
  BaseComponent,
  environment,
  LocationState,
  DeliveryState,
  Delivery,
  CartState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import {
  AddressState,
  Address,
  FetchDeliveryAddress,
  ChangePopupStatus,
  VenueManagementState,
  Venue,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import * as moment from 'moment';
import { SharedService } from './../../../core/services/shared.service';
export class AddressSelectorBaseComponent extends BaseComponent
  implements OnInit {
  viewChangeAdrsModalStatus: boolean;
  addressBookStatus: boolean = false;
  selectedLocation: any;
  selectedDelivery: any;
  deliveryLocation: any;
  selectedVenue: Venue = undefined;
  @Select(AddressState.getDeliveryAddress) DeliveryAddress$: Observable<
    Address
  >;
  @Select(AddressState.getAddressPopUpStatus)
  modalChangeAdrsStatus$: Observable<boolean>;
  @Select(DeliveryState.getSelectedDelivery) selectedDelivery$: Observable<
    Delivery
  >;
  @Select(CartState.getFutureDate) futureDate$: any;
  @Select(CartState.getETA) eta$: any;
  @Select(VenueManagementState.getSelectedPickUpLocation)
  selectedPickUpLocation$: Observable<Venue>;
  @Select(VenueManagementState.getSelectedRoom) selectedRoom$: Observable<
    Venue
  >;
  deliveryAddress: String;
  futureDate: String = '';
  eta: any;
  isMobileView = this.shared.isMobileScreen();
  constructor(
    public store: Store,
    public modal: ModalService,
    private shared: SharedService
  ) {
    super();
    this.store.dispatch(new FetchDeliveryAddress());
    this.selectedDelivery$
      .pipe(takeUntil(this.destroy$))
      .subscribe((delivery) => {
        if (delivery) {
          this.selectedDelivery = delivery;
          if (
            !this.isMobileView &&
            (this.selectedDelivery?.textCode == 'DELIVERYTYPE09' ||
              this.selectedDelivery?.textCode == 'DELIVERYTYPE10')
          ) {
            this.setSelectedVenuesToDisplay(this.selectedDelivery.textCode);
          }
        }
      });
    this.futureDate$.pipe(takeUntil(this.destroy$)).subscribe((date) => {
      if (date) {
        this.futureDate = this.setDisplayTime(date);
      }
    });
    this.eta$.pipe(takeUntil(this.destroy$)).subscribe((eta) => {
      if (eta) {
        this.eta = eta.message;
      }
    });
  }
  ngOnInit() {
    this.subscribeAdrs();
    this.subscribePopupStatus();
    this.selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
  }
  openChangeAdrs() {
    this.store.dispatch(new ChangePopupStatus(true));
  }
  closeChangeAdrs() {
    this.store.dispatch(new ChangePopupStatus(false));
  }
  subscribeAdrs() {
    this.DeliveryAddress$.pipe(takeUntil(this.destroy$)).subscribe(
      (address) => {
        if (address) {
          this.deliveryLocation = address;
          if (address?.buzzerNumber && address.buzzerNumber != '')
            this.deliveryAddress = address.buzzerNumber;
          if (address?.streetAddress && address.streetAddress != '')
            this.deliveryAddress += ',' + address.streetAddress;
          if (address?.city && address.city != '')
            this.deliveryAddress += ',' + address.city;
          if (address?.state && address.state != '')
            this.deliveryAddress += ',' + address.state;
          if (address?.country && address.country != '')
            this.deliveryAddress += ',' + address.country;
        }
      }
    );
  }
  subscribePopupStatus() {
    this.modalChangeAdrsStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((status) => {
        this.viewChangeAdrsModalStatus = status;
      });
  }
  setDisplayTime(futureDate) {
    if (futureDate) {
      const futureTime = moment(futureDate);
      let timeText = futureTime.format('MMM DD hh:mm A');
      timeText += ` - ${futureTime.add({ minutes: 30 }).format('hh:mm A')}`;
      return timeText;
    }
    return 'ASAP';
  }

  setSelectedVenuesToDisplay(selectedDeliveryTextCode) {
    switch (selectedDeliveryTextCode) {
      case 'DELIVERYTYPE09':
        this.selectedPickUpLocation$
          .pipe(takeUntil(this.destroy$))
          .subscribe((venue) => {
            if (venue) {
              this.selectedVenue = venue;
            }
          });
        break;
      case 'DELIVERYTYPE10':
        this.selectedRoom$.pipe(takeUntil(this.destroy$)).subscribe((venue) => {
          if (venue) {
            this.selectedVenue = venue;
          }
        });
        break;
      default:
        break;
    }
  }
}
