import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { BehaviorSubject } from 'rxjs';
const navigator :any = window.navigator;
@Injectable()
export class PWAService {

    pwaDisplay: BehaviorSubject<'header'|'banner'> = new BehaviorSubject(null);
    offline: BehaviorSubject<boolean> = new BehaviorSubject(false);
    deferredAlert;
    ua = navigator.userAgent;

    constructor(private workerUpdate: SwUpdate) {
        this.watchForSWUpdate();
        this.addToHomeScreenExtender();
        this.initOfflineListeners();
     }

    watchForSWUpdate() {
        this.workerUpdate.available.subscribe(event => {
            this.workerUpdate.activateUpdate().then(() => document.location.reload());
        });
    }

    addToHomeScreenExtender() {
        this.initDisplayState();
        window.addEventListener('beforeinstallprompt', (e) => {
            e.preventDefault();
            this.deferredAlert = e;

            // If android and the banner was not already dismissed to header
            if(this.checkAndroid)
                if(this.pwaDisplay.value !== 'header')
                    this.pwaDisplay.next('banner');
                else
                    this.pwaDisplay.next('header');
            else
                this.pwaDisplay.next('header');
                
        });
    }

    saveToHome() {
        this.deferredAlert.prompt();

        if(this.checkSamsung){
            this.deferredAlert = null;
            this.pwaDisplay.next(null);
        }else{
            this.deferredAlert.userChoice
            .then((choiceResult) => {                
                if(choiceResult && choiceResult.outcome === "accepted"){
                    this.deferredAlert = null;
                    this.pwaDisplay.next(null);
                }
            })
            .catch(console.error);
        }
    }

    initOfflineListeners(){
        window.addEventListener('online',  (event) => {
            const condition = navigator.onLine;
            this.offline.next(!condition);
        });
        window.addEventListener('offline', (event) => {
            const condition = navigator.onLine;
            this.offline.next(!condition);
        });
    }

    initDisplayState(){
        if(this.checkIOS && !this.checkStandAlone && this.checkSafari)
            this.pwaDisplay.next('header');
        else if (this.checkFirefoxAndroid)
            this.pwaDisplay.next('header');
        else
            this.pwaDisplay.next(null);
    }

    get checkStandAlone(){
		const navigator:any = window.navigator;
        return window.matchMedia('(display-mode: standalone)').matches || (('standalone' in navigator) && (navigator.standalone));
	}
	
	get checkSafari(){
        const ua = this.ua.toLowerCase(); 
		return (ua.indexOf('safari') != -1) && ((ua.indexOf('chrome') == -1) && ua.indexOf('crios') == -1);
    }
    
    get checkSamsung(){
        const ua = this.ua.toLowerCase(); 
        return ua.indexOf('samsungbrowser') !== -1;
    }

    get checkIOS(){
		return (/iPad|iPhone|iPod/.test(navigator.platform) || (navigator.platform === 'MacIntel' &&  navigator.maxTouchPoints > 1)) && !(window as any).MSStream;
    }
    
    get checkFirefoxAndroid(){
        return /Firefox|firefox/i.test(this.ua) && this.checkAndroid;
    }

    get checkAndroid(){
        return /Android/i.test(this.ua);
    }

}
