<div class="popup-content" *ngIf="deliveryType != 'DELIVERYTYPE08';else westJetAlert" [theme]="themeFont">
  <div class="header">
    {{ header }}
  </div>
  <div class="body" *ngIf="content">
    {{ content }}
  </div>
  <div class="button-wrapper">
    <button
      class="btn"
      (click)="closePopup()"
      [ngClass]="data.actionable? 'cancel-btn':'non-action'"
      data-qa="close"
      aria-label="close"
      [theme]="themeOptions"
    >
      {{ closeBtnText }}
    </button>
    <button
      *ngIf="actionBtn"
      class="btn action-btn"
      (click)="getData()"
      data-qa="remove-item"
      aria-label="remove"
      [theme]="themeFont"
    >
      {{ 'remove' | translate }}
    </button>
  </div>
</div>

<ng-template #westJetAlert>
  <div  class="background-overlay">
    <div class="content-modal">
      <div class="modal-content">
        <ng-container>
          <div class="description-container close-position">
            <span (click)="closePopup()" class="close-text">{{ 'close' | translate }}</span>
            <img
              (click)="closePopup()"
              src="/assets/images/wj_Icon_24_Close.svg"
              data-qa="close-button"
              class="close"
              aria-label="close"
            />
          </div>

          <div class="description-container alert-header-position">
            <img
              src="/assets/images/wj_Icon_24_Warning.svg"
              data-qa="close-button"
              class="header"
              aria-label="close"
            />
            <span class="header-text">{{ header}}</span>
          </div>
          <div class="description-container alert-content">{{ content }}</div>
          <div class="description-container alert-content items" *ngIf="items">  {{ items }}</div>
          <div class="alert-button-container">
            <div>
              <button
                type="button"
                data-qa="remove-item"
                aria-label="remove"
                [theme]="themeOptions"
                (click)="getData()"
                class="btn-primary"
              >
                {{ data.buttonText?data.buttonText:"continue" | translate }}
              </button>
            </div>
            <div *ngIf="data.isRemove">
              <button
              type="button"
              (click)="closePopup()"
              class="keep-item"
              data-qa="rate-our-next-dish"
              aria-label="rate next dish"
            >
              <!-- <img [src]="rateIcon" class="rating" /> -->
              {{ 'Keep my item' | translate }}

            </button>
            </div>
          </div>

        </ng-container>
        <ng-template #futureLoader>
          <div class="loader-date loader"></div>
          <div class="loader-time loader"></div>
        </ng-template>
      </div>
    </div>
  </div>
</ng-template>
