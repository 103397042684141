<div
  [class]="requireOverlay ? 'west-jet-common-popup overlay' : 'west-jet-common-popup'"
  [ngClass]="{ show: modalStatus }"
>
  <div class="popup-content" [style.margin-top]="topMargin">
    <div (click)="closePopupEvent.emit()" class="close-position">
      <span class="close-text" [theme]="themeClose">{{ 'close' | translate }}</span>
      <img
      src="/assets/images/wj_Icon_24_Close.svg"
      data-qa="close-button"
      class="close"
      aria-label="close"
    />
    </div>
    <div class="title">{{ heading | translate}}</div>
    <div class="body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
