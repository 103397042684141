import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { Auth } from '../models';
import { SetCommonAuthDetails } from '../actions';
import { Injectable } from '@angular/core';
import { SetCartId } from '../actions/auth.actions';
import { UpdateDeliverableStatus, ClearSelectedAddress } from '../actions';
export class AuthStateModel {
  loggedInUser: Auth;
}

@State<AuthStateModel>({
  name: 'auth',
  defaults: {
    loggedInUser: {
      isLoggedIn: false,
      userName: undefined,
      loginProvider: undefined,
      imgUrl: undefined,
      cartId: undefined,
      primaryPhone: undefined,
      customerId: undefined,
    },
  },
})
@Injectable()
export class AuthState {
  constructor(private store: Store) {}

  @Selector()
  static getCommonAuthDetails(state: AuthStateModel) {
    return state.loggedInUser;
  }

  @Action(SetCommonAuthDetails)
  SetCommonAuthDetails(
    { setState }: StateContext<AuthStateModel>,
    { payload }: SetCommonAuthDetails
  ) {
    const loggedInUserDetails = payload;
    setState({
      loggedInUser: loggedInUserDetails,
    });
    this.store.dispatch(new UpdateDeliverableStatus(false));
    this.store.dispatch(new ClearSelectedAddress());
  }

  @Action(SetCartId)
  SetCartId(
    { getState, patchState }: StateContext<AuthStateModel>,
    { payload }: SetCartId
  ) {
    const { loggedInUser } = getState();
    if (loggedInUser && payload) {
      loggedInUser.cartId = payload;
      patchState({
        loggedInUser: loggedInUser,
      });
    }
  }
}
