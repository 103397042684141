import { BaseComponent } from '@moduurnv2/libs-orderingapp/src/core';
import {
  Category,
  DiscountsState,
  Discount,
  CustomerFavourites,
  CustomerFavouritesState,
  FetchCustomerFavouritesList,
  PromotionalCategory,
  FetchDeliveryList,
  FetchDiscountsList,
  DeliveryState,
  Delivery,
  HomeModel,
  HomeState,
  FetchHomeData,
} from '@moduurnv2/libs-orderingapp/src/core';
import { ValidationService } from '@moduurnv2/libs-orderingapp/src/core/services';
import { Store, Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';

import { FormGroup, FormBuilder } from '@angular/forms';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';

export class HomeBaseComponent extends BaseComponent {
  submitted = false;
  today: Date = new Date();
  homeForm: FormGroup;
  enableCategoryList: boolean = true;
  enableDiscount: boolean = false;
  enablecustomerFavouritesList: boolean = true;
  enablePromotionalCategoryList: boolean = true;
  enabledeliveryList: boolean = true;

  private _homeSubscriprion: Subscription;
  private _customerSubscriprion: Subscription;

  @Select(HomeState.getHomeData)
  homeData$: Observable<HomeModel>;
  promotionalCategory: PromotionalCategory;
  @Select(HomeState.getHomeCategories) category$: Observable<Category>;
  category: Category;

  @Select(DiscountsState.getDiscountsList) discountList$: Observable<
    Discount[]
  >;
  @Select(CustomerFavouritesState.getCustomerFavouritesList)
  customerFavouritesList$: Observable<CustomerFavourites[]>;
  @Select(DeliveryState.getDeliveryList) deliveryList$: Observable<Delivery[]>;
  @Select(DeliveryState.getSelectedDelivery)
  selectedDelivery$: Observable<Delivery>;

  constructor(
    public fb: FormBuilder,
    public validatior: ValidationService,
    public store: Store,
    public modal: ModalService
  ) {
    super();
    this.subscribeListItems();
  }

  ngOnInit() {
    this.homeForm = this.fb.group({
      postalCode: [null, this.validatior.postalCodeValidator(true)],
    });

    // Fetch categories
    this.store.dispatch(new FetchDeliveryList());
    this.store.dispatch(new FetchHomeData());
    this.store.dispatch(new FetchCustomerFavouritesList());
    this.store.dispatch(new FetchDiscountsList());
  }
  subscribeListItems() {
    this._homeSubscriprion = this.homeData$.subscribe((homeData) => {
      // this.category = homeData.category;
      this.promotionalCategory = homeData.promotionalCategory;

      if (
        this.promotionalCategory &&
        this.promotionalCategory.items &&
        this.promotionalCategory.items.length == 0
      )
        this.enablePromotionalCategoryList = false;
      else this.enablePromotionalCategoryList = true;
    });

    this._customerSubscriprion = this.customerFavouritesList$.subscribe(
      (customerFavouritesList) => {
        if (
          customerFavouritesList != undefined &&
          customerFavouritesList.length == 0
        )
          this.enablecustomerFavouritesList = false;
        else this.enablecustomerFavouritesList = true;
      }
    );
  }
  ngOnDestroy() {
    this._homeSubscriprion.unsubscribe();
    this._customerSubscriprion.unsubscribe();
  }
  get homeFormControl() {
    return this.homeForm.controls;
  }

  onSubmit() {
    this.submitted = true;
  }
}
