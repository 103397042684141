import { environment } from '@moduurnv2/libs-orderingapp/src';
import { HttpClient } from '@angular/common/http';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { LocationState } from  '@moduurnv2/libs-orderingapp/src/core';

declare let gtag: Function;
let allPagesToTrack = [
  '/confirm',
  '/home',
  '/categories',
  '/combos',
  '/comboGroups',
  '/menu',
  '/order',
  '/payment',
  '/progress',
  '/settings',
];
let pagesToTrack = [
  '/confirm',
  '/home',
  '/categories',
  '/combos',
  '/comboGroups',
  '/menu',
  '/order',
  '/payment',
  '/progress',
  '/settings',
];
@Injectable()
export class AnalyticsHandler {
  googleAnalyticsTrackingId: string;
  googleAnalyticsEnableTracking: boolean = false;
  org_id = Config.organizationId;
  org_name = Config.name;
  
  public constructor(private http: HttpClient, private store: Store) {}
  public setupGoogleAnalytics() {
    const url =
      environment.organizationApiUrl + `organizations/${this.org_id}/analytics`;
    this.http.get(url).subscribe((resp) => {
      if (
        resp['data'] &&
        resp['data'].analytics &&
        resp['data'].analytics.length
      ) {
        const googleAnalyticsDetails = resp['data'].analytics.find(
          (obj) => obj.type == 'ANALYTICSPROVIDER01'
        );
        if (
          googleAnalyticsDetails &&
          googleAnalyticsDetails.isEnabled &&
          googleAnalyticsDetails.configs
        ) {
          const trackingId = googleAnalyticsDetails.configs.trackingId;
          this.googleAnalyticsTrackingId = trackingId;
          this.googleAnalyticsEnableTracking =
            this.googleAnalyticsTrackingId && googleAnalyticsDetails.isEnabled
              ? true
              : false;
          let gaScript1 = document.createElement('script');
          gaScript1.innerText = `window.ga = window.ga || function(){(ga.q=ga.q||[]).push(arguments);}ga.l=+new Date;ga(\'create\', ${trackingId},\'auto\');ga(\'send\', \'pageview\');`;
          let gaScript2 = document.createElement('script');
          gaScript2.setAttribute('async', 'true');
          gaScript2.setAttribute(
            'src',
            `https://www.google-analytics.com/analytics.js`
          );

          let gtagScript1 = document.createElement('script');
          gtagScript1.setAttribute('async', 'true');
          gtagScript1.setAttribute(
            'src',
            `https://www.googletagmanager.com/gtag/js?id=${trackingId}`
          );
          let gtagScript2 = document.createElement('script');
          gtagScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingId}\');`;
          document.documentElement.firstChild.appendChild(gtagScript1);
          document.documentElement.firstChild.appendChild(gtagScript2);
        }
      }
    });
  }
  public setPageRedirects(route: string) {
    const location = this.store.selectSnapshot(LocationState.getSelectedLocation);
    if (this.googleAnalyticsEnableTracking) {
      gtag('js', new Date());
      //Call 'set' before config to define mapping
      gtag('set', {
        custom_map: {
          dimension1: 'org_details',
        },
      });
      gtag('config', this.googleAnalyticsTrackingId, {
        page_path: route,
        org_details: location.name,
      });
    }
    //}
  }
  public setEvents(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string = null,
    eventValue: number = null
  ) {
    if (this.googleAnalyticsEnableTracking) {
      gtag('event', eventName, {
        eventCategory: eventCategory,
        eventLabel: eventLabel,
        eventAction: eventAction,
        eventValue: eventValue,
      });
    }
  }
  public setCustomDimension(dimensionIndex: string, dimensionValue: any) {
    if (this.googleAnalyticsEnableTracking) {
      gtag('set', dimensionIndex, dimensionValue);    
    }
  }
}
