import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import { Store } from '@ngxs/store';
@Component({
  selector: 'moduurnv2-alert-popup',
  templateUrl: './alert-popup.component.html',
  styleUrls: ['./alert-popup.component.scss'],
})

export class AlertPopupComponent implements OnInit {
  @Input() data:any = null;
  @Input() _modalClose: any;
  header:string;
  content:string;
  items:String;
  actionBtn:boolean = false;
  closeBtnText:string = 'Close';
  deliveryType:string;
  isRemove = false;
  themeFont: ThemeOptions = {
    appFontFamily:true
  };
  themeOptions: ThemeOptions = {
    appFontFamily:true,
    appPrimaryColor: true,
  };

  constructor( private modal:ModalService,public store: Store ) {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    if(selectedDelivery)
    this.deliveryType = selectedDelivery.textCode;
  }

  ngOnInit(): void {
    if(this.data) this.populateData(this.data);
  }

  populateData(data){
    this.header = data.header;
    this.content = data.content;
    this.items = data.items;
    this.actionBtn = data.actionable;
    this.isRemove = data.isRemove;
    if(data.actionable){
      this.closeBtnText = 'Cancel'
    }
  }

  getData(){
    this._modalClose(true);
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
        this.modal.closeAllComponentModals(modalRef, data);
    };
  }
}
