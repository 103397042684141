export class FetchPaymentMethods{
    static readonly type = 'FetchList';

    constructor(public payload?: string) {}
}

export class SetPaymentMethod{
    static readonly type = 'SetPayment';

    constructor(public payload?) {}
}
export class FetchSavedCards{
    static readonly type = 'FetchSavedCards';

    constructor(public payload?) {}
}
export class TokenizeCard{
    static readonly type = 'TokenizeCard';

    constructor(public payload?) {}
}
export class SetSelectedCard{
    static readonly type = 'SetSelectedCard';
    
    constructor(public payload?) {}
}

export class ClearSelectedCard{
    static readonly type = 'ClearSelectedCard';
    
    constructor(public payload?) {}
}
export class ClearPaymentDetails{
    static readonly type = 'ClearPaymentDetails';

    constructor(public payload?) {}
}

export class SetNewCard{
    static readonly type = 'SetNewCard';
    
    constructor(public payload?) {}
}

export class TokenizeProfile{
    static readonly type = 'TokenizeProfile';

    constructor(public payload?) {}
}
export class RemoveCard{
    static readonly type = 'RemoveCard';

    constructor(public payload?) {}
}