import { Whitelabel } from '../models';

export class SetWhitelabel {
    static readonly type = '[WHITELABEL] Set';

    constructor(public payload: Whitelabel){}
}
export class SetOther {
    static readonly type = '[WHITELABEL] SetOther';

    constructor(public payload){}
}
export class SetLandingPage {
    static readonly type = '[WHITELABEL] SetLandingPage';

    constructor(public payload){}
}