import {
  BaseComponent,
  environment,
  ChangePopupStatus,
  ChangeMapPopupStatus,
  ChangeToNewDeliveryAddress,
  UpdateAddressList,
  FetchAddressList,
  ChangeadrsDetailsPopupStatus,
  setLocationDistance,
  LocationState,
  AddressData,
  FetchDeliverableDetails,
  ChangeDeliveryAddress,
  ChangeDeliveryAddressLocal,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import {
  AddressState,
  Address,
  SaveNewDeliveryAddress,
  AuthState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
export class AddressDetailsBaseComponent extends BaseComponent
  implements OnInit {
  @Input() modalAdrsDetailsStatus: boolean;
  @Input() isAddressBook: boolean;
  @Output() closeEvent = new EventEmitter();
  deliveryLocation: any;
  deliveryAddress: string;
  imgBasePath: string = environment.image_basepath;
  adrsType: number = 0;
  isValid: boolean = true;
  isaddresDetails: boolean = true;
  houseNo: string = '';
  floor: string;
  instructions: string;
  selectedLocation: Address;
  otherAdrsType: string;
  zipCode: string;
  country: string;
  deliverabledata: any;
  isAdrsTagValid: boolean = true;
  @Select(AddressState.getTempSelectedAddress)
  DeliveryAddress$: Observable<Address>;
  icon = {
    edit: this.imgBasePath + environment.images.edit,
    close: this.imgBasePath + environment.images.close,
    adrs_edit: this.imgBasePath + environment.images.edit_blue,
  };
  constructor(public store: Store, public modal: ModalService) {
    super();
  }
  ngOnInit() {
    this.subscribeAdrs();
    let data = this.deliveryAddress.split(',');
    this.country = data[data.length - 1];
  }
  validate(zip) {
    if (
      this.country == 'Canada' ||
      this.country == 'United States' ||
      this.country == 'Saudi Arabia'
    ) {
      if (this.country == 'Canada') {
        this.zipCode = zip.toUpperCase();
        let char = this.zipCode.charAt(3);
        if (char != ' ' && this.zipCode.length > 3)
          this.zipCode = [
            this.zipCode.slice(0, 3),
            ' ',
            this.zipCode.slice(3),
          ].join('');
        var zipRegExp = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
      } else if (this.country == 'United States')
        var zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
      else if (this.country == 'Saudi Arabia')
        var zipRegExp = /^\d{5}(?:[-\s]\d{4})?$/;
      this.isValid = zipRegExp.test(zip);
    } else this.isValid = true;
  }
  adrsvalidate(adrs) {
    if (adrs.trim() == '') this.isaddresDetails = false;
    else this.isaddresDetails = true;
  }
  adrstagvalidate(tag) {
    if (tag.trim() == '') this.isAdrsTagValid = false;
    else this.isAdrsTagValid = true;
  }
  subscribeAdrs() {
    let address = localStorage.getItem('tempSelectedAdrsLocation')
      ? JSON.parse(localStorage.getItem('tempSelectedAdrsLocation'))
      : undefined;
    if (address) {
      this.deliveryLocation = address;
      if (address?.streetAddress && address.streetAddress != '')
        this.deliveryAddress = address.streetAddress;
      if (address?.city && address.city != '')
        this.deliveryAddress += ',' + address.city;
      if (address?.state && address.state != '')
        this.deliveryAddress += ',' + address.state;
      if (address?.country && address.country != '')
        this.deliveryAddress += ',' + address.country;
      this.houseNo = address.buzzerNumber;
      this.instructions = address.instructions;
      if (address.addressType != '') {
        if (address.addressType == 'Home') this.adrsType = 1;
        else if (address.addressType == 'Office') this.adrsType = 2;
        else {
          this.adrsType = 3;
          this.otherAdrsType = address.addressType;
        }
      } else {
        this.adrsType = 0;
        this.otherAdrsType = '';
      }
      this.zipCode = address.postalcode ? address.postalcode : '';
      if (this.zipCode != '') this.validate(this.zipCode);
    }
  }
  selectAdrsType(type) {
    if (this.adrsType == type) this.adrsType = 0;
    else this.adrsType = type;
    if (this.adrsType == 3) this.isAdrsTagValid = false;
    else this.isAdrsTagValid = true;
  }
  saveAdrs() {
    if (this.houseNo.trim() == '') this.isaddresDetails = false;
    else this.isaddresDetails = true;
    this.validate(this.zipCode);
    if (this.isValid && this.isaddresDetails) {
      this.store.dispatch(new ChangeadrsDetailsPopupStatus(false));
      this.store.dispatch(new ChangePopupStatus(false));
      this.store.dispatch(new ChangeMapPopupStatus(false));
      let address = JSON.parse(
        localStorage.getItem('tempSelectedAdrsLocation')
      );
      this.selectedLocation = address;

      this.selectedLocation.buzzerNumber = this.houseNo;
      this.selectedLocation.instructions = this.instructions;
      this.selectedLocation.postalcode = this.zipCode;
      this.selectedLocation.addressType = '';
      if (this.adrsType != 0) {
        if (this.adrsType == 3)
          this.selectedLocation.addressType = this.otherAdrsType;
        else
          this.selectedLocation.addressType =
            this.adrsType == 1 ? 'Home' : 'Office';
      }
      this.store.dispatch(
        new ChangeToNewDeliveryAddress(this.selectedLocation)
      );
      let previousLocs = [];
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      if (customer && customer.customerId) {
        this.store
          .dispatch(new SaveNewDeliveryAddress(this.selectedLocation))
          .subscribe((data) => {
            this.store
              .dispatch(new FetchAddressList())
              .subscribe((locations) => {
                let lastdata = locations.address.addressList.length - 1;
                this.selectedLocation._id =
                  locations.address.addressList[lastdata]._id;
                this.checkDeliverable(this.selectedLocation);
              });
          });
      } else {
        this.selectedLocation._id = String(new Date().getUTCMilliseconds());
        this.checkDeliverable(this.selectedLocation);
        if ('locationList' in localStorage)
          previousLocs = JSON.parse(localStorage.getItem('locationList'));
        let index = previousLocs.findIndex(
          (adrs) => adrs._id == this.deliveryLocation._id
        );
        if (index >= 0) previousLocs.splice(index, 1);
        previousLocs.push(this.selectedLocation);
        localStorage.setItem('locationList', JSON.stringify(previousLocs));
        this.store.dispatch(new UpdateAddressList(previousLocs));
      }
    }
  }
  checkDeliverable(adrsList) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let adrsdataObj: AddressData = {
      locationId: selectedLocation._id,
      locationCoordinates: selectedLocation['loc'],
      addressCoordinates: [],
    };

    adrsdataObj.addressCoordinates.push({
      id: adrsList._id,
      loc: {
        lng: adrsList['loc'][0],
        lat: adrsList['loc'][1],
      },
    });

    this.store
      .dispatch(new FetchDeliverableDetails(adrsdataObj))
      .toPromise()
      .then((data) => this.check(data));
  }
  check(data) {
    if (data.address.isDeliverable) {
      let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
      if (customer && customer.customerId) {
        this.store.dispatch(
          new ChangeDeliveryAddress(this.selectedLocation._id)
        );
      } else {
        this.store.dispatch(
          new ChangeDeliveryAddressLocal(this.selectedLocation._id)
        );
      }
      let selectedLocation = localStorage.getItem('selectedLocation')
        ? JSON.parse(localStorage.getItem('selectedLocation'))
        : undefined;
      if (selectedLocation) {
        this.store.dispatch(new setLocationDistance(selectedLocation._id));
      }
      localStorage.setItem('tempSelectedAdrsLocation', '');
    } else {
      localStorage.setItem('selectedAdrsLocation', undefined);
    }
  }
}
