<ng-container *ngIf="cart$ | async as cart">
  <div
    class="view-cart"
    *ngIf="cart && cart.count"
    (click)="emitReviewEvent()"
    [theme]="themeOptions"
    attr.aria-label="{{ 'checkout' | translate }}"
  >
    <!-- <div class="view-cart-grid">
      <span class="item-count"
        >{{ cart?.count }} {{ itemText(cart.count) | translate }}</span
      >
      <span class="item-price"
        >{{ cart?.subTotal | formattedCurrency }}
        <span class="tax-container" *ngIf="!allowNoPaymentOrdering"
          >{{ 'Plus taxes' | translate }}
        </span></span
      >
    </div> -->
    <ng-container *ngIf="locationStatus$ | async as locationStatus">
      <div class="view-order" *ngIf="locationStatus &&
            locationStatus.isOpenToday &&
            locationStatus.isCurrentlyOpen"
          (click)="viewReview.emit()" id="viewOrderBtn"
          >
        {{ 'checkout' | translate }}
        ({{ cart?.count }} {{ itemText(cart.count) | translate }})
      </div>
      <div class="view-order-text" *ngIf="locationStatus && (!locationStatus.isOpenToday || !locationStatus.isCurrentlyOpen)">
          {{ locCloseMsg | translate }}
      </div>
    </ng-container>
  </div>
</ng-container>
