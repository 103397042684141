import {
  State,
  Selector,
  StateContext,
  Action,
  Select,
  Store,
} from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Delivery, Location } from '../models';
import {
  FetchDeliveryList,
  SetSelectedDelivery,
  ClearSelectedDelivery,
} from '../actions';
import { Injectable } from '@angular/core';
import { DeliveryMethodService } from '../services';
import { tap, catchError, flatMap } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { LocationState } from './location.state';
import { ActivatedRoute } from '@angular/router';


export class DeliveryStateModel {
  deliveryList: Delivery[];
  selectedDelivery: Delivery;
  location: string;
}

@State<DeliveryStateModel>({
  name: 'delivery',
  defaults: {
    selectedDelivery: null,
    deliveryList: undefined,
    location:''
  },
})
@Injectable()
export class DeliveryState {
  constructor(private delivery: DeliveryMethodService, private store: Store, private route: ActivatedRoute) {}
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<
    Location
  >;

  @Selector()
  static getSelectedDelivery(state: DeliveryStateModel) {
    return state.selectedDelivery;
  }
  @Selector()
  static getDeliveryList(state: DeliveryStateModel) {
    return state.deliveryList;
  }
  @Selector()
  static getDeliveryLocation(state: DeliveryStateModel) {
    return state.location;
  }

  @Action(FetchDeliveryList)
  fetchDeliveries({ setState }: StateContext<DeliveryStateModel>) {
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    
    const delivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    return this.delivery.fetchDeliveries(location._id).pipe(
      tap((response) => {
        if (response && response.deliveryTypes) {
          setState(
            patch({
              deliveryList: response.deliveryTypes,
              location: response.name
            })
          );
          if (response.deliveryTypes.length == 0 || !delivery) {
            setState(
              patch({
                selectedDelivery: response.deliveryTypes[0],
              })
            );
          }
        } else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  @Action(SetSelectedDelivery)
  setLocation(
    { getState, setState }: StateContext<DeliveryStateModel>,
    { payload }: SetSelectedDelivery
  ) {
    const state = getState();
    if(state.deliveryList){
      setState(
        patch({
          selectedDelivery: state.deliveryList.find(
            (deliveryType) => deliveryType.textCode === payload
          ),
        })
      );
    }

  }
  @Action(ClearSelectedDelivery)
  ClearSelectedDelivery({ setState }: StateContext<DeliveryStateModel>) {
    setState(
      patch({
        selectedDelivery: null,
      })
    );
  }
}
