<div class="loc-access-container">
  <wj-app-header></wj-app-header>
  <div class="loc-message-container">
    <p class="loc-title">{{'location-access' | translate}}</p>
    <div class="loc-message">
      <!-- {{'enable-location' | translate}} <span class="loc-name">{{selectedLocationName}}</span> {{'services'| translate}}. -->
      {{'enable-location' | translate}} <span class="loc-name">WestJet Lounge </span> {{'services'| translate}}.
    </div>
  </div>
  <img class="loc-image" [src]="location_access" />
</div>