<!-- Modifier wrapper -->
<div class="modifier-list">
  <!-- Sub modifier items template -->
  <ng-container
    *ngIf="modifierItem?.items?.length > 0; else modifierItemTemplate"
  >
    <!-- Modifier header -->
    <div
      class="sub-modifier-header"
    >
      <div class="modifier-heading">{{ modifierItem.name | formattedLanguage : modifierItem.translation }}</div>
      <div class="modifier-requirements" *ngIf="choiceText(modifierItem)">
        {{choiceText(modifierItem)}}
      </div>
      <div *ngIf="headerErrors(modifierItem.valid)" class="option-error">
        <img src="assets/images/WJ_Error_Warning.svg"/>
        <span>{{choiceText(modifierItem)}}</span>
      </div>
    </div>

    <!-- Modifier List -->
    <div class="sub-modifier-list">
      <!-- Modifier item -->

      <ng-container *ngFor="let subItem of modifierItem.items">
        <div class="sub-modifier-item">
          <label
            [for]="subItem._id"
            [attr.data-qa]="subItem + '_label'"
            class="portion-label"
          >
            <span>{{ subItem.name | formattedLanguage : subItem.translation }}</span>
          </label>
          <div class="options" *ngIf="!checkInput(modifier); else radioInput">
            <label class="options-label">
              <input
                type="checkbox"
                class="custom-check"
                [id]="subItem._id"
                [attr.name]="modifierItem._id"
                [value]="subItem._id"
                [checked]="subItem.selected"
                (change)="subModifierItemChange($event, subItem, modifierItem)"
                [attr.data-qa]="subItem + '_input'"
              />
              <span [ngClass]="(subItem.selected) ? 'checkmark':'nocheckmark'"></span>
            </label>
          </div>
          <ng-template #radioInput>
            <label class="container">
              <input
                class="custom-check"
                type="radio"
                [id]="subItem._id"
                [attr.name]="modifierItem._id"
                [value]="subItem._id"
                [(ngModel)]="subItem.selected"
                [checked]="subItem.selected"
                (change)="subModifierItemChange($event, subItem, modifierItem)"
                [attr.data-qa]="subItem + '_input'"
                [ngClass]="{ selected: subItem.selected }"
              />
              <span class="checkmark"></span>
            </label>
          </ng-template>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <!-- Single Modifier item template -->
  <ng-template #modifierItemTemplate>
    <div class="single-modifier">
      <!-- Modifier details -->
      <label
        [attr.for]="modifierItem._id"
        class="modifier-label"
        [attr.data-qa]="modifierItem + '_label'"
      >
        {{ modifierItem.name | formattedLanguage : modifierItem.translation }}
      </label>

      <!-- Modifier input  -->
      <div class="options" *ngIf="!checkInput(modifier); else radioInput">
        <label class="options-label">
          <input
            type="checkbox"
            class="custom-check"
            [id]="modifierItem._id"
            [attr.name]="modifier._id"
            [value]="modifierItem._id"
            [checked]="modifierItem.selected"
            (change)="modifierItemChange($event, modifierItem, modifier)"
            [attr.data-qa]="modifierItem + '_input'"
          />
          <span [ngClass]="(modifierItem.selected) ? 'checkmark':'nocheckmark'"></span>
        </label>
      </div>
      <ng-template #radioInput>
        <label class="container">
          <input
            class="custom-check"
            type="radio"
            [id]="modifierItem._id"
            [attr.name]="modifier._id"
            [value]="modifierItem._id"
            [checked]="modifierItem.selected"
            (change)="modifierItemChange($event, modifierItem, modifier)"
            [attr.data-qa]="modifierItem + '_input'"
          />
          <span class="checkmark"></span>
        </label>
      </ng-template>
    </div>
  </ng-template>
</div>
