import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import {
  BaseComponent,
  AuthState,
  Auth,
  MenuListState,
  ChangeCategory,
  DeliveryState,
  WhitelabelState,
  fromOrderSuccess,
} from './../../../core';

export class MenuBaseComponent extends BaseComponent {
  profileIcon: string = 'assets/images/profileIcon.svg';
  @Input('selectedPage') selectedPage: string;
  loggedInUserDetails;
  hideProfile: boolean;
  hideOrders: boolean;

  pageList = [
    {
      id: 1,
      name: 'home',
      icon: 'assets/images/homeIcon.svg',
      activeIcon: 'assets/images/homeIcon-active.svg',
    },
    {
      id: 2,
      name: 'menu',
      icon: 'assets/images/menuIcon.svg',
      activeIcon: 'assets/images/menuIcon-active.svg',
    },
    {
      id: 3,
      name: 'orders',
      icon: 'assets/images/orderIcon.svg',
      activeIcon: 'assets/images/Bag_Items_Icon_Active.svg',
    },
    {
      id: 4,
      name: 'profile',
      icon: 'assets/images/profileIcon.svg',
      activeIcon: 'assets/images/profileIcon.svg',
    },
  ];

  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;
  constructor(
    private router: Router,
    private readonly store: Store,
    private route: ActivatedRoute
  ) {
    super();
    let path = window.location.pathname.split('/');
    this.selectedPage = path[path.length - 1];
    //this.loggedInUserDetails = localStorage.getItem("loggedInUser")? JSON.parse(localStorage.getItem("loggedInUser")):undefined;
  }

  @Input() userDetails;
  ngOnInit(): void {
    //this.setProfileIcon();
    let organizationOtherConfigs = this.store.selectSnapshot(
      WhitelabelState.getOther
    );
    let whiteLabelConfigs = this.store.selectSnapshot(
      WhitelabelState.getWhitelabel
    );
    this.hideProfile = organizationOtherConfigs.allowOnlyGuestUsers;
    this.hideOrders = whiteLabelConfigs.commonSettings.hideOrderRelatedScreens;
  }

  setMenuIcon(page) {
    let icon = this.selectedPage == page.name ? page.activeIcon : page.icon;
    if (page.name == 'profile') {
      icon = this.setProfileIcon(icon);
    }
    return icon;
  }
  setProfileIcon(icon) {
    if (
      this.loggedInUserDetails &&
      this.loggedInUserDetails.provider == 'GOOGLE'
    ) {
      return this.loggedInUserDetails.photoUrl;
    } else {
      return icon;
    }
  }

  routeTo(path) {
    if (this.selectedPage == path) return;
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    const authDetails = this.store.selectSnapshot(
      AuthState.getCommonAuthDetails
    );
    switch (path) {
      case 'menu':
        // let menuLists = this.store.selectSnapshot(MenuListState.getMenuList);
        // let selectedCategory = this.store.selectSnapshot(
        //   MenuListState.getMenuListCategory
        // );
        // if (menuLists && selectedCategory) {
        //   this.store.dispatch(new ChangeCategory(menuLists.categories[0]));
        // }
        // this.store.dispatch(new ChangeCategory(null));
        this.router.navigate([`locations/${locationId}/menu`]);
        break;
      case 'home':
        this.router.navigate([`locations/${locationId}/home`]);
        break;
      case 'view-orders':
        {
          this.store.dispatch(new fromOrderSuccess(false));
          this.router.navigate([`locations/${locationId}/view-orders`]);
        }
        break;
      case 'payments':
        this.router.navigate([`/payment`]);
        break;
      case 'profile':
        authDetails && authDetails.isLoggedIn
          ? this.router.navigate(['user-profile'])
          : this.router.navigate([`login`]);
        break;
    }
    //this.selectedPage = path;
  }
}
