
import { AuthEmail,AuthEmailSignUp,AuthEmailForgotPassword,ResetLinkRequest,ResetPasswordRequest,UserAvailability,UpdateProfileData } from '../models';
export class FetchLoggedInUserDetails {
    static readonly type = '[EMAIL-AUTH] FetchLoggedInUserDetails';
    constructor() {}
}

export class setUserDetailsOnLogin {
    static readonly type = '[EMAIL-AUTH] setUserDetailsOnLogin';
    constructor(public payload: AuthEmail){}
}

export class ClearLoginDetails {
    static readonly type = '[EMAIL-AUTH] ClearLoginDetails';
    constructor() {}
}

export class ClearExistingLoginData {
    static readonly type = '[EMAIL-AUTH] ClearExistingLoginData';
    constructor() {}
}


export class setExistingLoginData {
    static readonly type = '[EMAIL-AUTH] setExistingLoginData';
    constructor(public payload: string){}
}

export class ClearSignupData {
    static readonly type = '[EMAIL-AUTH] ClearSignupData';
    constructor() {}
}

export class SetUserDetailsOnSignUp {
    static readonly type = '[EMAIL-AUTH] SetUserDetailsOnSignUp';
    constructor(public payload: AuthEmailSignUp){}
}

export class FetchUserSignUpDetails {
    static readonly type = '[EMAIL-AUTH] FetchUserSignUpDetails';
    constructor() {}
}

export class ClearForgotPasswordData {
    static readonly type = '[EMAIL-AUTH] ClearForgotPasswordData';
    constructor() {}
}
export class SetForgotPasswordRequest {
    static readonly type = '[EMAIL-AUTH] SetForgotPasswordRequest';
    constructor(public payload: AuthEmailForgotPassword){}
}

export class UpdateUserProfileData {
  static readonly type = '[USER-PROFILE] UpdateUserProfileData';
  constructor(public payload: UpdateProfileData){}
}

export class FetchForgotPasswordResponse {
    static readonly type = '[EMAIL-AUTH] FetchForgotPasswordResponse';
    constructor(){}
}

export class CheckResetLinkValid {
    static readonly type = '[EMAIL-AUTH] CheckResetLinkValid';
    constructor(public payload: ResetLinkRequest){}
}

export class FetchResetLinkValidResponse {
    static readonly type = '[EMAIL-AUTH] FetchResetLinkValidResponse';
    constructor(){}
}


export class ClearResetLinkValid {
    static readonly type = '[EMAIL-AUTH] ClearResetLinkValid';
    constructor() {}
}

export class ResetPassword {
    static readonly type = '[EMAIL-AUTH] ResetPassword';
    constructor(public payload: ResetPasswordRequest){}
}

export class ClearResetPasswordResponse {
    static readonly type = '[EMAIL-AUTH] ClearResetPasswordResponse';
    constructor() {}
}

export class checkUserAvailability {
  static readonly type = '[EMAIL-AUTH] checkUserAvailability';
  constructor(public payload: UserAvailability){}
}
