
<div class="app-container">
  <router-outlet></router-outlet>
  <ngx-spinner
    bdColor="rgba(0, 0, 0, 0.8)"
    size="medium"
    color="#fff"
    type="timer"
    [fullScreen]="true"
    ><p style="color: white;">{{'processing'| translate}}...</p></ngx-spinner
  >
</div>