<ng-container *ngFor="let portion of portions">
  <div class="portion-list">
    <label [attr.for]="portion._id" class="portion-label">
     {{ portion.name | formattedLanguage : portion.translation }}
    </label>
      <label class="container">
        <input class="custom-radio" type="radio" [attr.id]="portion._id" name="portion" [value]="portion._id"
      [ngModel]="selectedPortion" (change)="portionChange($event, portion)" [attr.data-qa]="portion._id + '_input'" />
        <span class="checkmark"></span>
      </label>
  </div>
</ng-container>