import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { Venue } from '../models';
import {
  SetSelectedPickUpLocation,
  FetchVenues,
  ChangeRoomSelectionPopupStatus,
  ChangePickupStationPopupStatus,
  ChangeTakeoutStationPopupStatus,
  SetSelectedTakeOut,
  SetSelectedRoom,
  SetSelectedTable,
  ChangeLoungePopupStatus,
  ClearAllVenueDatas,
  SetCustomerPerTable,
  SetLocationAccess,
  ResetCustomerPerTable
} from '../actions';
import { Injectable } from '@angular/core';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { VenueManagementService } from './../services';
import { patch } from '@ngxs/store/operators';
import { LocationState } from './location.state';

export class VenueModel {
  venues: Venue[];
  selectedPickUpLocation: Venue;
  selectedTakeOut: Venue;
  selectedRoom: Venue;
  selectedLoungeTable: Venue;
  modalChangeLoungePopupStatus: boolean;
  modalChangeRoomSelectionPopupStatus: boolean;
  modalChangePickupPopupStatus: boolean;
  modalChangeTakeoutPopupStatus: boolean;
  totalPersonPerTable:Number;
  isLocationAccess:boolean;
}

@State<VenueModel>({
  name: 'venuemanagement',
  defaults: {
    venues: null,
    selectedPickUpLocation: undefined,
    selectedTakeOut: undefined,
    selectedRoom: undefined,
    selectedLoungeTable: undefined,
    modalChangeRoomSelectionPopupStatus: false,
    modalChangePickupPopupStatus: false,
    modalChangeTakeoutPopupStatus: false,
    modalChangeLoungePopupStatus: false,
    totalPersonPerTable:undefined,
    isLocationAccess:false,
  },
})
@Injectable()
export class VenueManagementState {
  constructor(
    private venueManagementService: VenueManagementService,
    private store: Store
  ) {}


  @Selector()
  static getChangeRoomSelectionPopupStatus(state: VenueModel) {
    return state.modalChangeRoomSelectionPopupStatus;
  }

  @Selector()
  static getChangePickupStationPopupStatus(state: VenueModel) {
    return state.modalChangePickupPopupStatus;
  }

  @Selector()
  static getChangeTakeoutStationPopupStatus(state: VenueModel) {
    return state.modalChangeTakeoutPopupStatus;
  }
  @Selector()
  static getChangeLoungeTablePopupStatus(state: VenueModel) {
    return state.modalChangeLoungePopupStatus;
  }

  @Action(ChangeRoomSelectionPopupStatus)
  ChangeRoomSelectionPopupStatus(
    { setState }: StateContext<VenueModel>,
    { payload }: ChangeRoomSelectionPopupStatus
  ) {
    setState(
      patch({
        modalChangeRoomSelectionPopupStatus: payload,
      })
    );
  }

  @Action(ClearAllVenueDatas)
  ClearAllVenueDatas(  { getState, patchState }: StateContext<VenueModel>,
    {}: any){
      patchState({
        venues: null,
        selectedPickUpLocation: undefined,
        selectedTakeOut: undefined,
        selectedRoom: undefined,
        selectedLoungeTable: undefined,
        modalChangeRoomSelectionPopupStatus: false,
        modalChangePickupPopupStatus: false,
        modalChangeTakeoutPopupStatus: false,
        modalChangeLoungePopupStatus: false,
        }
      );
  }

  @Action(ChangePickupStationPopupStatus)
  ChangePickupStationPopupStatus(
    { setState }: StateContext<VenueModel>,
    { payload }: ChangePickupStationPopupStatus
  ) {
    setState(
      patch({
        modalChangePickupPopupStatus: payload,
      })
    );
  }

  @Action(ChangeTakeoutStationPopupStatus)
  ChangeTakeoutStationPopupStatus(
    { setState }: StateContext<VenueModel>,
    { payload }: ChangeTakeoutStationPopupStatus
  ) {
    setState(
      patch({
        modalChangeTakeoutPopupStatus: payload,
      })
    );
  }
  @Action(ChangeLoungePopupStatus)
  ChangeLoungePopupStatus(
    { setState }: StateContext<VenueModel>,
    { payload }: ChangeLoungePopupStatus
  ) {
    setState(
      patch({
        modalChangeLoungePopupStatus: payload,
      })
    );
  }

  @Selector()
  static getVenues(state: VenueModel) {
    return state.venues;
  }

  @Action(FetchVenues)
  fetchVenues(
    { setState }: StateContext<VenueModel>,
    { payload }: FetchVenues
  ) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let locationId = selectedLocation._id;
    let orgId = Config.organizationId;
    let filters = payload;
    return this.venueManagementService
      .fetchAllVenues(locationId, orgId, filters)
      .pipe(
        tap((response) => {
          if (response) {
            setState(
              patch({
                venues: response,
              })
            );
          } else throw response;
        }),
        catchError((error) => {
          return Observable.throw(error);
        })
      );
  }

  @Selector()
  static getSelectedRoom(state: VenueModel) {
    return state.selectedRoom;
  }

  @Action(SetSelectedRoom)
  setSelectedRoom(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetSelectedRoom
  ) {
    patchState({
      selectedRoom: payload,
    });
  }

  @Selector()
  static getSelectedTakeOut(state: VenueModel) {
    return state.selectedTakeOut;
  }

  @Action(SetSelectedTakeOut)
  setSelectedTakeOut(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetSelectedTakeOut
  ) {
    patchState({
      selectedTakeOut: payload,
    });
  }

  @Selector()
  static getSelectedPickUpLocation(state: VenueModel) {
    return state.selectedPickUpLocation;
  }

  @Action(SetSelectedPickUpLocation)
  setSelectedPickUpLocation(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetSelectedPickUpLocation
  ) {
    patchState({
      selectedPickUpLocation: payload,
    });
  }
  @Selector()
  static getSelectedLoungeTable(state: VenueModel) {
    return state.selectedLoungeTable;
  }

  @Action(SetSelectedTable)
  setSelectedLoungeTable(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetSelectedTable
  ) {
    patchState({
      selectedLoungeTable: payload,
    });
  }

  @Selector()
  static getCustomersPerTable(state: VenueModel) {
    return state.totalPersonPerTable;
  }

  @Action(SetCustomerPerTable)
  setNoOfCustomersPerTable(
    { patchState,getState }: StateContext<VenueModel>,
    { payload }: SetCustomerPerTable
  ) {
    let { selectedLoungeTable } = getState();
    selectedLoungeTable['guestCount'] = payload.count;
    patchState({
      selectedLoungeTable: selectedLoungeTable,
      totalPersonPerTable: payload
    });
  }

  @Selector()
  static getLocationAccessEnabled(state: VenueModel) {
    return state.isLocationAccess;
  }

  @Action(SetLocationAccess)
  setLocationAccessEnabled(
    { patchState }: StateContext<VenueModel>,
    { payload }: SetLocationAccess
  ) {
    patchState({
      isLocationAccess: payload,
    });
  }

  @Action(ResetCustomerPerTable)
  resetfCustomersPerTable(
    { patchState,getState }: StateContext<VenueModel>,
    { payload }: ResetCustomerPerTable
  ) {
    let { selectedLoungeTable } = getState();
    patchState({
      selectedLoungeTable: selectedLoungeTable,
      totalPersonPerTable: undefined
    });
  }
}
