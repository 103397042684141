import {
  BaseComponent,
  ChangeRoomSelectionPopupStatus,
  Venue,
  FetchVenues,
  SetSelectedRoom,
  LocationDefaultState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Input, OnInit, Output, EventEmitter } from '@angular/core';
import { environment } from '../../../core/environments/environment';
import { Store, Select } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';

import { VenueManagementState } from '../../../core/state/venue-management.state';
import { Observable } from 'rxjs/internal/Observable';

export class VenueRoomTimeSelectionBaseComponent extends BaseComponent
  implements OnInit {
  backButton: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  closeButton: string =
    environment.image_basepath + environment.images.close_update_modal;
  selectedRoom: Venue = { name: '' };
  enteredRoom: string = '';
  showRoomList: boolean = false;
  requiredError: string = '';
  rooms: Venue[] = [];
  noRoomsSelectedError: boolean = false;
  allowRoomManualEntering: boolean = true;
  @Output() closeEvent = new EventEmitter();
  @Select(VenueManagementState.getVenues) venues$: Observable<Venue[]>;

  constructor(protected store: Store) {
    super();
  }

  ngOnInit() {
    this.populateInitialData();
  }

  populateInitialData() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowRoomEntering
    ) {
      this.allowRoomManualEntering =
        locationDefaults.locationData.orderSettings.allowRoomEntering;
      const selectedRoom = this.store.selectSnapshot(
        VenueManagementState.getSelectedRoom
      );
      if (selectedRoom) {
        this.enteredRoom = selectedRoom.name;
      }
    } else {
      this.allowRoomManualEntering = false;
    }
    if (!this.allowRoomManualEntering) {
      let filter = {
        tableType: 'TABLETYPE05',
      };
      this.store.dispatch(new FetchVenues(filter));
      this.venues$.pipe(takeUntil(this.destroy$)).subscribe((venues) => {
        if (venues && venues.length > 0) {
          this.rooms = venues;
          const selectedRoom = this.store.selectSnapshot(
            VenueManagementState.getSelectedRoom
          );
          if (selectedRoom) {
            this.roomSelection(selectedRoom);
          }
        } else {
          this.noRoomsSelectedError = true;
        }
      });
    }
  }

  closePopup() {
    this.store.dispatch(new ChangeRoomSelectionPopupStatus(false));
  }

  openRoomSelectionList(event) {
    event.stopPropagation();
    event.preventDefault();
    this.showRoomList = !this.showRoomList;
  }

  roomSelection(station, event?) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    this.requiredError = '';
    this.selectedRoom = station;
    this.showRoomList = false;
  }

  proceed() {
    if (this.allowRoomManualEntering && this.enteredRoom != '') {
      this.selectedRoom.name = this.enteredRoom;
    }
    if (this.selectedRoom && this.selectedRoom.name != '') {
      this.store.dispatch(new SetSelectedRoom(this.selectedRoom));
      this.closeSelectionModal();
    } else {
      this.handleError();
    }
  }

  handleError() {
    if (this.allowRoomManualEntering)
      this.requiredError = 'Please enter a room to continue';
    else this.requiredError = 'Please select a room to continue';
  }

  closeSelectionModal() {
    this.closeEvent.emit();
  }
}
