import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Location, LocationInfo, LocationStatus } from '../models';
import {
  FetchLocationList,
  SetSelectedLocation,
  setLocationDistance,
  FetchLocationInfo,
  FetchLocationStatus,
  FetchCart,
  ClearCart,
  ClearSelectedDelivery,
  ClearFutureDate,
  FetchFreshSelectedLocation,
  ClearSelectedLocation,
  UpdateDeliverableStatus,
} from '../actions';
import { Injectable } from '@angular/core';
import { LocationService } from '../services';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class LocationStateModel {
  locationList: Location[];
  selectedLocation: Location;
  locationInfo: LocationInfo;
  locationStatus: LocationStatus;
  locationDistance: string;
}

@State<LocationStateModel>({
  name: 'location',
  defaults: {
    selectedLocation: null,
    locationList: undefined,
    locationInfo: undefined,
    locationStatus: undefined,
    locationDistance: undefined,
  },
})
@Injectable()
export class LocationState {
  locationImages = [
    'assets/images/organization_1.png',
    'assets/images/organization_2.png',
    'assets/images/organization_3.png',
  ];

  constructor(private locations: LocationService, private store: Store) {}

  @Selector()
  static getLocationsList(state: LocationStateModel) {
    return state.locationList;
  }

  @Selector()
  static getSelectedLocation(state: LocationStateModel) {
    return state.selectedLocation;
  }
  @Selector()
  static getLocationInfo(state: LocationStateModel) {
    return state.locationInfo;
  }
  @Selector()
  static getLocationStatus(state: LocationStateModel) {
    return state.locationStatus;
  }

  @Action(FetchLocationInfo)
  fetchLocationInfo(
    { setState }: StateContext<LocationStateModel>,
    { payload }: FetchLocationInfo
  ) {
    return this.locations.fetchLocationInfo(payload).pipe(
      tap((response) => {
        if (response)
          setState(
            patch({
              locationInfo: response,
            })
          );
        else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  @Action(FetchLocationList)
  fetchLocations(
    { setState }: StateContext<LocationStateModel>,
    { payload }: FetchLocationList
  ) {
    return this.locations.fetchLocations(payload).pipe(
      map((locations) => this.formatLocation(locations)),
      tap((response) => {
        if (response) {
          setState(
            patch({
              locationList: response,
            })
          );
        } else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  @Action(SetSelectedLocation)
  setLocation(
    { getState, setState }: StateContext<LocationStateModel>,
    { payload }: SetSelectedLocation
  ) {
    const { locationList, selectedLocation } = getState();
    const newLocation = locationList.find(
      (location) => location._id === payload
    );
    if (newLocation == undefined) return;
    if (selectedLocation && selectedLocation._id !== newLocation._id) {
      this.store.dispatch(new ClearCart(newLocation._id));
      this.store.dispatch(new ClearSelectedDelivery());
      this.store.dispatch(new ClearFutureDate());
      this.store.dispatch(new UpdateDeliverableStatus(false));
      localStorage.setItem('selectedAdrsLocation', undefined);
    }
    setState(
      patch({
        selectedLocation: newLocation,
      })
    );
    // Why are we doing this ??
    localStorage.setItem('selectedLocation', JSON.stringify(newLocation));
    this.store.dispatch(new setLocationDistance(payload));
  }

  @Action(setLocationDistance)
  updateLocationDistance(
    { getState, setState }: StateContext<LocationStateModel>,
    { payload }: setLocationDistance
  ) {
    const state = getState();
    return this.locations.updateLocationDistance(payload).pipe(
      tap((response) => {
        if (response && response['data']) {
          setState(
            patch({
              locationDistance: response['data'][0].distanceFromAddress,
            })
          );
          localStorage.setItem(
            'locationDistance',
            JSON.stringify(response['data'][0].distanceFromAddress)
          );

          this.store.dispatch(new FetchCart());
        } else {
          localStorage.removeItem('locationDistance');

          this.store.dispatch(new FetchCart());
        }
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }

  formatLocation(locations) {
    if (locations.length && locations.length > 0) {
      locations = locations.map((location, index) => {
        const multiplesOfThree = index > 2 ? Math.floor(index % 3) : index;
        const {
          streetAddress,
          city,
          state,
          country,
          postalcode,
        } = location.address;
        location.address = `${streetAddress}, ${city}, ${state}, ${country}, ${postalcode}`;
        location.isLocationOpen = true;
        location.servicesSupported = location.deliveryTypes.map(
          (delivery) => delivery.text
        );
        return location;
      });
    } else {
      const {
        streetAddress,
        city,
        state,
        country,
        postalcode,
      } = locations.address;
      locations.address = `${streetAddress}, ${city}, ${state}, ${country}, ${postalcode}`;
      locations.isLocationOpen = true;
      locations.servicesSupported = locations.deliveryTypes.map(
        (delivery) => delivery.text
      );
    }
    return locations;
  }

  @Action(FetchLocationStatus)
  fetchLocationStatus(
    { setState }: StateContext<LocationStateModel>,
    { payload }: FetchLocationStatus
  ) {
    return this.locations.fetchLocationStatus(payload).pipe(
      tap((response) => {
        setState(
          patch({
            locationStatus: response,
          })
        );
      })
    );
  }

  @Action(FetchFreshSelectedLocation)
  fetchFreshSelectedLocation(
    { getState, patchState }: StateContext<LocationStateModel>,
    {}: FetchFreshSelectedLocation
  ) {
    const { selectedLocation } = getState();
    if (selectedLocation && selectedLocation._id) {
      patchState({
        selectedLocation: { ...selectedLocation, loading: true },
      });
      this.locations
        .fetchFreshSelectedLocation(selectedLocation._id)
        .pipe(map((locations) => this.formatLocation(locations)))
        .subscribe((response) => {
          const selected = response as Location;
          patchState({
            selectedLocation: selected,
          });
        });
    }
  }
  @Action(ClearSelectedLocation)
  clearSelectedLocation({ setState }: StateContext<LocationStateModel>) {
    setState(
      patch({
        selectedLocation: null,
      })
    );
  }
}
