import { OnInit } from '@angular/core';
import { DeliveryState, SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';

export class ScreenSwitchWrapperBaseComponent implements OnInit {
    isMobileView: boolean;
    //singleLocation: boolean;

    constructor(private shared: SharedService, public store:Store) {}

    ngOnInit() {
        this.isMobileView = this.shared.isMobileScreen();
    }
}
