
<div class="background-overlay">
  <div class="content-modal">
    <div class="modal-content">
      <ng-container>
<div class="description-outer">
<div (click)="backToHome()" class="description-container close-position">
  <span class="close-text" [theme]="themeOptions">{{'close' | translate }}</span>
  <img
  src="/assets/images/wj_Icon_24_Close.svg"
  data-qa="close-button"
  class="close"
  aria-label="close"
/>
</div>
</div>
<div class="access" [theme]="themeOptions"> {{'guest-description' | translate}}.</div>
<div class="guest-number">
  <input  tabindex="1" #customerCount type="tel" maxlength="3" [ngClass]="{errorBox:isHavingValue,normalInput:(personCount && personCount>0)}" name="customerCount" [(ngModel)]="personCount" (keyup)="checkFormat($event)" id="customerCount" class="form__field" placeholder="Number of guests">
  <label for="customerCount" [theme]="themeOptions" [ngClass]="{errorLabel:(isHavingValue || personCount <1),normalLabel:(personCount && personCount>=0)}" class="form__label">{{'number-guests' | translate}}</label>
  <span class="clear-text"*ngIf="!isHavingValue&&personCount>0" (click)="clearvalue()" [theme]="themeOptions">{{'clear' | translate}}</span>
  <div
  class="text-danger"
  *ngIf="isHavingValue"
>
  {{ 'valid-number' | translate }}
</div>
</div>

<div class="btn-container"><button type="button" [theme]="themeOptions" (click)="personCountSubmit(customerCount.value)"  class="btn-primary">{{ 'continue' | translate}}</button></div>
      </ng-container>
      <ng-template #futureLoader>
        <div class="loader-date loader"></div>
        <div class="loader-time loader"></div>
      </ng-template>
    </div>
  </div>
</div>

