import {
  BaseComponent,
  environment,
  SharedService
} from '@moduurnv2/libs-orderingapp/src/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { SelectedOrderState } from '@moduurnv2/libs-orderingapp/src/core';

import { OrderDetailsService } from './../../../core/services/order-details.service';
import { OrderReceiptService } from './../../../core/services/order-receipt.service';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';

interface Constant {
  text: string;
  textCode: string;
  textType: string;
}
export interface OrderReceipt {
  _id: string;
  orderNumber: string;
  location: {
    name: string;
    address: string;
    hidePrice: boolean;
  };
  deliveryMethod: Constant;
  delivery: {
    address: string;
  };
  paymentMode: string;
  deliveryStatus: Constant;
  itemDetails: any;
  orderPlacedDate: string;
  locationDetails: any;
  orderLog: any;
  orderedBy: any;
  payment: any;
  totalItemCount: string;
  totalPrice: string;
}
export class OrderReceiptBaseComponent extends BaseComponent {
  orderSettings:any;
  hidePrice:boolean;
  orderReceipt: OrderReceipt;
  deliveryStatusText: string;
  private _orderReceiptSubscription: Subscription;
  private _receiptDownloadSubscription: Subscription;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private _orderReceiptService: OrderReceiptService,
    private _orderDetailsService: OrderDetailsService,
    private _location: Location,
    private store: Store,
    public modal:ModalService,
    private shared: SharedService
  ) {
    super();
    
  }

  imgBasePath: string = environment.image_basepath;
  icon = {
    cancel: this.imgBasePath + environment.images.cancel_icon,
    cancelPng: this.imgBasePath + environment.images.cancel_icon_png,
    save: this.imgBasePath + environment.images.save_icon,
    savePng: this.imgBasePath + environment.images.save_icon_png,
    orderAgain: this.imgBasePath + environment.images.order_again_icon,
    orderAgainPng: this.imgBasePath + environment.images.order_again_icon_png,
  };
  orderId: string;
  isMobileDevice: boolean = false;
  ngOnInit(): void {
    this.isMobileDevice = this.shared.isMobileScreen();
    const order_id = this.store.selectSnapshot(
      SelectedOrderState.getSelectedOrderId
    );
    if (order_id) {
      this.orderId = order_id;
      this.getOrderReceiptDetails(this.orderId);
    }
  }

  ngOnDestroy() {
    if (this._orderReceiptSubscription)
      this._orderReceiptSubscription.unsubscribe();
    if (this._receiptDownloadSubscription)
      this._receiptDownloadSubscription.unsubscribe();
  }

  getOrderReceiptDetails(orderId) {
    this._orderReceiptSubscription = this._orderReceiptService
      .getOrderReceiptDetails(orderId)
      .subscribe((res) => {
        if (res && res.data) {
          this.orderReceipt = res.data;
          if(this.orderReceipt && this.orderReceipt.location){
            this.hidePrice = this.orderReceipt.location.hidePrice
          }
          if(this.orderReceipt && this.orderReceipt.locationDetails){
            this.orderSettings = this.orderReceipt.locationDetails.orderSettings;
          }
          this.deliveryStatusText = this._orderDetailsService.getOrderStatusText(
            this.orderReceipt.deliveryStatus.textCode,
            this.orderReceipt.deliveryMethod.textCode
          );
        }
      });
  }

  saveReceipt() {
    const dataForReceipt = {
      offset: new Date().getTimezoneOffset(),
      order: {
        _id: this.orderReceipt._id,
        deliveryMethod: this.orderReceipt.deliveryMethod,
        locationId: this.orderReceipt.locationDetails,
        orderLog: this.orderReceipt.orderLog,
        orderNumber: this.orderReceipt.orderNumber,
        orderedBy: this.orderReceipt.orderedBy,
        payment: this.orderReceipt.payment,
        totalItemCount: this.orderReceipt.totalItemCount,
        totalPrice: this.orderReceipt.totalPrice,
      },
      time: new Date().toISOString(),
    };
    this._receiptDownloadSubscription = this._orderReceiptService
      .fetchReciept(dataForReceipt)
      .subscribe(
        (data) => {
          let pdfData = data as string;
          const downloadLink = document.createElement('a');
          const fileName = `${dataForReceipt.order.orderNumber}.pdf`;
          downloadLink.href = pdfData;
          downloadLink.download = fileName;
          downloadLink.click();
        },
        (err) => {}
      );
  }
  goBack() {
    if (
      this.orderReceipt &&
      this.orderReceipt.locationDetails &&
      this.orderReceipt.locationDetails._id
    ) {
      this.router.navigate([
        `/locations/${this.orderReceipt.locationDetails._id}/order-details`,
      ]);
    }
  }
}
