import { State, Selector, StateContext, Action } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { CustomerFavourites } from '../models';
import { FetchCustomerFavouritesList } from '../actions';
import { Injectable } from '@angular/core';
import { CustomerFavouritesService } from '../services';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

export class CustomerFavouritesStateModel {
  customerFavouritesList: CustomerFavourites[];
}

@State<CustomerFavouritesStateModel>({
  name: 'customerfavourites',
  defaults: {
    customerFavouritesList: undefined
  }
})
@Injectable()
export class CustomerFavouritesState {
  constructor(private _customerFavourites: CustomerFavouritesService) {}

  @Selector()
  static getCustomerFavouritesList(state: CustomerFavouritesStateModel) {
    return state.customerFavouritesList;
  }

  @Action(FetchCustomerFavouritesList)
  fetchCustomerFavourites(
    { setState }: StateContext<CustomerFavouritesStateModel>,
    { payload }: FetchCustomerFavouritesList
  ) {
    return this._customerFavourites.fetchCustomerFavourites(payload).pipe(
      tap(response => {
        if (response)
          setState(
            patch({
              customerFavouritesList: response
            })
          );
        else throw response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }
}
