<div class="navbar" id="myNavbar">
  <div
    class="nav-item"
    (click)="routeTo('home')"
    [class.active]="selectedPage == 'home'"
    attr.aria-label="{{ 'home' | translate }}"
    data-qa="home"
  >
    <div class="icon home-icon"></div>
    <div class="nav-text">{{ 'home' | translate }}</div>
  </div>
  <div
    class="nav-item"
    (click)="routeTo('menu')"
    [class.active]="selectedPage == 'menu'"
    attr.aria-label="{{ 'menu' | translate }}"
    data-qa="menu"
  >
    <div class="icon menu-icon"></div>
    <div class="nav-text">{{ 'menu' | translate }}</div>
  </div>
</div>
