import { OnInit, Output, EventEmitter } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import {
  SetOrder,
  ClearOrderResponseMessage,
  PlaceOrder,
  OrderedUser,
  Order,
  Cart,
  CartState,
  CartItems,
  AdditionalParams,
  PaymentRequest,
  PaymentRequestType,
  Custom,
  PaymentProfile,
  Token,
  ClearPaymentDetails,
  PaymentMethodState,
  OrderState,
  Address,
  SweetAlertService,
  PushNotificationState,
  PushNotification,
  AuthEmailState,
  WhitelabelState,
  LocationState,
  AddressState,
  SetOrderDetails,
  SetMenuItem,
  ClearCart,
  DeliveryState,
  SavedCard,
  NewCard,
  SharedService,
  SetSelectedOrderId,
  VenueManagementService,
  VenueManagementState,
  environment,
} from '@moduurnv2/libs-orderingapp/src/core';
import { takeUntil } from 'rxjs/operators';
import {
  ProfileToken,
  OrderResponse,
  TimeInfo,
} from '@moduurnv2/libs-orderingapp/src/core/models/order.interface';
import { MessagingService } from '@moduurnv2/web/core/services/messaging-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { PaymentMethodModel } from '@moduurnv2/libs-orderingapp/src/core/state/payment-method.state';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { AlertPopupComponent } from '@moduurnv2/web/features/ui/components/alert-popup/alert-popup.component';
export class OrderCompleteBaseComponent implements OnInit {
  @Output() changePayMethod = new EventEmitter();
  @Select(CartState.getCart) cartPrice$: Observable<Cart>;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  @Select(OrderState.getOrderResponse) orderResponse$: Observable<
    OrderResponse
  >;
  @Select(PaymentMethodState.getSelectedPaymentType)
  selectedPaymentType$: Observable<string>;

  @Select(AddressState.getDeliveryAddress) selectedAddress$: Observable<
    Address
  >;
  @Select(PaymentMethodState.getPaymentState) paymentState$: Observable<
    PaymentMethodModel
  >;
  @Select(WhitelabelState.getOther) orgOtherConfigs$: Observable<any>;
  bannerImg = 'assets/images/order-complete-banner.png';
  phoneImg = 'assets/images/Phone-Icon-Big.svg';
  userDetailsForm: FormGroup;
  customerSelectedCard = {} as SavedCard;
  customerNewCard = {} as NewCard;
  selectedImage: string = null;
  showDropdown = false;
  canadaFlag = 'assets/images/Flag_of_Canada.svg';
  userDetails = {} as OrderedUser;
  orderDetails = {} as Order;
  pushNotification = {} as PushNotification;
  cartPrice: Cart;
  cartItems: CartItems[];
  specialInstructionText: String = '';
  deliveryAddress = {} as Address;
  cartOrder = {} as PlaceOrder;
  selectedPaymentType: string;
  disableButton: boolean = false;
  formSubmitted: boolean = false;
  isMobileView: boolean = true;
  phoneNumber = {
    international: '',
    valid: undefined,
  };
  additionalParams = {
    payment: {
      locationId: '',
      organizationId: '',
      cartId: '',
      paymentRequest: {
        amount: 0,
        order_number: '',
        comments: '',
        custom: {
          ref1: '',
        } as Custom,
        token: {
          code: '',
          name: '',
        } as Token,
        payment_profile: {
          customer_code: '',
          card_id: '',
        } as PaymentProfile,
      } as PaymentRequestType,
      userId: '',
      paymentProfileId: '',
      payUsingSavedCard: false,
      profileToken: {
        token: {
          code: '',
          name: '',
        } as Token,
      } as ProfileToken,
      giftCardDetails: null,
    } as PaymentRequest,
    savedCard: false,
    saveToAccount: false,
    cardType: '',
    session: '',
  } as AdditionalParams;
  destroy$: Subject<boolean> = new Subject<boolean>();
  orderData = {
    amount: 0,
    paymentType: '',
    deliveryMethod: '',
    futureOrder: false,
    deliveryTime: '',
    cardNumber: '',
    cardType: '',
  };
  paymentState: PaymentMethodModel;
  bypassCustomerDetailsEntry: boolean = true;
  isBypassedDetailsOrderPlaced: boolean = false;
  isProcessing: boolean = false;
  constructor(
    protected fb: FormBuilder,
    protected store: Store,
    protected router: Router,
    protected sweetAlert: SweetAlertService,
    protected spinner: NgxSpinnerService,
    protected shared: SharedService,
    protected venueManagementService: VenueManagementService,
    protected modal: ModalService,
    protected messagingService: MessagingService
  ) {
    this.bypassCustomerDetailsWithoutManualEntry();
    this.cartPrice$.pipe(takeUntil(this.destroy$)).subscribe((cartPrice) => {
      if (cartPrice) {
        this.cartPrice = cartPrice;
      }
    });
    this.cartItems$.pipe(takeUntil(this.destroy$)).subscribe((cartItems) => {
      if (cartItems) {
        this.cartItems = cartItems;
      }
    });
    this.selectedAddress$
      .pipe(takeUntil(this.destroy$))
      .subscribe((address) => {
        if (address) {
          this.deliveryAddress = address;
        }
      });

    this.selectedPaymentType$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedPaymentType) => {
        if (selectedPaymentType) {
          this.selectedPaymentType = selectedPaymentType;
        }
      });
    this.paymentState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentState) => {
        if (paymentState) {
          this.paymentState = paymentState;
        }
      });
    this.orderResponse$
      .pipe(takeUntil(this.destroy$))
      .subscribe((orderResponse) => {
        this.spinner.hide();
        if (
          this.bypassCustomerDetailsEntry &&
          (orderResponse == undefined || orderResponse.order_id == '')
        ) {
          if (!this.isBypassedDetailsOrderPlaced) {
            this.placeOrder();
          }
        }
        if (orderResponse && orderResponse.order_id) {
          this.isProcessing = false;
          this.store.dispatch(new ClearPaymentDetails());
          this.store.dispatch(new ClearCart());
          this.store.dispatch(new SetMenuItem(null));
          this.store.dispatch(new SetSelectedOrderId(orderResponse.order_id));
          this.store.dispatch(new ClearOrderResponseMessage());
          let self = this;
          setTimeout(function () {
            self.router.navigate([`/order/success`]);
          }, 3000);
        } else if (orderResponse && orderResponse.message != '') {
          this.modal.showSmallModal(AlertPopupComponent, {
            data: {
              header: orderResponse.title?orderResponse.title:"Error",
              content: orderResponse.message,
              items: orderResponse.items,
              actionable: false,
            },
            whenClosed: (data) => {
              const selectedLocation = this.store.selectSnapshot(
                LocationState.getSelectedLocation
              );
              if (selectedLocation)
                this.router.navigate([
                  `/locations/${selectedLocation._id}/order`,
                ]);
            },
          });
          this.store.dispatch(new ClearOrderResponseMessage());
        }
        this.formSubmitted = false;
        this.disableButton = false;
      });
  }

  ngOnInit() {
    let user = this.store.selectSnapshot(AuthEmailState.getUserDetails);
    this.isMobileView = this.shared.isMobileScreen();
    let guest = JSON.parse(localStorage.getItem('guest'));
    let isGuest = user && user.user_id ? false : true;
    if (isGuest && guest) {
      this.userDetails.name = guest.name;
      this.userDetails.phone = guest.phone;
    } else {
      this.userDetails.name =
        (user && user.fname ? user.fname : '') +
        (user && user.lname ? ' ' + user.lname : '');
      this.userDetails.phone =
        user && user.primaryPhone ? user.primaryPhone : '';
    }

    this.userDetailsForm = this.fb.group({
      name: [
        this.userDetails.name,
        [Validators.required, Validators.pattern(/^[A-Za-z0-9 ]+$/)],
      ],
      // number: [
      //   this.userDetails.phone,
      //   this.phoneNumber.international == '' || this.phoneNumber.valid == true,
      // ],
      acceptTerms: [false, Validators.requiredTrue],
    });
    this.selectedImage = this.canadaFlag;
    this.getCardDetails();
  }

  goBack() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation)
      this.router.navigate([`/locations/${selectedLocation._id}/order`]);
  }
  changeView() {
    this.changePayMethod.emit(true);
  }
  onClick() {
    this.showDropdown = !this.showDropdown;
  }

  onSelect(event) {
    this.showDropdown = false;
    this.selectedImage = this.canadaFlag;
  }
  formattedPhone(event) {
    this.phoneNumber = event;
  }
  async checkAndPlaceOrder() {
    let vm = this;
    let notification = localStorage.getItem('PushNotification')
      ? JSON.parse(localStorage.getItem('PushNotification'))
      : undefined;

    if (
      this.messagingService.checkForNotificationPopup() &&
      notification != 'denied'
    ) {
      await this.sweetAlert
        .allowPushNotification(
          ' In-app Order Updates',
          'Moduurn wishes to keep you updated on your order status through push notification on your device.',
          false
        )
        .then((choice) => {
          if (choice) {
            return vm.messagingService.askPermission().then((response) => {
              vm.placeOrder();
            });
          } else {
            localStorage.setItem('PushNotification', JSON.stringify('denied'));
            vm.placeOrder();
          }
        });
    } else {
      vm.placeOrder();
    }
  }
  async confirmOrder() {
    this.formSubmitted = true;
    if (this.userDetailsForm.valid && this.phoneNumber.valid) {
      if (!this.isMobileView) await this.checkAndPlaceOrder();
      else this.placeOrder();
    }
  }

  getCardDetails() {
    // if(this.paymentState.car)
    if (this.paymentState.cardType != '') {
      if (this.paymentState.payUsingSavedCard) {
        this.orderData.cardNumber = this.paymentState.selectedCard.number.substring(
          this.paymentState.selectedCard.number.length - 4
        );
      } else {
        this.orderData.cardNumber = this.paymentState.newCard.number.substring(
          this.paymentState.newCard.number.length - 4
        );
      }
      this.orderData.cardType = this.paymentState.cardType;
    }
  }

  placeOrder() {
    this.isBypassedDetailsOrderPlaced = true;
    // this.spinner.show();
    this.isProcessing = true;
    this.disableButton = true;
    const pushNotificationToken = this.store.selectSnapshot(
      PushNotificationState.getPushNotification
    );
    let user = this.store.selectSnapshot(AuthEmailState.getUserDetails);

    this.userDetails.email = user && user.email ? user.email : '';
    this.userDetails.name = this.userDetailsForm?.value.name;
    if (
      this.phoneNumber?.international == '' ||
      this.phoneNumber?.valid == true
    ) {
      this.userDetails.phone = this.phoneNumber?.international
        ? this.phoneNumber.international
        : '';
    }
    if (user && user.user_id) {
      this.userDetails.userId = user && user.user_id ? user.user_id : '';
    } else {
      localStorage.setItem('guest', JSON.stringify(this.userDetails));
    }
    this.orderDetails.orderedBy = this.userDetails;
    this.orderDetails.orderVia = 'web';
    this.orderDetails.orderLater = false;
    this.orderDetails.cartId = this.cartPrice.cart_id;
    this.orderDetails.organizationId = this.cartItems[0].organizationId;
    this.orderDetails.specialInstructions = this.store.selectSnapshot(
      CartState.getSpecialInstruction
    );
    this.orderDetails.locationId = this.cartPrice.locationId;
    this.orderDetails.deliveryMethod = this.cartItems[0].deliveryMethod.textCode;
    this.orderData.deliveryMethod = this.cartItems[0].deliveryMethod.textCode;
    let deliveryTime = new Date().toISOString();
    const futureDate = this.store.selectSnapshot(CartState.getFutureDate);
    if (futureDate) {
      deliveryTime = new Date(futureDate).toISOString();
      this.orderDetails.orderLater = true;
      this.orderData.futureOrder = true;
      this.orderData.deliveryTime = deliveryTime;
    }
    this.orderDetails.deliveryTime = deliveryTime;
    if (this.deliveryAddress && !this.isEmptyobject(this.deliveryAddress)) {
      this.orderDetails.deliveryAddress = this.deliveryAddress;
    }
    if (pushNotificationToken) {
      this.pushNotification.key = pushNotificationToken.token;

      const whitelabel = this.store.selectSnapshot(
        WhitelabelState.getWhitelabel
      );

      if (whitelabel && whitelabel.webOrderingUrl)
        this.pushNotification.click_action =
          whitelabel.webOrderingUrl + '/notification';
      if (whitelabel && whitelabel.restaurantLogo)
        this.pushNotification.icon =
          environment.imagebaseurl + whitelabel.faviconImage;
    }

    this.cartOrder.order = this.orderDetails;
    this.cartOrder.paymentType = this.selectedPaymentType;
    this.cartOrder.locationId = this.cartPrice.locationId;
    this.cartOrder.pushNotification = this.pushNotification;
    this.orderData.paymentType = this.selectedPaymentType;
    this.orderData.amount = this.cartPrice.totalAmount;

    let paymentState = this.store.selectSnapshot(
      PaymentMethodState.getPaymentState
    );

    if (this.selectedPaymentType == 'payNow') {
      this.additionalParams.savedCard = false;
      this.additionalParams.payment.locationId = this.cartPrice.locationId;
      this.additionalParams.payment.organizationId = this.cartItems[0].organizationId;
      this.additionalParams.payment.cartId = this.cartPrice.cart_id;
      this.additionalParams.saveToAccount = paymentState.saveToAccount;

      this.additionalParams.payment.paymentRequest.amount = this.cartPrice.price;
      this.additionalParams.payment.paymentRequest.paymentGateway =
        'PAYMENTGATEWAY02';
      let session = this.cartPrice.cart_id + new Date().getTime();
      this.additionalParams.payment.paymentRequest.order_number = session;
      this.additionalParams.payment.paymentRequest.comments = this.userDetails.name;
      this.additionalParams.payment.paymentRequest.custom.ref1 = JSON.stringify(
        this.userDetails
      );
      if (paymentState.paymentDetails && paymentState.paymentDetails.token) {
        this.additionalParams.payment.paymentRequest.token =
          paymentState.paymentDetails.token;
      }

      this.additionalParams.cardType = paymentState.cardType
        ? paymentState.cardType.toUpperCase()
        : '';
      this.additionalParams.session = session;
      if (paymentState.payUsingSavedCard) {
        this.additionalParams.savedCard = true;
        this.additionalParams.payment.paymentRequest.payment_profile = {
          customer_code: paymentState.customerProfileId,
          card_id: paymentState.selectedCard.card_id,
        };
      }

      this.additionalParams.payment.paymentProfileId =
        paymentState.customerProfileId;
      if (
        paymentState.paymentDetails &&
        paymentState.paymentDetails.profileToken
      )
        this.additionalParams.payment.profileToken.token =
          paymentState.paymentDetails.profileToken;

      this.additionalParams.payment.giftCardDetails = {};

      this.additionalParams.payment.userId = user && user.user_id;

      this.cartOrder.additionalParams = this.additionalParams;
    } else {
      delete this.cartOrder.additionalParams;
    }
    let delivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
    const currDate = new Date();
    this.cartOrder.timeInfo = {
      locationId: this.cartPrice.locationId,
      type: delivery.textCode,
      currentTime: currDate.toISOString(),
      future: futureDate,
      timezoneOffset: currDate.getTimezoneOffset(),
      selectedTime: deliveryTime,
    };
    this.store.dispatch(new SetOrder(this.cartOrder));
    this.store.dispatch(new SetOrderDetails(this.orderData));
  }

  isEmptyobject(object) {
    for (var key in object) {
      if (object.hasOwnProperty(key)) {
        return false;
      }
    }
    return true;
  }

  // validateVenueSelection() {
  //   let delivery = this.store.selectSnapshot(DeliveryState.getSelectedDelivery);
  //   if (this.venueManagementService.isVenueManagementOrder(delivery.textCode)) {
  //     let selectedVenue = this.getVenueByDeliveryTypeTextCode(
  //       delivery.textCode
  //     );
  //     if (selectedVenue && selectedVenue.name) {
  //       return true;
  //     } else {
  //       this.forceUserSelectVenue(delivery.textCode);
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // }

  // forceUserSelectVenue(deliveryType) {
  //   if (this.shared.isMobileScreen()) {
  //     this.modal.showModal(this.venueSelectionModalToShow(deliveryType));
  //   } else {
  //     this.modal.showMediumModalDesktop(
  //       this.venueSelectionModalToShow(deliveryType)
  //     );
  //   }
  // }

  // venueSelectionModalToShow(deliveryType) {
  //   switch (deliveryType) {
  //     case 'DELIVERYTYPE08':
  //       this.sweetAlert.toast('error', 'Please select a table to place order');
  //       return LoungeUpdateSelectionComponent;
  //     case 'DELIVERYTYPE09':
  //       this.sweetAlert.toast(
  //         'error',
  //         'Please select a pickup station to place order'
  //       );
  //       return PickupLocationUpdateSelectionComponent;
  //     case 'DELIVERYTYPE10':
  //       this.sweetAlert.toast(
  //         'error',
  //         'Please select your room to place order'
  //       );
  //       return RoomUpdateSelectionComponent;
  //     default:
  //       break;
  //   }
  // }

  // getVenueByDeliveryTypeTextCode(deliveryType) {
  //   let selectedVenue;
  //   switch (deliveryType) {
  //     case 'DELIVERYTYPE08':
  //       selectedVenue = this.store.selectSnapshot(
  //         VenueManagementState.getSelectedLoungeTable
  //       );
  //       break;
  //     case 'DELIVERYTYPE09':
  //       selectedVenue = this.store.selectSnapshot(
  //         VenueManagementState.getSelectedPickUpLocation
  //       );
  //       break;
  //     case 'DELIVERYTYPE10':
  //       selectedVenue = this.store.selectSnapshot(
  //         VenueManagementState.getSelectedRoom
  //       );
  //       break;
  //     default:
  //       break;
  //   }
  //   return selectedVenue;
  // }

  bypassCustomerDetailsWithoutManualEntry() {
    let otherConfigs = this.store.selectSnapshot(WhitelabelState.getOther);
    // this.orgOtherConfigs$.pipe(takeUntil(this.destroy$)).subscribe((otherConfigs)=>{
    if (otherConfigs) {
      this.bypassCustomerDetailsEntry = otherConfigs.bypassCustomerDetailsEntry
        ? otherConfigs.bypassCustomerDetailsEntry
        : false;
    } else {
      this.bypassCustomerDetailsEntry = false;
    }
    // })
  }

  placeOrderWithoutManualEntry() {
    if (this.bypassCustomerDetailsEntry) {
      this.placeOrder();
    }
  }
}
