import { Injectable, ComponentRef, Type } from '@angular/core';
import { OverlayComponent } from './overlay.component';
import { OverlayLargeDesktopComponent } from './overlay-large-desktop/overlay-large-desktop.component';
import { OverlayMediumDesktopComponent } from './overlay-medium-desktop/overlay-medium-desktop.component';
import { DomService } from './dom.service';
import { IModalConfig } from './base-modal.component';
import { OverlayFutureComponent } from './overlay-future/overlay-future.component';
import { OverlaySmallFormComponent } from './overlay-small-form/overlay-small-form.component';
import { OverlayGreyedOutComponent } from './overlay-greyed-out/overlay-greyed-out.component';
import { OverlayLocInfoComponent } from './overlay-loc-info/overlay-loc-info.component';
import { OverlayFlashMobileComponent } from './overlay-flash-mobile/overlay-flash-mobile.component';
import { OverlaySmallComponent } from './overlay-small/overlay-small.component';
import { OverlayModifierComponent } from './overlay-modifier/overlay-modifier.component';
@Injectable({
  providedIn: 'root',
})
export class ModalService {
  private openModals: { [idRef: number]: ComponentRef<any> } = {};
  constructor(private domService: DomService) {}

  closeModal(refId: number): void {
    document.body.style.overflow = 'initial';
    document.body.style.position = 'relative';
    this.domService.removeComponentFromBody(refId);
  }
  closeComponentModal(refId, data?) {
    document.body.style.overflow = 'initial';
    document.body.style.position = 'relative';
    this.openModals[refId].instance.closeModal(data);
  }

  closeAllComponentModals(refId, data?) {
    document.body.style.overflow = 'initial';
    document.body.style.position = 'relative';
    // this.openModals[refId].instance.closeModal(data);

    if (this.openModals)
    for (const [key, value] of Object.entries(this.openModals)) {
      for(let i=0;i<Object.entries(this.openModals).length;i++){
        if(i == Object.entries(this.openModals).length - 1){
          this.openModals[refId].instance.closeModal(data);
        } else{
          this.domService.removeComponentFromBody(parseInt(key));
        }
      }
    }
  }

  //used to close all open modals.
  closeAllModals(): void {
    document.body.style.overflow = 'initial';
    document.body.style.position = 'relative';
    if (this.openModals)
      for (const [key, value] of Object.entries(this.openModals)) {
        this.domService.removeComponentFromBody(parseInt(key));
      }
    this.openModals = {};
  }

  getModalContentInstance(refId: number): any {
    return !!this.openModals[refId]
      ? this.openModals[refId].instance.componentInstance.instance
      : null;
  }

  private setConfig(refId: number, config: IModalConfig): void {
    if (refId && config) {
      this.openModals[refId].instance.allowOverlayClick =
        'allowOverlayClick' in config
          ? !!config.allowOverlayClick
          : this.openModals[refId].instance.allowOverlayClick;

      this.openModals[refId].instance.showCloseButton =
        'showCloseButton' in config
          ? !!config.showCloseButton
          : this.openModals[refId].instance.showCloseButton;

      if ('whenClosed' in config) {
        this.openModals[refId].instance.whenOverlayClicked$.subscribe(
          (data) => {
            if (!!config.whenClosed) {
              config.whenClosed(data);
            }
          }
        );
      }
      if ('data' in config) {
        this.openModals[refId].instance.data = config.data;
      }
    }
    this.openModals[refId].instance.modalClose = refId;
  }

  showModal(content: Type<any>, config?: IModalConfig): number {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    const refId = this.domService.appendComponentToBody(OverlayComponent);
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlayComponent>;
    this.openModals[refId].instance.component = content;
    this.setConfig(refId, config);
    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }
  showSmallModal(content: Type<any>, config?: IModalConfig): number {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    const refId = this.domService.appendComponentToBody(OverlaySmallComponent);
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlaySmallComponent>;
    this.openModals[refId].instance.component = content;
    this.setConfig(refId, config);
    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }

  //Flash message popup for mobile
  showMobileFlash(content: Type<any>, config?: IModalConfig): number {
    const refId = this.domService.appendComponentToBody(OverlayFlashMobileComponent);
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlayFlashMobileComponent>;
    this.openModals[refId].instance.component = content;
    this.setConfig(refId, config);
    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }

  //Medium size modal . Used for auth,address,map popups
  showMediumModalDesktop(content: Type<any>, config?: IModalConfig): number {
    const refId = this.domService.appendComponentToBody(
      OverlayMediumDesktopComponent
    );
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlayMediumDesktopComponent>;
    this.openModals[refId].instance.component = content;
    this.setConfig(refId, config);
    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }

  //Large size modal . Used for modifier modal
  showLargeModalDesktop(content: Type<any>, config?: IModalConfig): number {
    const refId = this.domService.appendComponentToBody(
      OverlayLargeDesktopComponent
    );
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlayLargeDesktopComponent>;
    this.openModals[refId].instance.component = content;
    this.setConfig(refId, config);
    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }

  //Large size modal . Used for modifier modal
  showFuture(content: Type<any>, config?: IModalConfig): number {
    const refId = this.domService.appendComponentToBody(OverlayFutureComponent);
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlayFutureComponent>;
    this.openModals[refId].instance.component = content;
    if (config) {
      this.setConfig(refId, config);
    }

    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }

  //Small size modal . Used for small forms
  showSmallFormModal(content: Type<any>, config?: IModalConfig): number {
    document.body.style.overflow = 'hidden';
    document.body.style.position = 'fixed';
    const refId = this.domService.appendComponentToBody(
      OverlaySmallFormComponent
    );
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlaySmallFormComponent>;
    this.openModals[refId].instance.component = content;
    if (config) {
      this.setConfig(refId, config);
    }

    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }


   //Greyed Out modal . Used for small forms
   showGreyedOutModal(content: Type<any>, config?: IModalConfig): number {
    const refId = this.domService.appendComponentToBody(OverlayGreyedOutComponent);
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlayGreyedOutComponent>;
    this.openModals[refId].instance.component = content;
    if (config) {
      this.setConfig(refId, config);
    }

    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }
  //location Info Modal in mobile
  showLocationInfoModal(content: Type<any>, config?: IModalConfig): number {
    const refId = this.domService.appendComponentToBody(
      OverlayLocInfoComponent
    );
    this.openModals[refId] = this.domService.getComponent(
      refId
    ) as ComponentRef<OverlayLocInfoComponent>;
    this.openModals[refId].instance.component = content;
    this.setConfig(refId, config);
    this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
      this.closeModal(refId);
    });
    return refId;
  }

    //OverlayModifierComponent
    showModifierModal(content: Type<any>, config?: IModalConfig): number {
      const refId = this.domService.appendComponentToBody(
        OverlayModifierComponent
      );
      this.openModals[refId] = this.domService.getComponent(
        refId
      ) as ComponentRef<OverlayModifierComponent>;
      this.openModals[refId].instance.component = content;
      this.setConfig(refId, config);
      this.openModals[refId].instance.whenOverlayClicked$.subscribe((data) => {
        this.closeModal(refId);
      });
      return refId;
    }
  
}
