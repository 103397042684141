import {
  BaseComponent,
  ChangeCategory,
  MenuListState,
  CategoryList,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

export class HorizontalScrollableMenuBaseComponent extends BaseComponent {
  @Select(MenuListState.getMenuListCategory) selectedCategory$: Observable<
    CategoryList
  >;
  constructor(
    public store: Store,
    public router: Router,
    public route: ActivatedRoute
  ) {
    super();
  }

  selectCategory(category) {
    this.store.dispatch(new ChangeCategory(category));
    this.router.navigate([`../${category._id}`], { relativeTo: this.route });
  }
}
