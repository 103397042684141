// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ViewAllOrdersService {
  constructor(private http: HttpClient) {}

  fetchViewAllOrders(customerId,OrganizationId) {
    const url = `${environment.customerApiUrl}customers/${customerId}/organization/${OrganizationId}/orders`;
    return this.http.get(url);
  }

  
  fetchInprogressOrders(body) {
  const url = `${environment.customerApiUrl}customers/inprogress`;
    return this.http.post(url,body);
  }
}
