import {
  Component,
  OnInit,
  Output,
  Input,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { MessagingService } from '@moduurnv2/web/core';
import { ReviewPayBaseComponent } from '@moduurnv2/libs-orderingapp/src/features';
import { Store, Select } from '@ngxs/store';
import { Router } from '@angular/router';

import {
  SweetAlertService,
  WhitelabelState,
  VenueManagementService,
  Venue,
  VenueManagementState,
  DeliveryState,
  LocationState,
  SetLocationAccess,
  SharedService,
  LocationDefaultState
} from '@moduurnv2/libs-orderingapp/src/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
import { Location } from '@angular/common';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';
import { takeUntil } from 'rxjs/operators';
import { AlertPopupComponent } from '@moduurnv2/web/features/ui/components/alert-popup/alert-popup.component';
@Component({
  selector: 'wj-review',
  templateUrl: './wj-review.component.html',
  styleUrls: ['./wj-review.component.scss'],
})
export class WJReviewComponent extends ReviewPayBaseComponent {
  @ViewChild('specialInstruction') specialInstructionFocus: ElementRef;
  @Select(DeliveryState.getSelectedDelivery) selectedDelivery$: Observable<any>;
  @Select(VenueManagementState.getLocationAccessEnabled)
  locationEnabled$: Observable<any>;
  @Select(VenueManagementState.getSelectedLoungeTable) loungeTable$: Observable<
  Venue
>;
  @Select(LocationState.getSelectedLocation) selectedLocation$: Observable<any>;
  themeOptions: ThemeOptions = {
    appPrimaryColor: true,
    appFontFamily: true,
  };
  themeLabel: ThemeOptions = {
    appFontFamily: true,
  };
  isError: boolean = false;
  clearValue: boolean = false;
  selectedTable: Venue;
  deliveryType;
  locationEnabled;
  deliverySubscription;
  locationSubscription;
  constructor(
    public store: Store,
    public router: Router,
    public sweetAlert: SweetAlertService,
    public messagingService: MessagingService,
    public spinner: NgxSpinnerService,
    public shared: SharedService,
    public translate: TranslateService,
    public _location: Location,
    venueManagementService: VenueManagementService,
    modal: ModalService
  ) {
    super(
      store,
      router,
      sweetAlert,
      spinner,
      shared,
      translate,
      venueManagementService,
      modal,
      _location
    );
    this.selectedTable = this.store.selectSnapshot(
      VenueManagementState.getSelectedLoungeTable
    );
    if(!this.selectedTable){
      this.getTableData()
    }
    console.log('Table', this.selectedTable);
    this.deliverySubscription = this.selectedDelivery$.subscribe((delivery) => {
      if (delivery) {
        this.deliveryType = delivery.textCode;
      }
    });

    this.locationSubscription = this.locationEnabled$.subscribe((persons) => {
      if (persons) {
        this.locationEnabled = true;
      } else {
        this.locationEnabled = false;
      }
    });
    if(this.isSpecialInstructionPresent){
      this.clearValue = true;
    }
  }

  getTableData() {
    this.loungeTable$
      .pipe(takeUntil(this.destroy$))
      .subscribe((loungeTable) => {
        if (loungeTable) {
          this.selectedTable = loungeTable;
        }
      });
  }

  saveSpecialAllergyInstruction(val) {
    // if (val.trim().length > 0) {
    //   console.log('entered');
      this.saveSpecialInstruction(val);
      this.isError = false;
    // } else {
    //   this.specialInstructionFocus.nativeElement.focus();
    //   this.isError = true;
    // }
  }
  checkValue(event) {
    if (event.target.value.trim().length > 0) {
      this.clearValue = true;
    } else {
      this.clearValue = false;
    }
  }

  async checkAndPlaceOrder() {
    let vm = this;
    let otherConfigs = this.store.selectSnapshot(WhitelabelState.getOther);
    let bypassCustomerDetailsEntry = otherConfigs.bypassCustomerDetailsEntry
      ? otherConfigs.bypassCustomerDetailsEntry
      : false;
    if (bypassCustomerDetailsEntry) {
      vm.placeOrder();
    }
  }

  showLocationAccess() {
    const locationDetails = this.store.selectSnapshot(LocationState.getSelectedLocation);
    const selLoc =  this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (selLoc?.locationData?.orderSettings?.geoFencing?.isAllow) {
      let tableNumber;
      const venue = this.store.selectSnapshot(
        VenueManagementState.getSelectedLoungeTable
      );
      if (venue && venue.name) tableNumber = venue.name;
      const isAccessed = this.store.selectSnapshot(
        VenueManagementState.getLocationAccessEnabled
      );
      console.log('isAccessed', isAccessed);

      if (window.navigator && window.navigator.geolocation) {
        console.log(window.navigator.geolocation);
        // window.navigator.geolocation.getCurrentPosition(
        //   (position) => {
        //    console.log(position)
        //   },
        //   (error) => {
        //     console.log(error)
        //   }
        //   )
      } else {
        console.log('no access');
      }

      if (this.deliveryType == 'DELIVERYTYPE08' && tableNumber) {
        // return this.checkLocPermission().then(res=>{
        if (this.locationEnabled) {
          return true;
        } else {
          const locationAccessContent:string = locationDetails.name + ' would like to access your location. This website will use your precise location to access lounge services.';
          this.modal.showSmallModal(AlertPopupComponent, {
            data: {
              header: this.translate.instant('Location Services'),
              content: this.translate.instant(
                locationAccessContent.toString()
              ),
              actionable: true,
              buttonText: 'Continue',
            },
            whenClosed: (data) => {
              if (data) {
                const selectedLocation = this.store.selectSnapshot(
                  LocationState.getSelectedLocation
                );
                const isAccessed = this.store.selectSnapshot(
                  VenueManagementState.getLocationAccessEnabled
                );
                if (isAccessed) {
                  this.router.navigate(
                    [`locations/${selectedLocation._id}/home`],
                    { queryParams: { tableNumber: tableNumber } }
                  );
                } else {
                  if (window.navigator && window.navigator.geolocation) {
                    window.navigator.geolocation.getCurrentPosition(
                      (position) => {
                        if (position && position.coords) {
                          this.store.dispatch(new SetLocationAccess(true));
                          return true;
                          // this.router.navigate(
                          //   [`locations/${selectedLocation._id}/home`],
                          //   { queryParams: { tableNumber: tableNumber } }
                          // );
                        }
                      },
                      (error) => {
                        this.store.dispatch(new SetLocationAccess(false));
                        return false;
                      }
                    );
                  }
                }
              }
            },
          });
          return false;
        }
        //  })
      } else return true;
    } else if (selLoc?.locationData?.orderSettings) {
      return true;
    }
  }
  ngOnDestroy() {
    this.locationSubscription.unsubscribe();
    this.deliverySubscription.unsubscribe();
  }

  checkLocPermission() {
    return new Promise<boolean>(async (resolve, reject) => {
      let PermissionStatus = await navigator.permissions.query({
        name: 'geolocation',
      });
      if (PermissionStatus['state'] == 'granted') {
        resolve(true);
      } else {
        resolve(false);
      }
    });
  }
  get grandTotal() {
    if (this.cartPrice.tip) return this.cartPrice.price + this.cartPrice.tip;
    return this.cartPrice.price;
  }
}
