// angular
import { Injectable } from '@angular/core';
import { HttpClient,HttpHeaders } from '@angular/common/http';

//rxjs
import { Observable, Subscription} from 'rxjs';

// app
import { environment } from '../../core/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class OrderReceiptService {
  constructor(
    private http: HttpClient
  ) { }

  getOrderReceiptDetails(orderId:string):Observable<any>{
    const url = `${environment.locationApiUrl}orders/${orderId}/receiptDetails`;
    return this.http.get(url);
  }



  fetchReciept(dataForReceipt){
    const url = `${environment.customerApiUrl}customers/${dataForReceipt.order._id}/print`;
    return this.http.post(url, dataForReceipt, { headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      }), responseType: 'text' })
}
}
