import {
  BaseComponent,
  environment,
  LocationState,
  DeliveryState,
  Delivery,
  AddressState,
  Address,
  VenueManagementState,Venue
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';


export class VenueUpdateSelectorBaseComponent extends BaseComponent
  implements OnInit {
  viewUpdateVenueModalStatus: boolean = false;
  addressBookStatus: boolean = false;
  selectedLocation: any;
  selectedVenueHeading: string;
  selectedVenue: Venue;
  loungeName: string;
  selectedDelivery
  deliveryLocation: any;
  noSelectionMsg:string ='';
  @Select(AddressState.getDeliveryAddress) DeliveryAddress$: Observable<
    Address
  >;
  @Select(VenueManagementState.getSelectedPickUpLocation) selectedPickUpLocation$: Observable<
    Venue
  >;
  @Select(VenueManagementState.getSelectedLoungeTable) selectedLoungeTable$;
  @Select(VenueManagementState.getSelectedRoom) selectedRoom$: Observable<
  Venue
  >;
  // @Select(VenueManagementState.getSelectedTakeOut) selectedTakeOut$: Observable<
  // Venue
  // >;
  deliveryAddress: String;
  constructor(public store: Store, public modal: ModalService) {
    super();
    this.loungeName = this.store.selectSnapshot(DeliveryState.getDeliveryLocation);
  }

  ngOnInit() {
    this.selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
      );
    }
    
  ngDoCheck(){
    this.setInitialData();
  }

  setInitialData(){
    this.selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    this.setVenue(this.selectedDelivery.textCode);
  }

  setVenue(selectedDelivery){
    this.selectedVenue = {name:''};
    switch (selectedDelivery) {
      case 'DELIVERYTYPE08':
        this.noSelectionMsg = 'Select a table';
        this.selectedLoungeTable$
        .pipe(takeUntil(this.destroy$))
        .subscribe((venue) => {
          if (venue) {
            this.selectedVenue = venue;
            this.selectedVenueHeading = 'ORDERING AT';
          }
        });
        break;
      case 'DELIVERYTYPE09':
        this.noSelectionMsg = 'Select a pickup station';
        this.selectedPickUpLocation$
        .pipe(takeUntil(this.destroy$))
        .subscribe((venue) => {
          if (venue) {
            this.selectedVenue = venue;
            this.selectedVenueHeading = 'Pick up From';
          }
        });
        break;
      case 'DELIVERYTYPE10':
        this.noSelectionMsg = 'Select a room for delivery';
        this.selectedRoom$
        .pipe(takeUntil(this.destroy$))
        .subscribe((venue) => {
          if (venue) {
            this.selectedVenue = venue;
            this.selectedVenueHeading = 'Delivering To';
          }
        });
        break;
      default:
        break;
    }
  }
}
