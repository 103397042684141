import {
  BaseComponent,
  OrderState,
  LocationDefaultState,
  LocationDefaults,
  FetchLocationDefaults,
  PaymentMethodState,
  WhitelabelState,
  Whitelabel,
  environment,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import {
  // Cart,
  // CartState,
  LocationState,
  Location,
  SetSelectedOrderId,
  // ClearCart,
  SetInprogressOrderIds,
  SelectedOrderState,
  AuthState,
  ClearOrderResponseMessage,
  SharedService,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import * as moment from 'moment';

export class OrderSuccessBaseComponent extends BaseComponent {
  bannerImg = 'assets/images/Berries.png';
  successImg = 'assets/images/Success-Icon-Big.svg';
  successImgThumbs = 'assets/images/Success Icon Big Thumbs.svg';
  infoIcon = 'assets/images/Info-Icon.svg';
  closeIcon = 'assets/images/Close-white.svg';
  receiptIcon = 'assets/images/receipt-icon-small.svg';
  rateIcon = 'assets/images/Star.svg';
  headerText = 'order-successful';

  estimatedTime: number;

  // @Select(CartState.getCart) cart$: Observable<Cart>;
  @Select(OrderState.getOrderDetails) orderDetails$: Observable<any>;
  @Select(LocationState.getSelectedLocation) location$: Observable<Location>;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  @Select(PaymentMethodState.getSelectedPaymentType) paymentType$: Observable<
    string
  >;
  @Select(WhitelabelState.getWhitelabel) whiteLabel$: Observable<Whitelabel>;
  // cart: Cart;
  orderDetails: any;
  successScreen: any;
  overRide: boolean;
  allowFeedback: boolean;
  feedBackUrl: string;
  location: Location;
  locationDefaults: LocationDefaults;
  paymentType: string;
  orderSettings;
  hideReceipt:boolean;
  hideOrder:boolean;
  constructor(
    public router: Router,
    public store: Store,
    public modal: ModalService,
    public shared: SharedService
  ) {
    super();
    this.orderDetails$
      .pipe(takeUntil(this.destroy$))
      .subscribe((orderDetails) => {
        this.orderDetails = orderDetails;
      });
    this.whiteLabel$ .pipe(takeUntil(this.destroy$))
      .subscribe((whiteLabel) => {
        this.successScreen = whiteLabel.successScreen;
        if(this.successScreen && this.successScreen.isOverride){
          this.successImg = environment.imagebaseurl+this.successScreen.icon;
          this.bannerImg = environment.imagebaseurl+this.successScreen.headerBanner;
          this.headerText = this.successScreen.heading;
          this.overRide = this.successScreen.isOverride;
          if(this.successScreen.feedbackLink){
            this.allowFeedback = this.successScreen.feedbackLink.isAllow;
            this.feedBackUrl = this.successScreen.feedbackLink.url;
          }
        }
        if(whiteLabel.commonSettings){
          this.hideReceipt = whiteLabel.commonSettings.hideReceipt;
          this.hideOrder = whiteLabel.commonSettings.hideOrderRelatedScreens;
        }
      });
    // this.cart$.pipe(takeUntil(this.destroy$)).subscribe((cart) => {
    //   if (cart) {
    //     this.cart = cart;
    //   }
    // });
    this.location$.pipe(takeUntil(this.destroy$)).subscribe((location) => {
      if (location) {
        this.location = location;
      }
    });
    this.locationDefaults$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationDefaults) => {
        if (locationDefaults) {
          this.locationDefaults = locationDefaults;
          this.orderSettings = locationDefaults.locationData.orderSettings;
        }
      });
    this.paymentType$
      .pipe(takeUntil(this.destroy$))
      .subscribe((paymentType) => {
        if (paymentType) {
          this.paymentType = paymentType;
        }
      });
    this.store.dispatch(new FetchLocationDefaults());
    this.setDataForOrderInprogress();
    this.getOrderDetails();
  }

  //Changes for MV2-166
  // Use Guards instead of this
  ngAfterContentChecked() {
    // if(!this.cart){
    //   this.router.navigate([`/locations/${this.location._id}/home`]);
    // }
  }

  getOrderDetails() {
    let orderSettings = this.locationDefaults.locationData.orderSettings;
    if (!this.orderDetails.futureOrder) {
      this.orderDetails.deliveryTime = orderSettings.maxPrepTime;
    }
  }

  close() {
    this.router.navigate([`/locations/${this.location._id}/menu`]);
  }

  viewStatus() {
    const { order_id } = this.store.selectSnapshot(
      OrderState.getOrderResponse
    ) || { order_id: undefined };
    if (order_id) {
      this.store.dispatch(new SetSelectedOrderId(order_id));
      this.store.dispatch(new ClearOrderResponseMessage());

      this.router.navigate([`/locations/${this.location._id}/order-details`]);
    } else {
      let selectedOrderIdToView = this.store.selectSnapshot(
        SelectedOrderState.getSelectedOrderId
      ) || { selectedOrderIdToView: undefined };
      if (selectedOrderIdToView) {
        this.store.dispatch(
          new SetSelectedOrderId(selectedOrderIdToView.toString())
        );
        this.router.navigate([`/locations/${this.location._id}/order-details`]);
      }
    }
  }

  startOrder(){
    if(this.location._id){
      this.router.navigate([`/locations/${this.location._id}/menu`]);
    }
  }

  viewReceipt() {
    const { order_id } = this.store.selectSnapshot(
      OrderState.getOrderResponse
    ) || { order_id: undefined };
    if (order_id) {
      this.store.dispatch(new SetSelectedOrderId(order_id));
      this.store.dispatch(new ClearOrderResponseMessage());
      // this.router.navigate([`/order-receipt`]);
    } else {
      const selectedOrderIdToView = this.store.selectSnapshot(
        SelectedOrderState.getSelectedOrderId
      ) || { selectedOrderIdToView: undefined };
      if (selectedOrderIdToView) {
        this.store.dispatch(
          new SetSelectedOrderId(selectedOrderIdToView.toString())
        );
      }
    }
  }

  setDataForOrderInprogress() {
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);
    if (!customer || customer.isLoggedIn == false) {
      // const { order_id } = this.store.selectSnapshot(
      //   OrderState.getOrderResponse
      // ) || { order_id: undefined };
      const selectedOrderIdToView = this.store.selectSnapshot(
        SelectedOrderState.getSelectedOrderId
      ) || { selectedOrderIdToView: undefined };
      const ids = this.store.selectSnapshot(
        SelectedOrderState.getInprogressOrderIds
      );
      let orderIds = [];
      if (ids && ids.length > 0 && selectedOrderIdToView) {
        orderIds = ids;
        orderIds.push(selectedOrderIdToView);
      } else if (selectedOrderIdToView) {
        orderIds = [selectedOrderIdToView];
      }
      this.store.dispatch(new SetInprogressOrderIds(orderIds));
    }
  }

  formatTime(futureDate) {
    const futureTime = moment(futureDate);
    let timeText = futureTime.format('MMM DD hh:mm a');
    timeText += ` - ${futureTime.add({ minutes: 30 }).format('hh:mm a')}`;
    return timeText;
  }
}
