import { Component, OnInit } from '@angular/core';
import { Router,ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import {MenuBaseComponent} from '@moduurnv2/libs-orderingapp';

@Component({
  selector: 'wj-footer',
  templateUrl: './wj-footer.component.html',
  styleUrls: ['./wj-footer.component.scss'],
})
export class WJFooterComponent extends MenuBaseComponent implements OnInit {
  constructor(router: Router,store: Store,route:ActivatedRoute) {
    super(router,store,route);  }

}
