import { Component, Input, OnInit } from '@angular/core';
import { IconListBaseComponent } from '@moduurnv2/libs-orderingapp';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';

@Component({
  selector: 'wj-icon-list',
  templateUrl: 'wj-icon-list.component.html',
  styleUrls: ['wj-icon-list.component.scss'],
})
export class WJIconListComponent extends IconListBaseComponent implements OnInit {
  @Input('icons') itemIcons: any[];
  @Input('showText') showText: boolean = false;
  options: ThemeOptions = {
    appFontFamily: true
}
  constructor() {
    super();
  }

  ngOnInit() {
    // this.itemIcons = this.itemIcons
    //   .map((iconId) => {
    //     let foundIcon: any = Icons.find((icon) => icon.id === iconId);
    //     if (foundIcon) return foundIcon;
    //   })
    //   .filter((icon) => icon !== undefined);
  }

}
