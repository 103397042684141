import {
  BaseComponent, Icons,
} from '@moduurnv2/libs-orderingapp/src/core';

export class IconListBaseComponent extends BaseComponent {
  
  constructor() {
    super();
  }

  getImageURL(iconId) {
    const founIcon = Icons.find((icon) => icon.id === iconId)
    return `assets/images/${founIcon.filename}.svg`;
  }

  getIconName(iconId) {
    const founIcon = Icons.find((icon) => icon.id === iconId)
    return founIcon.name;
  }

}
