import { Component, Inject } from '@angular/core';

import { Injectable } from '@angular/core';

import { Router, NavigationEnd, NavigationError } from '@angular/router';
// xplat
import { AppBaseComponent } from '@moduurnv2/web';
import { SharedService } from '@moduurnv2/libs-orderingapp';
import { Select ,Store} from '@ngxs/store';
import {
  WhitelabelState,
  Whitelabel,
  SetSelectedLocation,
  FetchLocationList,
  LocationState,
  Location,
  SetSelectedDelivery,
  FetchDeliveryList,
  DeliveryState,
  Delivery,
  SetSelectedTable,
  SetLocationAccess,
  ResetCustomerPerTable,
  ClearLoginDetails,
  ClearCart,
  ClearMenuItem,
  ClearMenuList
} from '@moduurnv2/libs-orderingapp/src/core';
import { Observable,Subscription } from 'rxjs';
import { MessagingService } from '@moduurnv2/web/core';
import * as rg4js from 'raygun4js';
import { AnalyticsHandler } from '@moduurnv2/core/services';
import { PWAService } from '@moduurnv2/core/services';

import { HttpClient } from '@angular/common/http';
import { environment } from '@moduurnv2/libs-orderingapp/src/core';
import * as CryptoJS from 'crypto-js';
import { Config } from './../config';
@Component({
  selector: 'wj-moduurnv2-root',
  templateUrl: './wj-app.component.html',
  styleUrls: ['./wj-app.component.scss'],
})
@Injectable({
  providedIn: PWAService,
})
export class WJAppComponent extends AppBaseComponent {
  @Select(WhitelabelState.getWhitelabel) whitelabel$: Observable<Whitelabel>;
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  @Select(DeliveryState.getDeliveryList) deliveryList$: Observable<Delivery[]>;
  isMobileView: boolean;
  locationListSubscription:Subscription;
  deliveryListSubscription:Subscription;
  initialLoad:boolean = false;
  cipher = Config.cipher;
  constructor(
    private router: Router,
    private http: HttpClient,
    private analyticsHandler: AnalyticsHandler,
    public messagingService: MessagingService,
    private shared: SharedService,
    private store:Store
  ) {
    super();
    this.whitelabel$.subscribe((response) => {
    });
    this.setupGoogleAnalytics();
    this.getInitialDataForLoungeDelivery();
    if(Config.appVersion)
    console.log(`You are using v ${Config.appVersion}`)
  }
  setupGoogleAnalytics() {
    this.analyticsHandler.setupGoogleAnalytics();
    //this.analyticsHand = new AnalyticsHandler(this.http).setupGoogleAnalytics()
  }
  ngOnInit() {
    this.isMobileView = this.shared.isMobileScreen();
    this.messagingService.reciveNotificationIfEnabled();
    // this.router.events.subscribe((evt) => {
    //   if (environment.production) {
    //     if (evt instanceof NavigationError) {
    //       //Track navigation error
    //       rg4js('send', {
    //         error: evt.error,
    //       });
    //     }
    //   }
    //   if (evt instanceof NavigationEnd) {
    //     // Track navigation end
    //     this.analyticsHandler.setPageRedirects(evt.urlAfterRedirects);
    //   }
    // });
  }

  ngOnDestroy(){
    if(this.locationListSubscription)
    this.locationListSubscription.unsubscribe();
    if(this.deliveryListSubscription)
    this.deliveryListSubscription.unsubscribe();
  }

  getInitialDataForLoungeDelivery() {
      this.initialLoad = true;
      const url = window.location.href;
      let vars = {};
      let queryParams = url.split("?")[1];
      if(queryParams){
        let queryParam = queryParams.split('&');
        let params;
        let decryptedData;
        let b64String;
        for (var i = 0; i < queryParam.length; i++) {
        params=queryParam[i].split("=");
        vars[params[0]] = params[1];
        b64String = decodeURIComponent(params[1]);
        if(vars['table']){
          var bytes  = CryptoJS.AES.decrypt(b64String, this.cipher);
          decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
        }
        }

        if(vars && vars['table']){
          let locationId = window.location.pathname.split('/')[2];
          this.store.dispatch(new FetchLocationList());
         this.locationListSubscription = this.locationsList$.subscribe((locations) => {
             if(locations){
               if(locations.length>0){
                this.store.dispatch(new ResetCustomerPerTable(''));
                this.store.dispatch(new ClearCart());
                this.store.dispatch(new ClearMenuItem());
                this.store.dispatch(new ClearMenuList());
                 let selLocation = locations.find(loc=> loc._id == locationId);
                 if(selLocation && selLocation['deliveryTypes']){
                  selLocation['deliveryTypes'].map((delivery) => {
                    if (delivery.textCode == 'DELIVERYTYPE08' && this.initialLoad) {
                      this.store.dispatch(new SetSelectedLocation(locationId));
                      this.store.dispatch(new ClearLoginDetails());
                      this.store.dispatch(new FetchDeliveryList());
                      this.store.dispatch(new SetLocationAccess(false));
                    this.deliveryListSubscription = this.deliveryList$.subscribe((deliveries)=>{
                        if(deliveries){
                          if (delivery.textCode == 'DELIVERYTYPE08' && this.initialLoad) {
                          this.store.dispatch(new SetSelectedDelivery(delivery.textCode));
                          this.initialLoad = false;
                          if(decryptedData){
                            if(typeof decryptedData === 'string')
                            decryptedData = JSON.parse(decryptedData);
                            let lounge = { name: '' };
                            lounge.name = decryptedData['tableNumber'];
                            this.store.dispatch(new SetSelectedTable(lounge));
                            this.router.navigate([`locations/${locationId}/home`],{ queryParams: { 'tableNumber':b64String }})
                          }
                          }
                        }
                      })
                    }
                  });
                 }
               }
             }
          });
        }
      }
  }
}
