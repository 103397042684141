// angular
import { Injectable } from '@angular/core';
// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { OrderResponse } from '../models/order.interface';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  constructor(private http: HttpClient) {}

  placeOrder(payload) {
    const url = `${environment.placeOrderApiUrl}placeOrderV3?isAPI=true`;
    // if (payload.order.deliveryAddress.state == 'British Columbia')
    //   payload.order.deliveryAddress.state = 'BC';
    return this.http.post<OrderResponse>(url, payload);
  }

  clearCurrentCart(locationId, cartId) {
    const payload = {
      locationId: locationId,
      giftCardAmount: 0,
    };
    const url = `${environment.cartApiUrl}${cartId}/clear`;
    return this.http.post(url, payload);
  }
}
