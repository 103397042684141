import { NgModule, Optional, SkipSelf } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { CommonModule } from '@angular/common';

// libs
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { throwIfAlreadyLoaded } from '@moduurnv2/utils';

//firebase
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { MessagingService } from './services/messaging-service';
import { webEnvironment } from './environments/environment';

import {
  PlatformLanguageToken,
  PlatformWindowToken,
} from '@moduurnv2/libs-orderingapp/src/core';
import { LibsOrderingappModule } from '@moduurnv2/libs-orderingapp/src';
import {
  SocialLoginModule,
  SocialAuthServiceConfig,
} from 'angularx-social-login';
import {
  GoogleLoginProvider,
  FacebookLoginProvider,
} from 'angularx-social-login';

// bring in custom web services here...
import { environment } from '@moduurnv2/libs-orderingapp/src/core';
import { AuthService } from './services/auth-service';
import { NgxsStoragePluginModule, StorageOption } from '@ngxs/storage-plugin';
// factories
export function winFactory() {
  return window;
}

export function platformLangFactory() {
  const browserLang = window.navigator.language || 'en'; // fallback English
  // browser language has 2 codes, ex: 'en-US'
  return browserLang.split('-')[0];
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, `./assets/i18n/`, '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    CommonModule,
    HttpClientModule,
    LibsOrderingappModule.forRoot([
      {
        provide: PlatformLanguageToken,
        useFactory: platformLangFactory,
      },
      {
        provide: PlatformWindowToken,
        useFactory: winFactory,
      },
    ]),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
    SocialLoginModule,
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(webEnvironment.firebase),
    NgxsStoragePluginModule.forRoot({
      key: [
        'whitelabel',
        'location',
        'delivery',
        'menuItem',
        'cart',
        'lastOrder',
        'auth',
        'authEmail',
        'paymentMethods',
        'locationDefaults',
        'selectedOrderIdToView',
        'order',
        'venuemanagement',
        'menulist'
      ],
      storage: StorageOption.LocalStorage,
    }),
  ],
  providers: [
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.googleWebOAuthClientId
            ),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    AuthService,
    MessagingService,
  ],
})
export class Moduurnv2CoreModule {
  constructor(
    @Optional()
    @SkipSelf()
    parentModule: Moduurnv2CoreModule
  ) {
    throwIfAlreadyLoaded(parentModule, 'Moduurnv2CoreModule');
  }
}
