import {
  BaseComponent,
  environment,
  WhitelabelState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { Store } from '@ngxs/store';
import { OrderDetailsService } from './../../../core/services/order-details.service';
import {
  LocationState,
  SelectedOrderState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';

interface Constant {
  text: string;
  textCode: string;
  textType: string;
}
interface Timeline {
  text: string;
  textCode: string;
  time: string;
  isCompleted: boolean;
}

interface DeliveryAddress {
  address: string;
}
interface Location {
  name: string;
  address: string;
  primaryContactPhoneNumber: string;
}

interface OrderDetails {
  _id: string;
  orderNumber: string;
  location: Location;
  timeline: Timeline[];
  deliveryMethod: Constant;
  delivery: DeliveryAddress;
}

export class OrderDetailsBaseComponent extends BaseComponent {
  hideReceipt:boolean;
  options:ThemeOptions={
    appFontFamily:true
  }
  constructor(
    public store: Store,
    private router: Router,
    private _orderDetailsService: OrderDetailsService
  ) {
    super();
  }

  imgBasePath: string = environment.image_basepath;
  icon = {
    receipts: this.imgBasePath + environment.images.receipt_icon,
    call: this.imgBasePath + environment.images.call_icon,
    viewAll: this.imgBasePath + environment.images.view_all_orders_icon,
    back: this.imgBasePath + environment.images.arrow_left_icon,
    backPng: this.imgBasePath + environment.images.arrow_left_icon_png,
  };
  orderDetails: OrderDetails;
  private _orderDetailsSubscription: Subscription;
  orderId: string;
  isMobileDevice: boolean = true;
  ngOnInit() {
    const order_id = this.store.selectSnapshot(
      SelectedOrderState.getSelectedOrderId
    );
    if (order_id) {
      this.orderId = order_id;
      this.getOrderDetails(this.orderId);
    }
    let whiteLabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    if(whiteLabel && whiteLabel.commonSettings){
      this.hideReceipt = whiteLabel.commonSettings.hideReceipt;
    }
  }
  ngOnDestroy() {
    if (this._orderDetailsSubscription)
      this._orderDetailsSubscription.unsubscribe();
  }

  getOrderDetails(orderId) {
    this._orderDetailsSubscription = this._orderDetailsService
      .getOrderDetails(orderId)
      .subscribe((res) => {
        if (res && res.data) {
          this.orderDetails = res.data;
        }
      });
  }
  goBack() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/home`]);
    }
  }

  viewReceipt() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.router.navigate([`/order-receipt`]);
    }
  }

  viewAllOrders() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/view-orders`]);
    } else {
      this.router.navigate([`/locations`]);
    }
  }
}
