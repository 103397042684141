// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Store } from '@ngxs/store';

@Injectable({
  providedIn: 'root',
})
export class MenuListService {
  constructor(private http: HttpClient, private store: Store) {}

  fetchMenuList() {
    const selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    const { _id: locationId } = this.store.selectSnapshot(
      (app) => app.location.selectedLocation
    );
    let url = `${environment.mobileStoreApiv3Url}stores/${locationId}/menu`;

    if (selectedDelivery && selectedDelivery.textCode)
      url += `?delivery=${selectedDelivery.textCode}`;

    let deliveryLocation = (localStorage.getItem('selectedAdrsLocation') && localStorage.getItem('selectedAdrsLocation') != "undefined")
      ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
      : undefined;
    if (selectedDelivery && selectedDelivery.textCode == 'DELIVERYTYPE01')
      url += `&lat=${deliveryLocation['loc'][1]}&lng=${deliveryLocation['loc'][0]}`;

    const futureTime = this.store.selectSnapshot(
      (app) => app.cart && app.cart.futureDate
    );
    if (futureTime) {
      const selectedTime = new Date(futureTime).toISOString();
      url += `&selectedTime=${selectedTime}`;
    }

    return this.http.get(url);
  }
}
