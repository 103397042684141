import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
@Injectable({
    providedIn: 'root'
})
export class GeoLocationService {
    watchId: number;
    locationTrackingActive = false;
    currentLocation: { latitude: number, longitude: number, altitude:number,full:any } = { latitude: undefined, longitude: undefined, altitude: undefined,full:undefined };
    private deviceLocation$: Subject<Object>;
 
    constructor() {
        this.deviceLocation$ = new BehaviorSubject<Object>(this.currentLocation);
    }
 
    getCurrentLocation(): Observable<Object> {
        // const opts = { enableHighAccuracy: true, maximumAge: 60000, timeout: 30000 };
        const opts = { enableHighAccuracy: true};
        navigator.geolocation.getCurrentPosition((position) => {
            this.locationTrackingActive = true;
            this.currentLocation = { latitude: position.coords.latitude, longitude: position.coords.longitude, altitude: position.coords.altitude ,full:position.coords};
            this.deviceLocation$.next(this.currentLocation);
        },
        (err) => {
            console.log(err);
            this.locationTrackingActive = false;
        },
        opts);
        return this.deviceLocation$;
    }
}
