import { CartCreation } from '../models';

export class FetchCart {
  static readonly type = '[CART] FetchCart';

  constructor() {}
}

export class SetSelectedCart {
  static readonly type = '[CART] SetCart';

  constructor(public payload: CartCreation) {}
}

export class AddItemToCart {
  static readonly type = '[CART] AddCart';

  constructor(public payload: CartCreation | any) {}
}

export class SetCartItems {
  static readonly type = '[CART] SetCartItems';

  constructor(public payload: { cartId; organizationId }) {}
}
export class UpdateCart {
  static readonly type = '[CART] UpdateCart';

  constructor(public payload: { count; menuItemId; cartId }) {}
}

export class UpdateCount {
  static readonly type = '[CART] UpdateCount';

  constructor(public payload: { count; menuItemId; cartId }) {}
}
export class SetCartTip {
  static readonly type = '[CART] SetCartTip';

  constructor(public payload: { tip: {} }) {}
}
export class ClearCart {
  static readonly type = '[CART] ClearCart';

  constructor(public payload?: string) {}
}

export class SetFutureDate {
  static readonly type = '[CART] SetFuture';

  constructor(public payload) {}
}

export class FetchPrepTime {
  static readonly type = '[ANY]FetchPrepTime';

  constructor(public payload?: string) {}
}

export class UpdateCartItem {
  static readonly type = '[CART] UpdateCartItem';

  constructor(public payload) {}
}
export class FetchCartMenuItems {
  static readonly type = '[CART] FetchCartMenuItems';

  constructor(public payload) {}
}
export class SetSpecialInstruction {
  static readonly type = '[CART] SetSpecialInstruction';

  constructor(public payload) {}
}

export class UpdateCartTotal {
  static readonly type = '[CART] UpdateCartTotal';

  constructor(public payload) {}
}
export class FetchETA {
  static readonly type = '[CART] FetchETA';

  constructor() {}
}

export class ClearFutureDate {
  static readonly type = '[CART] ClearFutureDate';

  constructor() {}
}
export class SetDefaultTipApplied {
  static readonly type = '[ANY] SetDefaultTipApplied';

  constructor(public payload?: boolean) {}
}
