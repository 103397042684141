import { State, Selector, StateContext, Action, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Category, PromotionalCategory, HomeModel } from '../models';
import { Injectable } from '@angular/core';
import { HomeService } from '../services';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';
import { FetchHomeData } from '../actions/home.actions';
import { LocationState } from './location.state';
import { DeliveryState } from './delivery-methods.state';

export class HomeStateModel {
  category: Category;
  promotionalCategory: PromotionalCategory;
}

@State<HomeStateModel>({
  name: 'home',
  defaults: {
    category: { _id: '', name: '', description: '', items: undefined, translation:'' },
    promotionalCategory: { _id: '', name: '', items: undefined },
  },
})
@Injectable()
export class HomeState {
  constructor(private _home: HomeService, private store: Store) {}

  @Selector()
  static getHomeData(state: HomeStateModel) {
    return state;
  }

  @Selector()
  static getHomeCategories(state: HomeStateModel) {
    return state.category;
  }

  @Selector()
  static getHomePromotionalCategory(state: HomeStateModel) {
    return state.promotionalCategory;
  }

  @Action(FetchHomeData)
  fetchHomeData({ setState }: StateContext<HomeStateModel>) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let selectedLocId;
    let selectedDeliveryCode;

    const selectedDelivery = this.store.selectSnapshot(
      DeliveryState.getSelectedDelivery
    );
    if (selectedDelivery && selectedDelivery.textCode)
      selectedDeliveryCode = selectedDelivery.textCode;
    else if (
      selectedLocation &&
      selectedLocation['deliveryTypes'] &&
      selectedLocation['deliveryTypes'].length
    )
      selectedDeliveryCode = selectedLocation['deliveryTypes'][0].textCode;

    if (selectedLocation && selectedLocation._id) {
      selectedLocId = selectedLocation._id;
    }
    return this._home.fetchHomeData(selectedLocId, selectedDeliveryCode).pipe(
      tap((response) => {
        if (response && response.data) setState(patch(response.data));
        else throw response;
      }),
      catchError((error) => {
        return Observable.throw(error);
      })
    );
  }
}
