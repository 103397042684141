import { State, Action, StateContext, Store, Selector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import {
  FetchSelectedMenuItem,
  SetPortion,
  SetSubModifierItem,
  RemoveModifierItem,
  SetModifierItem,
  SubmitModifierItem,
  UpdateItemCount,
  SetItemDetails,
  AddItemToCart,
  UpdateCartItem,
  RemoveSubModifierItem,
  ClearMenuItem
} from '../actions';
import { MenuItemService } from '../services';
import { tap } from 'rxjs/operators';

export class MenuItemStateModel {
  fetchedMenuItem: any;
  selectedMenuItem: any;
  submitted: boolean;
  count: number;
  itemDetails: any;
  cartDetails: any;
}

@State<MenuItemStateModel>({
  name: 'menuItem',
  defaults: {
    fetchedMenuItem: null,
    selectedMenuItem: null,
    submitted: false,
    count: 1,
    itemDetails: null,
    cartDetails: null,
  },
})
@Injectable()
export class MenuItemState {
  constructor(private _menuItem: MenuItemService, private store: Store) {}

  @Selector()
  static getSelectedMenuItem(state: MenuItemStateModel) {
    return state.selectedMenuItem;
  }

  @Selector()
  static getGeneratedPortion(state: MenuItemStateModel) {
    return state.selectedMenuItem.portion;
  }

  @Selector()
  static getGeneratedModifiers(state: MenuItemStateModel) {
    return state.selectedMenuItem.modifiers;
  }

  @Selector()
  static getGeneratedCount(state: MenuItemStateModel) {
    return state.count;
  }

  @Selector()
  static getItemDetails(state: MenuItemStateModel) {
    return state.itemDetails;
  }

  @Selector()
  static getSubmitted(state: MenuItemStateModel) {
    return state.submitted;
  }

  @Selector()
  static getCartDetails(state: MenuItemStateModel) {
    return state.cartDetails;
  }

  @Selector()
  static getMenuItemPrice(state: MenuItemStateModel) {
    const { selectedMenuItem, count } = state;
    const { price, modifiers, portions, organizationId } = selectedMenuItem;
    let basePrice = price || 0;

    const foundSelectedPortion =
      portions.find((portion) => portion.selected) || portions[0];
    if (foundSelectedPortion)
      if (organizationId.other.isRelative) {
        basePrice += foundSelectedPortion.price;
      } else {
        basePrice = foundSelectedPortion.price;
      }
    const totalModifierPrice = modifiers.reduce((modifiersTotal, modifier) => {
      if (modifier && modifier.selected) {
        const { modifierItems } = modifier;
        if (modifierItems && modifierItems.length > 0) {
          const modifierTotal = modifierItems.reduce(
            (modifierItemTotal, modifierItem) => {
              if (modifierItem && modifierItem.selected) {
                const { items, costDifference } = modifierItem;

                if (items && items.length > 0) {
                  const itemsPrice = items.reduce((itemsTotal, item) => {
                    if (item.selected) return itemsTotal + item.costDifference;
                    else return itemsTotal;
                  }, 0);

                  return modifierItemTotal + itemsPrice;
                } else return modifierItemTotal + costDifference;
              } else {
                return modifierItemTotal;
              }
            },
            0
          );
          return modifiersTotal + modifierTotal;
        }
      } else {
        return modifiersTotal;
      }
    }, 0);
    basePrice += totalModifierPrice;
    if (count) basePrice = basePrice * count;
    return basePrice;
  }

  @Action(FetchSelectedMenuItem)
  fetchMainMenu(
    { patchState, getState }: StateContext<MenuItemStateModel>,
    { payload }: FetchSelectedMenuItem
  ) {
    const { cartDetails } = getState();
    patchState({
      selectedMenuItem: null,
      count: 1,
    });
    return this._menuItem.fetchMenuItemDetails(payload).pipe(
      tap((response) => {
        let menuItemDetails = response;
        const fetchedMenuItem = clone(menuItemDetails);
        if (
          menuItemDetails &&
          menuItemDetails.portions &&
          menuItemDetails.portions.length > 0
        ) {
          let findDefault;
          menuItemDetails.portions.forEach((portion) => {
            portion.selected = false;
            if (portion.isDefault === true) {
              findDefault = portion;
            }
          });
          let selectedPortionId;
          if(cartDetails && cartDetails.portion){
            selectedPortionId = cartDetails.portion._id;
          } else if (findDefault) {
            findDefault.selected = true;
            selectedPortionId = findDefault._id;
          } else {
            menuItemDetails.portions[0].selected = true;
            selectedPortionId = menuItemDetails.portions[0]._id;
          }
          menuItemDetails.modifiers = filterPortion(menuItemDetails, selectedPortionId);
        }
        if (cartDetails)
          menuItemDetails = selectCartItems(menuItemDetails, cartDetails);
        const validateDetails = checkValidations(menuItemDetails);
        const patchData = {
          selectedMenuItem: validateDetails,
          fetchedMenuItem: fetchedMenuItem,
          submitted: false,
        };

        if (cartDetails) patchData['count'] = (cartDetails as any).count;
        if (menuItemDetails) patchState(patchData);
      })
    );
  }

  @Action(SetPortion)
  setGeneratedPortion(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { payload }: SetPortion
  ) {
    const { selectedMenuItem, fetchedMenuItem } = getState();
    selectedMenuItem.portions = selectedMenuItem.portions.map((portion) => {
      portion.selected = portion._id === payload;
      return portion;
    });
    const tempCopy = clone(fetchedMenuItem);
    Object.freeze(tempCopy);
    const filteredModifiers = filterPortion(tempCopy, payload);
    selectedMenuItem.modifiers = filteredModifiers;
    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(SetModifierItem)
  setGeneratedSubModifier(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { id, payload }: SetModifierItem
  ) {
    const { selectedMenuItem } = getState();
    const foundModifier = selectedMenuItem.modifiers.find(
      (modifier) => modifier._id === id
    );
    if (foundModifier) {
      const { modifierItems, maxLimit } = foundModifier;

      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload
        );

        if (foundModifierItem) {
          const selectedItems = modifierItems.filter(
            (modifierItem) => modifierItem.selected
          );

          if (
            (maxLimit > 0 && maxLimit > selectedItems.length) ||
            maxLimit === 0
          ) {
            foundModifierItem.selected = true;
            foundModifier.selected = true;
          } else if (maxLimit === 1) {
            foundModifier.modifierItems = foundModifier.modifierItems.map(
              (modifierItem) => {
                modifierItem.selected = false;
                modifierItem.items = modifierItem.items.map((item) => {
                  item.selected = false;
                  return item;
                });
                return modifierItem;
              }
            );
            foundModifierItem.selected = true;
            foundModifier.selected = true;
          } else {
            foundModifierItem.selected = false;
            const selectedSubItems = modifierItems.filter(
              (modifierItem) => modifierItem.selected
            );
            if (selectedSubItems.length === 0) foundModifier.selected = false;
          }
        }
      }
    }

    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(RemoveModifierItem)
  removeGeneratedSubModifier(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { id, payload }: RemoveModifierItem
  ) {
    const { selectedMenuItem } = getState();
    const foundModifier = selectedMenuItem.modifiers.find(
      (modifier) => modifier._id === id
    );
    if (foundModifier) {
      const { modifierItems } = foundModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === payload
        );
        if (foundModifierItem) {
          foundModifierItem.selected = false;
          const selectedModifierItems = foundModifier.modifierItems.filter(
            (modifierItem) => modifierItem.selected === true
          );
          if (selectedModifierItems.length === 0)
            foundModifier.selected = false;
        }
      }
    }
    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(SetSubModifierItem)
  setGeneratedSubModifierItem(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { id, subId, payload }: SetSubModifierItem
  ) {
    const { selectedMenuItem } = getState();
    const foundModifier = selectedMenuItem.modifiers.find(
      (modifier) => modifier._id === id
    );
    if (foundModifier) {
      let { modifierItems, maxLimit: modifierMaxLimit } = foundModifier;
      const selectedModifierItems = modifierItems.filter(
        (modifierItem) => modifierItem.selected
      );
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = foundModifier.modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        if (foundModifierItem) {
          let { items, maxLimit } = foundModifierItem;
          const foundSubModifierItem = items.find(
            (subModifier) => subModifier._id === payload
          );
          const selectedItems = items.filter(
            (subModifier) => subModifier.selected
          );
          if (foundSubModifierItem) {
            if (
              (maxLimit && maxLimit > selectedItems.length) ||
              maxLimit === 0
            ) {
              if (maxLimit === 1) {
                items = items.map((item) => {
                  item.selected = false;
                  return item;
                });
              }
              if (foundModifier.maxLimit === 1)
                modifierItems = modifierItems.map((modifierItem) => {
                  modifierItem.selected = false;
                  return modifierItem;
                });
              foundSubModifierItem.selected = true;
              foundModifierItem.selected = true;
              foundModifier.selected = true;
            } else if (maxLimit === 1) {
              modifierItems = modifierItems.map((modifierItem) => {
                modifierItem.selected = false;
                modifierItem.items = modifierItem.items.map((item) => {
                  item.selected = false;
                  return item;
                });
                return modifierItem;
              });
              foundSubModifierItem.selected = true;
              foundModifierItem.selected = true;
              foundModifier.selected = true;
            } else {
              foundSubModifierItem.selected = false;
              foundModifierItem.selected = false;
              foundModifier.selected = false;
            }
          }
        }
      }
    }
    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(RemoveSubModifierItem)
  removeGeneratedSubModifierItem(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { id, subId, payload }: RemoveSubModifierItem
  ) {
    const { selectedMenuItem } = getState();
    const foundModifier = selectedMenuItem.modifiers.find(
      (modifier) => modifier._id === id
    );
    if (foundModifier) {
      const { modifierItems } = foundModifier;
      if (modifierItems && modifierItems.length > 0) {
        const foundModifierItem = modifierItems.find(
          (modifierItem) => modifierItem._id === subId
        );
        if (foundModifierItem) {
          const { items } = foundModifierItem;
          const foundSubModifierItem = items.find(
            (item) => item._id === payload
          );
          if (foundSubModifierItem) {
            foundSubModifierItem.selected = false;
            const selectedSubmodifierItems = items.filter(
              (item) => item.selected
            );
            if (selectedSubmodifierItems.length === 0) {
              foundModifierItem.selected = false;
              const selectedModifierItems = foundModifier.modifierItems.filter(
                (modifierItem) => modifierItem.selected === true
              );
              if (selectedModifierItems.length === 0)
                foundModifier.selected = false;
            }
          }
        }
      }
    }
    patchState({
      selectedMenuItem: checkValidations(selectedMenuItem),
    });
  }

  @Action(SubmitModifierItem)
  submitMenuItem({ getState, patchState }: StateContext<MenuItemStateModel>) {
    const { selectedMenuItem, count, cartDetails } = getState();
    if (selectedMenuItem.valid === true) {
      const foundPortion = selectedMenuItem.portions.find(
        (portion) => portion.selected
      );
      let cartModifiers = selectedMenuItem.modifiers.map((modifier) => {
        if (modifier && modifier.selected) {
          const formattedModifier = {
            cartModifier: modifier._id,
            cartModifierItems: [],
          };
          if (modifier.modifierItems.length > 0) {
            formattedModifier.cartModifierItems = modifier.modifierItems.map(
              (modifierItem) => {
                if (modifierItem && modifierItem.selected) {
                  const formattedModifierItem = {
                    cartModifierItem: modifierItem._id,
                    type: modifierItem.type,
                    cartModifierSubItems: [],
                  };
                  if (modifierItem.items.length > 0) {
                    formattedModifierItem.cartModifierSubItems = modifierItem.items.map(
                      (item) => {
                        if (item && item.selected)
                          return {
                            cartModifierSubItem: item._id,
                          };
                      }
                    );
                    formattedModifierItem.cartModifierSubItems = formattedModifierItem.cartModifierSubItems.filter(
                      (item) => item != null
                    );
                  }

                  return formattedModifierItem;
                }
              }
            );
          }
          formattedModifier.cartModifierItems = formattedModifier.cartModifierItems.filter(
            (cartModifierItem) => cartModifierItem != null
          );
          return formattedModifier;
        }
      });
      cartModifiers = cartModifiers.filter(
        (cartModifier) => cartModifier != null
      );
      const formattedMenuItem: any = {
        count: count,
        menuItem: selectedMenuItem._id,
        portion: null,
        cartModifiers: [],
      };
      if (foundPortion) formattedMenuItem.portion = foundPortion._id;
      if (cartModifiers) formattedMenuItem.cartModifiers = cartModifiers;

      const formattedMenuItems = [formattedMenuItem];

      if (cartDetails) {
        const { cartDetails } = getState() as any;
        formattedMenuItem._id = cartDetails._id;
        this.store.dispatch(new UpdateCartItem(formattedMenuItem));
      } else this.store.dispatch(new AddItemToCart(formattedMenuItems));
    }

    patchState({
      submitted: true,
    });
  }

  @Action(UpdateItemCount)
  updateCount(
    { getState, patchState }: StateContext<MenuItemStateModel>,
    { payload }: UpdateItemCount
  ) {
    let { count } = getState();
    count += payload;
    if (count > 0)
      patchState({
        count: count,
      });
  }

  @Action(SetItemDetails)
  setItemDetails(
    { patchState }: StateContext<MenuItemStateModel>,
    { payload }: SetItemDetails
  ) {
    let { cartDetails, ...itemDetails } = payload;
    patchState({
      itemDetails: itemDetails,
      cartDetails: cartDetails,
    });
  }

  @Action(ClearMenuItem)
  clearMenuItem(
    { patchState }: StateContext<MenuItemStateModel>
  ) {
    patchState({
      fetchedMenuItem: null,
      selectedMenuItem: null,
      submitted: false,
      count: 1,
      itemDetails: null,
      cartDetails: null,
    });
  }
}

const clone = (data) => {
  return JSON.parse(JSON.stringify(data));
};

const checkValidations = (menuItemDetails) => {
  const menuItem = menuItemDetails;
  // Check if all the modifiers are valid

  const modifiersValid = menuItem.modifiers.reduce(
    (modifierValid, modifier) => {
      const { minLimit, maxLimit, modifierItems } = modifier;
      let modifierValidFlag = true;
      const selectedModifierItems = modifierItems.filter(
        (modifierItem) => modifierItem.selected
      );
      let modifierItemsValid = true;
      let modifierRequiredCheck = true;

      if (modifier.isForced || selectedModifierItems.length > 0) {
        if (modifier.isForced) {
          const minimumModifierItems = minLimit || 1;
          if (selectedModifierItems.length < minimumModifierItems)
            modifierRequiredCheck = false;
        }
        modifierItemsValid = modifierItems.reduce(
          (modifierItemValid, modifierItem) => {
            let modifierItemValidFlag = true;
            let modifierItemRequiredCheck = true;
            const { items, minLimit, maxLimit } = modifierItem;
            const selectedSubItems = items.filter((item) => item.selected);
            if (modifierItem.isForced || selectedSubItems.length > 0) {
              if (modifierItem.isForced) {
                const minimumSubItems = minLimit || 1;
                if (selectedSubItems.length < minimumSubItems)
                  modifierItemRequiredCheck = false;
              }
              if (
                maxLimit &&
                (minLimit > selectedSubItems.length ||
                  maxLimit < selectedSubItems.length)
              )
                modifierItemValidFlag = false;
            }

            modifierItem.valid =
              modifierItemValidFlag && modifierItemRequiredCheck;
            return modifierItemValid && modifierItemValidFlag;
          },
          true
        );

        if (
          maxLimit &&
          (minLimit > selectedModifierItems.length ||
            maxLimit < selectedModifierItems.length)
        ) {
          modifierValidFlag = false;
        }
      } else
        modifierItems.forEach((modifierItem) => {
          modifierItem.valid = true;
        });

      modifierValidFlag =
        modifierValidFlag && modifierItemsValid && modifierRequiredCheck;
      modifier.valid = modifierValidFlag;
      return modifierValid && modifierValidFlag;
    },
    true
  );

  menuItem.valid = modifiersValid;
  return menuItem;
};

const filterPortion = ({ modifiers }, portionId) => {
  const copy = clone(modifiers);
  const filteredPortions = copy.filter((modifier) => {
    const { portions } = modifier;
    if (portions && portions.length > 0) {
      const foundPorton = portions.find((portion) => portion === portionId);
      return foundPorton != undefined;
    } else return true;
  });
  return filteredPortions;
};

const selectCartItems = (menuItemDetails, cartDetails) => {
  const { modifiers, portions } = menuItemDetails;
  if (cartDetails && cartDetails.portion) {
    const { portion } = cartDetails;
    portions.forEach((eachPortion) => {
      eachPortion.selected = eachPortion._id === portion._id;
    });
  }
  if (
    cartDetails &&
    cartDetails.cartModifiers &&
    cartDetails.cartModifiers.length > 0
  ) {
    const { cartModifiers } = cartDetails;
    cartModifiers.forEach((eachCartModifier) => {
      const { cartModifier, cartModifierItems } = eachCartModifier;
      if (cartModifier) {
        const foundMenuModifier = modifiers.find(
          (eachModifier) => eachModifier._id === cartModifier._id
        );
        if (foundMenuModifier) {
          foundMenuModifier.selected = true;
          if (cartModifierItems && cartModifierItems.length > 0) {
            cartModifierItems.forEach((eachCartModifierItem) => {
              const { cartModifierSubItems } = eachCartModifierItem;
              const foundModifierItem = foundMenuModifier.modifierItems.find(
                (eachModifierItem) =>
                  eachModifierItem._id ===
                  eachCartModifierItem.cartModifierItem._id
              );
              foundModifierItem.selected = true;

              if (cartModifierSubItems && cartModifierSubItems.length > 0) {
                cartModifierSubItems.forEach((eachCartItem) => {
                  const foundSubItem = foundModifierItem.items.find(
                    (eachItem) =>
                      eachItem._id === eachCartItem.cartModifierSubItem._id
                  );
                  foundSubItem.selected = true;
                });
              }
            });
          }
        }
      }
    });
  }

  return menuItemDetails;
};
