import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import {
  LocationState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
@Injectable()
export class WJLocationAccessGuard implements CanActivate {
  constructor(private router: Router,private store:Store) { }
  canActivate() {
    return new Promise<boolean>(async (resolve, reject) => {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          let selLoc = this.store.selectSnapshot(LocationState.getSelectedLocation);
          this.router.navigate([`/locations/${selLoc._id}/order`]);
          resolve(false);
        },
        (error) => {
          resolve(true);
        }
        )
    });
  }

}