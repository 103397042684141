<div class="cart-list-container" *ngFor="let item of items; trackBy: trackById">
  <div class="cart-item">
    <div class="item-name">
      <!-- {{ item.menuItem.name }} -->
      {{ item.menuItem.name | formattedLanguage : item.menuItem.translation }}
    </div>
    <div class="item-count-outer">
      <wj-item-count
        [menuitem]="item"
        [reviewmenuitem]="isReviewCart"
      >
      </wj-item-count>
    </div>
  </div>
  <div
    class="edit-item"
    data-qa="list-cart-edit"
    aria-label="edit cart item"
    *ngIf="checkModifier(item)"
    (click)="editListCart(item)"
  >
    {{ 'edit-item' | translate }}
  </div>
</div>

<!-- <ng-container *ngIf="showModifier">
  <wj-modifier [itemDetail]="menuId" (closeModifier)="closeModal()" [isEdit]="true"></wj-modifier>
</ng-container> -->
