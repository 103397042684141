<button
  *ngIf="checkModifier; else loggedOut"
  (click)="addItem(menuItem)"
  data-qa="add-button"
  class="button-count-container button-add"
  [ngClass]="{
    loading: loading
  }"
>
  <span
    class="add-item"
    data-qa="add"
    attr.aria-label="{{ 'add' | translate }}"
    >{{ 'add' | translate }}</span
  >
</button>
<span
  *ngIf="checkCustomize"
  class="customise"
  data-qa="customize"
  attr.aria-label="{{ 'item-customizable' | translate }}"
  >{{ 'item-customizable' | translate }}</span
>

<ng-template #loggedOut>
  <div
    class="button-count-container"
    [ngClass]="{
      'show-count-container': reviewMenuItem === true ? menuItem.count : value,
      loading: loading
    }"
  >
    <button
      (click)="subtractCount(menuItem)"
      data-qa="decrement-button"
      class="action-btn count-dec"
      attr.aria-label="{{ 'subtract' | translate }}"
    >
      <img src="assets/images/WJ_Minus_Icon.svg" />
    </button>
    <div
      [ngClass]="{
        'count-value': value
      }"
    >
      {{ value }}
    </div>
    <button
      class="action-btn"
      data-qa="increment-button"
      [ngClass]="{
        'count-plus': reviewMenuItem === true ? menuItem.count : value
      }"
      (click)="addCount(menuItem)"
      attr.aria-label="{{ 'add' | translate }}"
    >
      <img src="assets/images/WJ_Plus_Icon.svg" />
    </button>
  </div>
</ng-template>

<!-- Modifier Popup -->
<!-- <ng-container *ngIf="showModifier">
  <wj-modifier [itemDetail]="menuItemId" (closeModifier)="closeModal()" [isEdit]="false"></wj-modifier>
</ng-container> -->
