// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';

import { environment } from '../environments/environment';
@Injectable({
  providedIn: 'root',
})
export class VenueManagementService {
  constructor(private http: HttpClient) {}

  fetchAllVenues(locationId,organizationId,filter?) {
    let url = `${environment.locationV1ApiUrl}locations/${locationId}/tableManagement?organizationId=${organizationId}`;
  if(filter.tableType){
      url +=`&tableType=${filter.tableType}`
  }; 
  if(filter.selectedTime){
    url +=`&selectedTime=${filter.selectedTime}`
  };  
  if(filter.timeZoneOffset){
    url +=`&timeZoneOffset=${filter.timeZoneOffset}`
  }; 
    return this.http.get(url);
  }

  isVenueManagementOrder(deliveryTypeTextCode){
    if(deliveryTypeTextCode === 'DELIVERYTYPE03' || deliveryTypeTextCode === 'DELIVERYTYPE08' || deliveryTypeTextCode === 'DELIVERYTYPE09' || deliveryTypeTextCode === 'DELIVERYTYPE10'){
      return true
  } else {
      return false;
  }
  }

}
