import { ViewAllOrders } from '../models';

export class FetchViewAllOrdersList {
    static readonly type = '[VIEWALLORDERS] FetchList';

    constructor(public payload?: any) {}
}

export class FetchInProgressOrderList {
    static readonly type = '[VIEWALLORDERS] FetchInprogressList';

    constructor(public payload?: any) {}
}


