import { State, Selector, StateContext, Action, Store,Select } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import { Injectable } from '@angular/core';
import { tap, catchError } from 'rxjs/operators';
import { Observable } from 'rxjs/internal/Observable';

import { FetchPaymentMethods, SetPaymentMethod, FetchSavedCards,TokenizeCard,SetSelectedCard,ClearSelectedCard ,ClearPaymentDetails,SetNewCard,TokenizeProfile,RemoveCard,} from '../actions';
import { PaymentMethodService } from '../services';
import { PaymentMethod,PaymentDetails,SavedCard ,Cart,NewCard} from '../models';
import { CartState } from './cart.state';
import { LocationState } from './location.state';
import { AuthState } from './auth.state';

export class PaymentMethodModel {
  paymentList: PaymentMethod[];
  selectedPayment: string;
  selectedPaymentType : string;
  paymentDetails : PaymentDetails;
  savedCards : SavedCard[];
  selectedCard : SavedCard;
  customerProfileId : string;
  newCard : NewCard;
  payUsingSavedCard : boolean;
  cardType : string;
  saveToAccount : boolean;
}

@State<PaymentMethodModel>({
  name: 'paymentMethods',
  defaults: {
    paymentList: null,
    selectedPayment: null,
    savedCards: null,
    paymentDetails: {
      order_number: '',
      comments: '',
      amount: 0,
      payment_method: '',
      token: {
        code: '',
        name: ''
      },
      custom: {
        ref1: '',
        ref2: ''
      },
      giftCardDetails: null,
      paymentGateway: '',
      profileToken: {
        code: '',
        name: ''
      }
    },
    selectedCard: null,
    customerProfileId: null,
    newCard: null,
    payUsingSavedCard: false,
    cardType: '',
    selectedPaymentType: '',
    saveToAccount: false,
  }
})
@Injectable()
export class PaymentMethodState {
  constructor(
    private paymentService: PaymentMethodService,
    private store: Store
  ) {}
  @Select(CartState.getCart) cartPrice$: Observable<Cart>;

  @Selector()
  static getPaymentsList(state: PaymentMethodModel) {
    return state.paymentList;
  }
  @Selector()
  static getSelectedPayment(state: PaymentMethodModel) {
    return state.selectedPayment;
  }
  @Selector()
  static getSavedCards(state: PaymentMethodModel) {
    return state.savedCards;
  }

  @Selector()
  static getSelectedCards(state: PaymentMethodModel) {
    return state.selectedCard;
  }
  @Selector()
  static getNewCard(state: PaymentMethodModel) {
    return state.newCard;
  }
  @Selector()
  static getPaymentDetails(state: PaymentMethodModel) {
    return state.paymentDetails;
  }

  @Selector()
  static getPaymentProfile(state: PaymentMethodModel) {
    return state.customerProfileId;
  }

  @Selector()
  static getPaymentState(state: PaymentMethodModel) {
    return {
      paymentList: state.paymentList,
      selectedPayment: state.selectedPayment,
      savedCards: state.savedCards,
      paymentDetails: state.paymentDetails,
      selectedCard: state.selectedCard,
      customerProfileId: state.customerProfileId,
      newCard: state.newCard,
      payUsingSavedCard: state.payUsingSavedCard,
      cardType: state.cardType,
      saveToAccount : state.saveToAccount,
    };
  }
  @Selector()
  static getSelectedPaymentType(state: PaymentMethodModel) {
    return state.selectedPaymentType;
  }
  @Selector()
  static getCardType(state: PaymentMethodModel) {
    return state.cardType;
  }
  @Action(FetchPaymentMethods)
  fetchPaymentMethods(
    { setState }: StateContext<PaymentMethodModel>,
    { payload }: FetchPaymentMethods
  ) {
    return this.paymentService.fetchPaymentMethods().pipe(
      tap(response => {
        if (response) {
          setState(
            patch({
              paymentList: response
            })
          );
        } else throw response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  @Action(SetPaymentMethod)
  setPaymentMethod(
    { setState }: StateContext<PaymentMethodModel>,
    { payload }: SetPaymentMethod
  ) {
    return this.paymentService.setPaymentMethod(payload).pipe(
      tap(response => {
        if (response) {
          setState(
            patch({
              selectedPayment: response.text,
              selectedPaymentType: response.type
            })
          );
        } else throw response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  @Action(FetchSavedCards)
  fetchSavedCards(
    { setState }: StateContext<PaymentMethodModel>,
    { payload }: FetchPaymentMethods
  ) {
    const cart = this.store.selectSnapshot(CartState.getCart);

   
    let customer = this.store.selectSnapshot(AuthState.getCommonAuthDetails);

    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );

    return this.paymentService.fetchSavedCards(location._id, customer.customerId).pipe(
      tap(response => {
        if (response) {
          let customerProfileId = response['customer_code'];
          let cards = response['card'] ? JSON.parse(atob(response['card'])) : [];
          {
            token: response;
          }
          setState(
            patch({
              savedCards: cards,
              customerProfileId: customerProfileId
            })
          );
        } else throw response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  @Action(TokenizeCard)
  tokenizeCard(
    { setState }: StateContext<PaymentMethodModel>,
    { payload }: TokenizeCard
  ) {
    const paymentGateway = this.store.selectSnapshot(
      CartState.getPaymentGateway
    );
    const card = this.store.selectSnapshot(PaymentMethodState.getNewCard);

    let paymentDetails = this.store.selectSnapshot(
      PaymentMethodState.getPaymentDetails
    );
    let url = paymentGateway.tokenizationURL;

    return this.paymentService.tokenizeCard(url, payload).pipe(
      tap(response => {
        if (response) {
         
            paymentDetails.token.code = response["token"];
            paymentDetails.token.name = payload.name;
              setState(
                patch({
                  paymentDetails: paymentDetails,
                  cardType: payload.card_type
                })
              );
        } else throw response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  @Action(TokenizeProfile)
  tokenizeCardProfile(
    { setState }: StateContext<PaymentMethodModel>,
    { payload }: TokenizeProfile
  ) {
    const paymentGateway = this.store.selectSnapshot(
      CartState.getPaymentGateway
    );
    let paymentDetails = this.store.selectSnapshot(
      PaymentMethodState.getPaymentDetails
    );
    let url = paymentGateway.tokenizationURL;

    return this.paymentService.tokenizeCard(url, payload).pipe(
      tap(response => {
        if (response) {
         
          paymentDetails.profileToken.code = response["token"];
          paymentDetails.profileToken.name = payload.name;
              setState(
                patch({
                  paymentDetails: paymentDetails,
                })
              );
             
        } else throw response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }

  @Action(SetSelectedCard)
  SetSelectedCard(
    { patchState }: StateContext<PaymentMethodModel>,
    { payload }: SetSelectedCard
  ) {
    patchState({
      selectedCard: payload,
      payUsingSavedCard: true,
      cardType: payload.cardType
    });
  }

  @Action(ClearSelectedCard)
  ClearSelectedCard(
    { patchState }: StateContext<PaymentMethodModel>,
    { payload }: ClearSelectedCard
  ) {
    patchState({
      selectedCard: null,
      payUsingSavedCard: false,
      cardType: ''
    });
  }

  @Action(ClearPaymentDetails)
  ClearPaymentDetails(
    { patchState }: StateContext<PaymentMethodModel>,
    { payload }: ClearPaymentDetails
  ) {
    patchState({
      paymentList: null,
    selectedPayment: null,
    savedCards: null,
    paymentDetails: {
      order_number: '',
      comments: '',
      amount: 0,
      payment_method: '',
      token: {
        code: '',
        name: ''
      },
      custom: {
        ref1: '',
        ref2: ''
      },
      giftCardDetails: null,
      paymentGateway: '',
      profileToken: {
        code: '',
        name: ''
      }
    },
    selectedCard: null,
    customerProfileId: null,
    newCard: null,
    payUsingSavedCard: false,
    cardType: '',
    selectedPaymentType: '',
    saveToAccount: false,
    });
  }

  @Action(SetNewCard)
  SetNewCard(
    { patchState }: StateContext<PaymentMethodModel>,
    { payload }: SetNewCard
  ) {
    patchState({
      newCard: payload,
      cardType: payload.card_type,
      saveToAccount : payload.saveToAccount,
    });
  }

  @Action(RemoveCard)
  removeCard(
    { setState }: StateContext<PaymentMethodModel>,
    { payload }: RemoveCard
  ) {
    
    const location = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    let customerProfileId = this.store.selectSnapshot(
      PaymentMethodState.getPaymentProfile
    )
    
    let data = {
      locationId: location._id,
      customer_code: customerProfileId ? customerProfileId : "",
      card_id: payload.card_id
  };
  const selectedCard = this.store.selectSnapshot(PaymentMethodState.getSelectedCards);
  
    return this.paymentService.removeSavedCard(data).pipe(
      tap(response => {
        if (response) {
          this.store.dispatch(new FetchSavedCards());
          if(selectedCard && selectedCard.card_id == payload.card_id){
            this.store.dispatch(new ClearPaymentDetails());
          }
        } else throw response;
      }),
      catchError(error => {
        return Observable.throw(error);
      })
    );
  }
}
