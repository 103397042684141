import { Router } from '@angular/router';
import { OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormGroup,
  FormControl,
  Validators,
  AbstractControl,
} from '@angular/forms';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { FormBuilder } from '@angular/forms';
import * as moment from 'moment';
import {
  BaseComponent,
  environment,
  Regex,
  AuthEmailSignUp,
  AuthEmailState,
  SetUserDetailsOnSignUp,
  AuthEmailSignUpResponse,
  LocationState,
  SharedService,
  Provinces,
  ClearSignupData,
  checkUserAvailability,
  UserAvailabilityResponse,
  WhitelabelState
} from '@moduurnv2/libs-orderingapp/src/core';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
export class EmailSignUpBaseComponent extends BaseComponent implements OnInit {
  stepper: number = 1;
  _modalClose: any = null;
  isMobileView:boolean=true;
  _modalCloseall: any = null;
  showDOB: boolean;
  userAvailabilityCheck: any;
  config: any = {
    format: 'YYYY/MM/DD',
    disableKeypress: true,
  };
  loginLeftArrowIcon: string =
    environment.image_basepath + environment.images.arrow_left_icon;
  @Output() modalValue: EventEmitter<any> = new EventEmitter<any>();
  user: AuthEmailSignUp = {
    email: '',
    password: '',
    dateOfBirth: '',
    firstName: '',
    lastName: '',
    organizationId: '',
    primaryPhone: '',
    title: 1,
    username: '',
  };
  phoneNumber = {
    international: '',
    valid: undefined,
  };
  emailRegex = Regex.email;
  authSignUpSubscription: Subscription;

  formSubmitted: boolean = false;
  errorInSignUp: string = '';
  errorInPhone: string = '';
  invalidDate = false;
  signUpForm1 = this.fb.group(
    {
      email: [
        '',
        Validators.compose([
          Validators.required,
          this._shared.emailpatternValidator(),
        ]),
      ],
      password: [
        '',
        [Validators.required],
        this._shared.passwordValidator.bind(this._shared),
      ],
      confirmPassword: ['', [Validators.required]],
    },
    {
      validator: this._shared.MatchPassword('password', 'confirmPassword'),
    }
  );

  signUpForm2 = new FormGroup({
    firstName: new FormControl('', Validators.required),
    lastName: new FormControl('', Validators.required),
    dateOfBirth: new FormControl(''),
    // primaryPhone: new FormControl('')
  });
  @Select(AuthEmailState.getSignUpData) signUpResp$: Observable<
    AuthEmailSignUpResponse
  >;
  @Select(AuthEmailState.getUserAvailabilityResponse)
  userAvailable$: Observable<UserAvailabilityResponse>;
  countriesSupported = Provinces.countries;
  signUpResponseText: string = '';
  constructor(
    public store: Store,
    public router: Router,
    private _shared: SharedService,
    private fb: FormBuilder
  ) {
    super();
    let whiteLabel = this.store.selectSnapshot(WhitelabelState.getOther);
    this.showDOB = !whiteLabel.hideDOB;
  }

  ngOnInit() {
    const loggedInData = this.store.selectSnapshot(AuthEmailState.getLoginData);
    this.isMobileView = this._shared.isMobileScreen();
  }

  ngOnDestroy() {
    if (this.authSignUpSubscription) this.authSignUpSubscription.unsubscribe();
  }

  goBack(value) {
    if (value === 1) this.stepper = 1;
    else if (value === 2) this.stepper = 2;
    else if (value === 3) this.modalValue.emit('REGISTER');
    else this.modalValue.emit('LOGIN');
  }
  clearValue() {
    this.errorInSignUp = '';
    this.errorInPhone = '';
    this.phoneNumber.international = '';
    this.phoneNumber.valid = undefined;
  }

  formatDate(event) {
    let birthDate = event.target['value'];
    let formattedDate = '';
    let cardDate = '';
    let numbersPattern = new RegExp(/^[0-9/]*$/);
    if(birthDate.length==5) {
      let dateSplit = birthDate.split("/");
      if((Number(dateSplit[0])>=1 && Number(dateSplit[0]<=12)) && (Number(dateSplit[1]>=1 && Number(dateSplit[1]<=31)))) {
        this.invalidDate = false;
      } else {
        return (event.target.value = '');}
    } else if(birthDate.length>5) {
      return (event.target.value = '');
    } else if(!birthDate.length)this.invalidDate = false;
    setTimeout(() => {
      birthDate = event.target['value'];
      if (numbersPattern.test(birthDate)) {
        for (let pos = 0; pos < birthDate.length; pos++) {
          if (pos == 0) {
            cardDate = birthDate.charAt(pos);
          } else if (pos == 2 && !birthDate.includes('/')) {
            cardDate = cardDate + '/' + birthDate.charAt(pos);
          } else {
             this.invalidDate = false
            cardDate = cardDate + birthDate.charAt(pos);
          }
        }
        formattedDate = cardDate;
        return (event.target.value = formattedDate);
      } else {
        return (event.target.value = '');
      }
    });
  }
  proceed(stepper) {
    this.formSubmitted = true;
    this.stepper = stepper;
    if (stepper === 1) {
      if (this.signUpForm1.valid) {
        let fields = this.signUpForm1.value;
        this.user.email = fields.email;
        this.user.username = fields.email;
        this.user.password = fields.password;
        this.formSubmitted = false;
        this.invalidDate = false;
        const request = {
          username: this.user.email,
          organizationId : Config.organizationId
        };
        this.store.dispatch(new checkUserAvailability(request));
        this.userAvailabilityCheck = this.userAvailable$.subscribe(
          (response) => {
            if (response && response.errorCode != 4000) {
              this.errorInSignUp = '';
              this.stepper = 2;
            } else if (response) {
              this.errorInSignUp = response.error;
              this.stepper = 1;
            }
          },
          (error) => {
          }
        );
      }
    } else if (stepper === 2) {
      if(this.signUpForm2.value.dateOfBirth && !this.signUpForm2.value.dateOfBirth.includes("/")) {
        this.signUpForm2.value.dateOfBirth = [this.signUpForm2.value.dateOfBirth.slice(0, 2), "/", this.signUpForm2.value.dateOfBirth.slice(2)].join('');
      }
      let dateSplit = this.signUpForm2.value.dateOfBirth?this.signUpForm2.value.dateOfBirth.split("/"):null;
      if (this.signUpForm2.value.dateOfBirth && (Number(dateSplit[0])>=1 && Number(dateSplit[0]<=12)) && (Number(dateSplit[1]>=1 && Number(dateSplit[1]<=31)))) {
        this.signUpForm2.value.dateOfBirth = moment("1975/"+this.signUpForm2.value.dateOfBirth, 'YYYY/MM/DD')
      } else {
        if(this.signUpForm2.value.dateOfBirth)
        this.invalidDate = true;
        else  this.invalidDate = false;
        this.signUpForm2.controls['dateOfBirth'].setErrors(null);
      }
      if (
        this.signUpForm2.valid &&
        ((this.signUpForm2.value.dateOfBirth && this.invalidDate == false) ||
          this.signUpForm2.value.dateOfBirth == '')
      ) {
        let fields = this.signUpForm2.value;
        this.user.firstName = fields.firstName;
        this.user.lastName = fields.lastName;
        this.user.dateOfBirth = fields.dateOfBirth
          ? fields.dateOfBirth
          : undefined;
        this.formSubmitted = false;
        if (
          this.phoneNumber.international == '' ||
          this.phoneNumber.valid == true
        ) {
          this.user.primaryPhone = this.phoneNumber.international
            ? this.phoneNumber.international
            : '';
          this.signUp();
        }
      }
    }
  }

  get signUpFormControl1() {
    return this.signUpForm1.controls;
  }
  get signUpFormControl2() {
    return this.signUpForm2.controls;
  }

  formattedPhone(event) {
    this.phoneNumber = event;
  }

  signUp() {
    const signUpData: any = Object.assign({}, this.user);
    this.store.dispatch(new ClearSignupData());
    this.store.dispatch(new SetUserDetailsOnSignUp(signUpData));
    this.authSignUpSubscription = this.signUpResp$.subscribe(
      (response) => {
        if (response) {
          if (response.status == 200) {
            if (response.allowLogin) {
              this.closeSignup();
              this.proceedToHome();
            } else {
              this.stepper = 3;
              if (response.validationSent) {
                this.signUpResponseText = 'sign-up-link-verification';
              }
              if (!response.allowLogin) {
                this.signUpResponseText = 'not-allow-login';
              }
            }

          } else if (response.msg) {
            this.errorInPhone = response.msg;
          }
        }
      },
      (error) => {
        this.errorInPhone = error;
      }
    );
  }

  proceedToHome() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if(this.isMobileView)
    this.router.navigate([`/locations/${selectedLocation._id}/home`]);
  }
  closeSignup() {
    if (this._modalClose) this._modalCloseall();
  }
}
