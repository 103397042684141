import { BaseComponent } from '@moduurnv2/libs-orderingapp/src/core';

import { Router } from '@angular/router';
import { environment } from './../../../core/environments/environment';
import { Store, Select } from '@ngxs/store';
import { ChangePopupStatus, ClearLoginDetails } from './../../../core/actions';
import { Auth } from './../../../core/models';
import { LocationState, AuthState } from './../../../core/state';

import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
export class UserProfileBaseComponent extends BaseComponent {
  @Select(AuthState.getCommonAuthDetails)
  loggedInUserDetails$: Observable<Auth>;

  authSubscription: Subscription;
  loggedInUserDetails;
  loginRightArrowIcon: string =
    environment.image_basepath + environment.images.arrow_right_icon;
  logOutIcon: string =
    environment.image_basepath + environment.images.logout_icon;
  rateIcon: String = environment.image_basepath + environment.images.rate_icon;
  aboutIcon: string =
    environment.image_basepath + environment.images.about_icon;
  helpIcon: string = environment.image_basepath + environment.images.help_icon;
  adrsBookIcon: string =
    environment.image_basepath + environment.images.adrsbook_icon;
  ProfileIcon: string =
    environment.image_basepath + environment.images.editprofile_icon;
  notificationIcon: string =
    environment.image_basepath + environment.images.notification_icon;
  settingsIcon: string =
    environment.image_basepath + environment.images.settings_icon;
  paymentIcon: string =
    environment.image_basepath + environment.images.payment_icon;
  pointsicon: string =
    environment.image_basepath + environment.images.points_icon;
  paymentIconCircle: string =
    environment.image_basepath + environment.images.payment_circle_icon;

  userProfileModal: boolean = false;
  viewChangeAdrsModalStatus: boolean;
  addressBookStatus: boolean = true;
  constructor(private router: Router, public store: Store) {
    super();
    this.authSubscription = this.loggedInUserDetails$.subscribe((res) => {
      this.loggedInUserDetails = res;
    });
  }
  ngOnDestroy() {
    if (this.authSubscription) this.authSubscription.unsubscribe();
  }
  profileHeaderImg: string =
    environment.image_basepath + environment.images.profile_head;
  profileRightArrowIcon: string =
    environment.image_basepath + environment.images.arrow_right_icon;
  profileLeftArrowIcon: string =
    environment.image_basepath + environment.images.arrow_left_icon;

  logOutEmail() {
    this.store.dispatch(new ClearLoginDetails());
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    this.router.navigate([`/locations/${selectedLocation._id}/home`]);
  }

  goBack() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/home`]);
    }
  }
  paymentMethods() {
    this.router.navigate([`/payment-profile`]);
  }
  userProfile() {
    this.router.navigate([`/user-profile/edit`]);
  }

  useraddressBook() {
    this.store.dispatch(new ChangePopupStatus(true));
    this.viewChangeAdrsModalStatus = true;
  }
  closeChangeAdrs() {
    this.store.dispatch(new ChangePopupStatus(false));
    this.viewChangeAdrsModalStatus = false;
  }
}
