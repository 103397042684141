import {
  BaseComponent,
  environment,
  FetchLocationInfo,
  LocationState,
  LocationInfo,
  CartState,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, Input, Output } from '@angular/core';
import {
  SetSelectedLocation,
  ClearCart,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
export class LocationInfoBaseComponent extends BaseComponent implements OnInit {
  // @Input() location;
  location;
  @Input() modalPopup;
  @Output() closeEvent = new EventEmitter();
  @Input() data: any = null;
  currLat: number;
  currLng: number;
  _modalClose: any = null;
  _data: any = null;
  constructor(
    public router: Router,
    public store: Store,
    public sweetAlert: SweetAlertService,
    public modal: ModalService,
    public translate: TranslateService
  ) {
    super();
  }
  ngOnInit(): void {
    this.location = this.data.location;
    this.store.dispatch(new FetchLocationInfo(this.location._id));
  }
  imgBasePath: string = environment.image_basepath;
  icon = {
    orderHere: this.imgBasePath + environment.images.order_here,
    orderHereWhite: this.imgBasePath + environment.images.order_here_white,
    phone: this.imgBasePath + environment.images.phone,
    favoriteIcon: this.imgBasePath + environment.images.favorite_icon,
    nonFavoriteIcon: this.imgBasePath + environment.images.nonFavorite_icon,
    getDirections: this.imgBasePath + environment.images.get_directions_icon,
    orderForLater: this.imgBasePath + environment.images.order_for_later,
    getDirection: this.imgBasePath + environment.images.get_direction_icon,
    orderForLaterInactive:
      this.imgBasePath + environment.images.order_for_later_inactive,
    logoIcon: environment.image_basepath + environment.images.logo_big,
    close: this.imgBasePath + environment.images.close,
    getDirectionDesktop:
      this.imgBasePath + environment.images.get_direction_desktop_icon,
  };
  async orderFromOrganization(location) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );

    if (location.isLocationOpen) {
      if (selectedLocation && selectedLocation._id != location._id) {
        let selectedCart = this.store.selectSnapshot(CartState.getCart);
        if (selectedCart) {
          let vm = this;
          await this.sweetAlert
            .confirm(
              this.translate.instant('are-you-sure'),
              this.translate.instant('loc-change-clear-cart')
            )
            .then((choice) => {
              if (choice) {
                this.closePopup();
                this.router.navigate([`locations/${location._id}/home`]);
                this.store.dispatch(new SetSelectedLocation(location._id));
              } else {
                return;
              }
            });
        } else {
          this.closePopup();
          this.router.navigate([`locations/${location._id}/home`]);
          this.store.dispatch(new SetSelectedLocation(location._id));
        }
      } else {
        this.closePopup();
        this.router.navigate([`locations/${location._id}/home`]);
        this.store.dispatch(new SetSelectedLocation(location._id));
      }
    }
  }
  getSelectedCoordinates() {
    let isRefinedLocation = localStorage.getItem('refinedLocation')
      ? JSON.parse(localStorage.getItem('refinedLocation'))
      : undefined;
    if (isRefinedLocation) {
      this.currLat = isRefinedLocation.coordinates[1];
      this.currLng = isRefinedLocation.coordinates[0];
    }
  }

  getlocation(selectedLng, selectedLat) {
    this.getSelectedCoordinates();
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.currLat = position.coords.latitude;
            this.currLng = position.coords.longitude;
            this.getSelectedCoordinates();
            this.mapsSelector(
              this.currLat,
              this.currLng,
              selectedLng,
              selectedLat
            );
          }
        },
        (error) => {
          this.mapsSelector(
            this.currLat,
            this.currLng,
            selectedLng,
            selectedLat
          );
        }
      );
    }
  }
  mapsSelector(currentLat, currentLng, selectedLng, selectedLat) {
    if (
      /* if we're on iOS, open in Apple Maps */
      navigator.platform.indexOf('iPhone') != -1 ||
      navigator.platform.indexOf('iPad') != -1 ||
      navigator.platform.indexOf('iPod') != -1
    ) {
      // var newWin = window.open(environment.mapbox.directionURLIOS);
      let url;
      if (currentLat != undefined && currentLng != undefined) {
        url =
          environment.mapbox.directionURLIOS +
          'saddr=' +
          currentLat +
          ',' +
          currentLng +
          '&daddr=' +
          selectedLat +
          ',' +
          selectedLng +
          '&amp;ll=';
      } else {
        url =
          environment.mapbox.directionURLIOS +
          '&daddr=' +
          selectedLat +
          ',' +
          selectedLng +
          '&amp;ll=';
      }
      let newWin = window.open(url);
      if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        this.sweetAlert.alertWarning(
          '',
          this.translate.instant('device-popup-blocker')
        );
      }
    } else {
      /* else use Google */

      let url;
      if (currentLat != undefined && currentLng != undefined) {
        url =
          environment.mapbox.directionURL +
          'saddr=' +
          currentLat +
          ',' +
          currentLng +
          '&daddr=' +
          selectedLat +
          ',' +
          selectedLng +
          '&amp;ll=';
      } else {
        url =
          environment.mapbox.directionURL +
          '&daddr=' +
          selectedLat +
          ',' +
          selectedLng +
          '&amp;ll=';
      }
      let newWin = window.open(url);
      if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
        this.sweetAlert.alertWarning(
          '',
          this.translate.instant('device-popup-blocker')
        );
      }
    }
  }

  closePopup() {
    if (this._modalClose) this._modalClose();
  }

  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
}
