import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { LocationState, SetSelectedRoom,VenueManagementState } from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
import * as CryptoJS from 'crypto-js';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
@Injectable()
export class WJSelectedLocationGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}
  canActivate(route: ActivatedRouteSnapshot) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation) {
      return true;
    } else {
      let table;
      let b64String = (route.queryParams && route.queryParams.table) ? route.queryParams.table.replaceAll(' ','+') : undefined;
      // let b64String = decodeURIComponent(route.queryParams.table);
      if(!b64String){
        this.router.navigate(['emptyTable'])
        return false;
      }
      var bytes  = CryptoJS.AES.decrypt(b64String, Config.cipher);
      let decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
      if(typeof(decryptedData) ==='string')
      table = JSON.parse(decryptedData);
      else
      table = decryptedData;
      if(table)
        this.store.dispatch(new SetSelectedRoom(table));
      this.router.navigate(['locations'], {
        queryParamsHandling: 'merge',
        preserveFragment: true,
        queryParams : route.queryParams
      });
      return false;
    }
  }
}
