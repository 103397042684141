<div class="loader-container">
  <div>
    <ng-container *ngIf="isProcessing; else successLoader">
      <!-- <div class="loading"></div> -->
      <img src="assets/images/WJ_Loader.gif" />
      <p>{{'processing-order'| translate }}</p>
    </ng-container>
    <ng-template #successLoader>
      <img src="assets/images/WJ_Loader_Check.gif" />
      <p>{{'order-processed'| translate}}</p>
    </ng-template>
  </div>
</div>
