// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { PaymentMethod } from '../models';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class PaymentMethodService {

  private Paymentsinit:PaymentMethod = {
    eTransfer: false,
    allowTip: false,
    payInterac: false,
    payLater: true,
    payNow: false,
  };
    constructor(private http: HttpClient, private store: Store) {}
  
    fetchPaymentMethods(): Observable<any> {
      return of(this.Paymentsinit).pipe();
    }
    setPaymentMethod(payload) {
      return of(payload).pipe();
    }

    fetchSavedCards(locationId,customerId) {
    let  url = environment.paymentApiUrl + 'payment/bambora/' + locationId + "/getCustomer/" + customerId
      return this.http.get(url);
    }
    removeSavedCard(data) {
      let  url = environment.paymentApiUrl + 'payment/bambora/removeCard';
      return this.http.post(url,data);
    }

    tokenizeCard(url,card,header?){
      let options = undefined;
      let body = JSON.stringify(card);
    if (header) {
      options = { headers: header };
    }
      return this.http.post(url,body,options).pipe(this.extractData);
    }

    private extractData(res) {
      let body = res;
      return body || {};
    }
  }