import {
  BaseComponent,
  environment,
  FetchLocationList,
  LocationState,
  Location,
  CartState,
  ClearCart,
  ChangeMapPopupStatus,
  ChangeadrsDetailsPopupStatus,
  SweetAlertService,
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, Output, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import {
  SetSelectedLocation,
  FetchLocationInfo,
  ClearSelectedLocation,
  FetchLocationDefaults,
} from '@moduurnv2/libs-orderingapp/src/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import * as mapboxgl from 'mapbox-gl';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { TranslateService } from '@ngx-translate/core';

export class OrganizationLocationsBaseComponent extends BaseComponent
  implements OnInit {
  geocoder: MapboxGeocoder;
  @Output() closeEvent = new EventEmitter();
  organizationLocations: Location[];
  locations: Location[];
  @Select(LocationState.getLocationsList) locationsList$: Observable<
    Location[]
  >;
  seletedLocatioId: string = undefined;
  selectedLocation: string = undefined;
  locationPinIcon: string =
    environment.image_basepath + environment.images.location_pin;
  logoIcon: string = environment.image_basepath + environment.images.logo_big;
  closeIcon: string = environment.image_basepath + environment.images.close;
  getDirections: string =
    environment.image_basepath + environment.images.get_directions_icon;
  getDirection: string =
    environment.image_basepath + environment.images.get_direction_icon;
  phone: string = environment.image_basepath + environment.images.phone;
  isRestaurantsAvailable: boolean = true;
  isLocationOn: boolean = true;
  getDirectionDesktop: string =
    environment.image_basepath + environment.images.get_direction_desktop_icon;
  constructor(
    public router: Router,
    sharedService: SharedService,
    public store: Store,
    public route: ActivatedRoute,
    public sweetAlert: SweetAlertService,
    public translate: TranslateService
  ) {
    super();
    //this.store.dispatch(new FetchLocationList());
    this.getlocation();
    this.locationsList$.subscribe((response) => {
      this.organizationLocations = this.locations = response;
      const selectedLocation = this.store.selectSnapshot(
        LocationState.getSelectedLocation
      );
      if (
        !selectedLocation &&
        !this.selectedLocation &&
        this.organizationLocations &&
        this.organizationLocations[0] &&
        this.organizationLocations[0]._id
      ) {
        this.selectLocation(this.organizationLocations[0]);
      } else if (selectedLocation && selectedLocation._id) {
        // this.seletedLocatioId = selectedLocation._id;
        if (this.organizationLocations && this.organizationLocations.length) {
          let index = this.organizationLocations.findIndex(
            (item) => item._id == selectedLocation._id
          );
          if (index != -1) {
            this.selectLocation(this.organizationLocations[index]);
            this.store.dispatch(
              new SetSelectedLocation(this.organizationLocations[index]._id)
            );
          } else {
            if (this.selectedLocation) {
              this.showLocationMissing();
            }
          }
        }
      }
    });
  }
  openMap() {
    this.store.dispatch(new ChangeadrsDetailsPopupStatus(false));
    this.store.dispatch(new ChangeMapPopupStatus(true));
  }
  closeMap() {
    this.store.dispatch(new ChangeMapPopupStatus(false));
  }

  ngOnInit() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation && selectedLocation._id) {
      this.seletedLocatioId = selectedLocation._id;
    }
    // this.store.dispatch(new FetchLocationList());
    this.subscribeLocations();

    Object.getOwnPropertyDescriptor(mapboxgl, 'accessToken').set(
      environment.mapbox.accessToken
    );
    var geocoder = new MapboxGeocoder({
      accessToken: mapboxgl.accessToken,
      placeholder: this.translate.instant('loc-search-placeholder'),
    });
    let isRefinedLocation = localStorage.getItem('refinedLocation')
      ? JSON.parse(localStorage.getItem('refinedLocation'))
      : undefined;
    geocoder.addTo('#geocoder');
    if (isRefinedLocation != undefined) {
      geocoder.query(isRefinedLocation.text);
    }
    geocoder.on('result', (results) => {
      this.isLocationOn = true;
      this.store.dispatch(
        new FetchLocationList(results.result.geometry.coordinates)
      );
      let refinedLocation = {
        placeName: results.result.place_name,
        coordinates: results.result.geometry.coordinates,
        text: results.result.text,
      };
      localStorage.setItem('refinedLocation', JSON.stringify(refinedLocation));
    });
  }
  subscribeLocations() {
    this.locationsList$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locations) => {
        this.isRestaurantsAvailable = true;
        if (locations && locations.length == 1) {
          this.router.navigate([`locations/${locations[0]._id}/home`]);
          this.store.dispatch(new SetSelectedLocation(locations[0]._id));
          this.store.dispatch(new FetchLocationDefaults());
        }
        if (locations && locations.length == 0)
          this.isRestaurantsAvailable = false;
        else this.isRestaurantsAvailable = true;
      });
  }
  closeOrganizationLocation() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation && selectedLocation._id) {
      this.seletedLocatioId = selectedLocation._id;
      this.router.navigate([`locations/${this.seletedLocatioId}/home`]);
    }
  }
  tabClick(tabSection?) {
    if (tabSection == 'FAVORITE') {
      this.organizationLocations = this.locations.filter(
        (loc) => loc.favourite == true
      );
    } else {
      this.organizationLocations = this.locations;
    }
  }
  getlocation() {
    if (window.navigator && window.navigator.geolocation) {
      window.navigator.geolocation.getCurrentPosition(
        (position) => {
          if (position) {
            this.isLocationOn = true;
            let isRefinedLocation = localStorage.getItem('refinedLocation')
              ? JSON.parse(localStorage.getItem('refinedLocation'))
              : undefined;
            if (isRefinedLocation) {
              let deliveryLocation =
                localStorage.getItem('selectedAdrsLocation') &&
                localStorage.getItem('selectedAdrsLocation') != 'undefined'
                  ? JSON.parse(localStorage.getItem('selectedAdrsLocation'))
                  : undefined;
              if (deliveryLocation) {
                this.store.dispatch(
                  new FetchLocationList(deliveryLocation['loc'])
                );
              } else {
                this.store.dispatch(
                  new FetchLocationList(isRefinedLocation.coordinates)
                );
              }
            } else {
              this.store.dispatch(
                new FetchLocationList([
                  String(position.coords.latitude),
                  String(position.coords.longitude),
                ])
              );
            }
          }
        },
        (error) => {
          this.isLocationOn = false;
          let isRefinedLocation = localStorage.getItem('refinedLocation')
            ? JSON.parse(localStorage.getItem('refinedLocation'))
            : undefined;
          if (isRefinedLocation) {
            this.store.dispatch(
              new FetchLocationList(isRefinedLocation.coordinates)
            );
          } else this.store.dispatch(new FetchLocationList());
        }
      );
    }
  }
  selectLocation(location) {
    this.selectedLocation = location;
    // this.store.dispatch(new SetSelectedLocation(location._id));
    this.store.dispatch(new FetchLocationInfo(location._id));
  }
  showLocationMissing() {
    this.sweetAlert
      .confirmWithoutCancel(
        '',
        'Your selected location is closed now.Please select another location'
      )
      .then((result) => {
        this.store.dispatch(new ClearSelectedLocation());
        this.selectedLocation = null;
        if (
          this.organizationLocations[0] &&
          this.organizationLocations[0]._id
        ) {
          this.selectLocation(this.organizationLocations[0]);
        }
        // this.getlocation();
      });
  }
}
