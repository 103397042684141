import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { LocationState, CartState } from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
@Injectable()
export class WJCartGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}
  canActivate() {
    const cart = this.store.selectSnapshot(CartState.getCart);
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (cart?.cart_id && selectedLocation) {
      return true;
    } else if (selectedLocation) {
      this.router.navigate([`/locations/${selectedLocation._id}/home`]);
    } else {
      this.router.navigate([`/locations`]);
      return false;
    }
  }
}
