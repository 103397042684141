// angular
import { Injectable } from '@angular/core';

// app
import { HttpClient } from '@angular/common/http';
import { environment } from '../../core/environments/environment';
import { Observable } from 'rxjs/internal/Observable';
import { tap } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { SetWhitelabel, SetOther, SetLandingPage } from '../actions';

@Injectable({
  providedIn: 'root'
})
export class AppInitService {
  constructor(
    private http: HttpClient,
    private store: Store
  ) { }

  load(organizationId: string): Observable<any> {

      const url = `${environment.organizationApiUrl}organizations/${organizationId}/configuration`;
      return this.http.get(url).pipe(
        tap( response => {
          const { whiteLabel , other, landingPage } = response;
          if(whiteLabel)
          {
            if(other){
              whiteLabel["currency"] = other.currencyType.text;
              whiteLabel["distance"] = other.distanceType.text;
            }
            this.store.dispatch(new SetWhitelabel(whiteLabel));
            this.store.dispatch(new SetOther(other));
            this.store.dispatch(new SetLandingPage(landingPage));
          }
        })
      );
  }
}
