import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot } from '@angular/router';
import { LocationState} from '@moduurnv2/libs-orderingapp/src/core';
import { Store } from '@ngxs/store';
@Injectable()
export class WJEmptyRedirectionGuard implements CanActivate {
  constructor(private router: Router, private store: Store) {}
  canActivate(route: ActivatedRouteSnapshot) {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation) {
      this.router.navigate([`/locations/${selectedLocation._id}/home`]);
    }
    else {
     let b64String = (route.queryParams && route.queryParams.table) ? route.queryParams.table.replaceAll(' ','+') : undefined;
      if(!b64String){
        this.router.navigate(['emptyTable'])
      }
    }
    return false;
  }
}
