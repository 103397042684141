import {
  BaseComponent,
  environment,
  LocationDefaults,
  FetchLocationDefaults,
  LocationDefaultState,
  FetchPaymentMethods,
  PaymentMethodState,
  SweetAlertService,
  LocationState,
  LocationStatus,
  DeliveryState,
  Delivery,
  SetSpecialInstruction,
  UpdateCartTotal,
  FetchCart,
  ClearSelectedCard,
  SetPaymentMethod,
  UpdateCart,
  GeoLocationState,
  FetchCurrentCoordinates
} from '@moduurnv2/libs-orderingapp/src/core';
import { OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import {
  Cart,
  CartState,
  CartItems,
  VenueManagementState,
  VenueManagementService,
} from '@moduurnv2/libs-orderingapp/src/core';
import { takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import {
  paymentCardType,
  paymentMethods,
} from './../../../core/services/constant';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { TranslateService } from '@ngx-translate/core';
import * as turf  from '@turf/helpers';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import transformScale from '@turf/transform-scale';
import { AlertPopupComponent } from '@moduurnv2/web/features/ui/components/alert-popup/alert-popup.component';
import { Location } from '@angular/common';
export class ReviewPayBaseComponent extends BaseComponent implements OnInit {
  @ViewChild('specialInstruction') specialInstructionField: ElementRef;
  @Select(LocationDefaultState.getLocationDefaults)
  locationDefaults$: Observable<LocationDefaults>;
  desktopView: boolean = false;
  @Select(LocationState.getLocationStatus) locationStatus$: Observable<
    LocationStatus
  >;
  addCardMethod: boolean = false;
  nextPage: boolean = false;
  @Select(CartState.getCart) cartPrice$: Observable<Cart>;
  @Select(CartState.getCartTip) tip$: Observable<Cart>;
  @Select(CartState.getCartItems) cartItems$: Observable<CartItems[]>;
  @Select(PaymentMethodState.getSelectedPayment)
  selectedPayment$: Observable<string>;
  @Select(DeliveryState.getSelectedDelivery) delivery$: Observable<Delivery>;
  @Select(CartState.getSpecialInstruction) specialInstruction$: Observable<
    string
  >;
  @Select(GeoLocationState.getCurrentCoordinates) getCurrentCoordinates$: Observable<any>;

  isSpecialInstruction: boolean = false;
  isSpecialInstructionPresent: boolean = false;
  locationDefaults: LocationDefaults;
  cartPrice: Cart;
  cartItems: CartItems[];
  specialInstructionText: String = '';
  locationStatus: LocationStatus;
  delivery: Delivery;
  isMobileView: boolean = true;
  imgBasePath: string = environment.image_basepath;
  icon = {
    close: this.imgBasePath + environment.images.close,
    // special_instructions:
    //   this.imgBasePath + environment.images.special_instructions,
    special_instructions: 'assets/images/special-instructions-new.svg',
    // alert: this.imgBasePath + environment.images.alert,
    alert: 'assets/images/alert-icon-new.svg',
    credit_card: this.imgBasePath + environment.images.visa_card,
    pay_on_delivery: this.imgBasePath + environment.images.credit_card,
    selected: this.imgBasePath + environment.images.success,
  };
  specialInstructions: string;
  specialInstructionPopupHeading: string;
  specialInstructionPopupMessage: string;
  specialInstructionPopupPlaceholder:string;
  selectedPayment: string;
  payWithIcon: string;
  tip: any;
  selectedPaymentType: string;
  isPayOnDelivery: boolean = false;
  selectedDelivery: any;
  virtual: boolean;
  locCloseMsg: string;
  grandTotalAmount: number;
  orderSettings: any;
  allowNoPaymentOrdering: boolean = false;
  constructor(
    public store: Store,
    public router: Router,
    public sweetAlert: SweetAlertService,
    public spinner: NgxSpinnerService,
    public shared: SharedService,
    public translate: TranslateService,
    public venueManagementService: VenueManagementService,
    public modal: ModalService,
    public _location: Location
  ) {
    super();

    this.locationDefaults$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationDefaults) => {
        if (locationDefaults) {
          this.locationDefaults = locationDefaults;
          this.orderSettings = this.locationDefaults.locationData.orderSettings;
          if (
            this.locationDefaults &&
            this.locationDefaults.locationData &&
            this.locationDefaults.locationData.orderSettings
          ) {
            this.specialInstructions = this.locationDefaults.locationData.orderSettings.specialInstruction;
            this.specialInstructionPopupHeading = this.locationDefaults.locationData.orderSettings.specialInstructionPopupHeading;
            this.specialInstructionPopupMessage = this.locationDefaults.locationData.orderSettings.specialInstructionPopupMessage;
            this.specialInstructionPopupPlaceholder = this.locationDefaults.locationData.orderSettings.specialInstructionPopupPlaceholder;
          }
        }
      });
    this.locationStatus$
      .pipe(takeUntil(this.destroy$))
      .subscribe((locationStatus) => {
        if (locationStatus) {
          this.locationStatus = locationStatus;
        }
      });
    this.delivery$.pipe(takeUntil(this.destroy$)).subscribe((delivery) => {
      if (delivery) {
        this.delivery = delivery;
      }
    });

    this.cartPrice$.pipe(takeUntil(this.destroy$)).subscribe((cartPrice) => {
      if (cartPrice) {
        this.cartPrice = cartPrice;
        this.cartPrice.totalAmount = this.cartPrice.price;
      }
    });

    this.cartItems$.pipe(takeUntil(this.destroy$)).subscribe((cartItems) => {
      if (cartItems) {
        this.cartItems = cartItems;
        if (this.cartItems[0] && this.cartItems[0].menuItems.length == 0) {
          const selectedLocation = this.store.selectSnapshot(
            LocationState.getSelectedLocation
          );
          this.router.navigate([
            `/locations/${selectedLocation._id}/menu`,
          ]);
        }
        if (this.cartItems[0].isDeliverable === false) {
          this.showUndeliveredMessage();
        }
      }
    });
    this.specialInstruction$
      .pipe(takeUntil(this.destroy$))
      .subscribe((specialInstruction) => {
        if (specialInstruction) {
          this.specialInstructionText = specialInstruction;
          this.isSpecialInstructionPresent = true;
        }
      });
    this.tip$.pipe(takeUntil(this.destroy$)).subscribe((tip) => {
      if (tip) {
        this.tip = tip;
      }
    });
    this.selectedPayment$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedPayment) => {
        if (selectedPayment) {
          this.selectedPayment = selectedPayment;
        }
      });
    this.selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );
    this.checkNoPaymentOrdering();
  }

  ngOnInit(): void {
    this.store.dispatch(new FetchCart());
    this.store.dispatch(new FetchLocationDefaults());
    this.store.dispatch(new FetchPaymentMethods());
    this.selectedDelivery = this.store.selectSnapshot(
      (app) => app && app.delivery && app.delivery.selectedDelivery
    );

    this.isMobileView = this.shared.isMobileScreen();
  }
  openPaymentView() {
    this.router.navigate([`/payment`]);
  }
  closeReview() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if (selectedLocation._id) {
      this.router.navigate([`/locations/${selectedLocation._id}/menu`]);
    }
  }
  openSpecialInstruction() {
    this.isSpecialInstruction = true;
    // setTimeout(() => {
    //   this.specialInstructionField.nativeElement.focus();
    // }, 0);
  }
  setDesktop(val) {
    if (!val.isdesktop) {
      this.desktopView = !val.isdesktop;
    }
    if (val.type === 5) {
      if (val.savedCard) this.nextPage = true;
      this.isPayOnDelivery = !val.isdesktop;
    } else {
      this.isPayOnDelivery = false;
    }
  }
  addCardDesktop(val) {
    if (val) {
      this.addCardMethod = true;
      this.nextPage = true;
    }
  }
  changePayMethod(val) {
    if (val) {
      this.nextPage = false;
      this.desktopView = false;
    }
  }

  closeSpecialInstructionView() {
    this.isSpecialInstruction = false;
  }
  deleteSpecialInstruction() {
    let text = '';
    this.saveSpecialInstruction(text);
  }
  saveSpecialInstruction(specialInstructionText) {
    this.isSpecialInstruction = false;
    this.updateSpecialInstruction(specialInstructionText);
    this.store.dispatch(new SetSpecialInstruction(specialInstructionText));
  }
  updateSpecialInstruction(specialInstructionText) {
    this.specialInstructionText = specialInstructionText;
    if (specialInstructionText.length > 0)
      this.isSpecialInstructionPresent = true;
    else this.isSpecialInstructionPresent = false;
  }

  updateGrandTotal(tip) {
    let totalAmount = this.cartPrice.subTotal;
    let totalAmountWithoutTip = 0;
    if (this.cartPrice.tip)
      totalAmountWithoutTip = this.cartPrice.totalAmount - this.cartPrice.tip;
    else totalAmountWithoutTip = this.cartPrice.totalAmount;

    if (tip.type === '%') {
      let calculatedTip = (totalAmount * tip.value) / 100;
      this.cartPrice.tip = calculatedTip;
    } else if (tip.type === '$') {
      this.cartPrice.tip = parseFloat(tip.value);
    }
    if (this.cartPrice.tip) {
      this.cartPrice.totalAmount = totalAmountWithoutTip + this.cartPrice.tip;
    } else {
      this.cartPrice.totalAmount = totalAmountWithoutTip;
    }
    this.store.dispatch(new UpdateCartTotal(this.cartPrice));
  }

  getPaymentMethod() {
    let paymentState = this.store.selectSnapshot(
      PaymentMethodState.getPaymentState
    );

    switch (this.selectedPayment) {
      case 'Pay at Location':
        return (this.payWithIcon = 'assets/images/Pay-on-Delivery-Icon.svg');
        break;
      case 'Credit Card':
        {
          if (paymentState.cardType && paymentCardType[paymentState.cardType]) {
            return (this.payWithIcon =
              paymentCardType[paymentState.cardType].icon);
          } else {
            return (this.payWithIcon = 'assets/images/Credit-Card-Logo.svg');
          }
        }
        break;
      default:
        return (this.payWithIcon = 'assets/images/Pay-on-Delivery-Icon.svg');
    }
  }

  async placeOrder() {
    const geoFencingValidation = await this.validateLocationGeoFencing();
    if (geoFencingValidation) {
      this.virtual = this.delivery.textCode == 'DELIVERYTYPE04' ? true : false;
      if (this.delivery.textCode == 'DELIVERYTYPE05') {
        this.locCloseMsg = 'You can place the order once the event starts';
      } else {
        if (this.delivery.textCode == 'DELIVERYTYPE02') {
          this.locCloseMsg = 'Ordering is closed';
        } else {
          this.locCloseMsg =
            'Sorry, the location is closed.You can place the order only when the location is Open';
        }
      }

      if (this.allowNoPaymentOrdering) {
        this.setCODOrder();
      }
      if (
        (this.locationStatus &&
          this.locationStatus.isOpenToday &&
          this.locationStatus.isCurrentlyOpen) ||
        this.virtual == true
      ) {
        this.router.navigate([`/order/complete`]);
      } else if (
        this.locationStatus &&
        (!this.locationStatus.isOpenToday ||
          !this.locationStatus.isCurrentlyOpen) &&
        this.virtual == false
      ) {
        this.sweetAlert.toast('error', this.locCloseMsg);
      }
    }
  }

  showUndeliveredMessage() {
    if (this.router.url.split('/').pop() === 'order') {
      this.sweetAlert.alertError(
        '',
        'Some of the items in the cart are undeliverable to the selected address.'
      );
    }
  }

  checkNoPaymentOrdering() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    if (
      locationDefaults &&
      locationDefaults?.locationData?.orderSettings?.allowNoPaymentOrdering
    ) {
      this.allowNoPaymentOrdering =
        locationDefaults.locationData.orderSettings.allowNoPaymentOrdering;
    }
  }

  setCODOrder() {
    let paymentMethod = paymentMethods.find((method) => method.id == 5);
    this.store.dispatch(new ClearSelectedCard());
    this.store.dispatch(new SetPaymentMethod(paymentMethod));
  }


  validateLocationGeoFencing() {
    const locationDefaults = this.store.selectSnapshot(
      LocationDefaultState.getLocationDefaults
    );
    return new Promise(async (resolve) => {
      if (locationDefaults?.locationData?.orderSettings?.geoFencing?.isAllow && locationDefaults?.locationData?.orderSettings?.geoFencing?.coordinates?.length>0) {
        let isUserInsideGeoBoundary = undefined;
        isUserInsideGeoBoundary = await this.verifyUserInsideGeoBoundary(
          locationDefaults.locationData.orderSettings.geoFencing.coordinates,
          locationDefaults.locationData.orderSettings.geoFencing.scaling
        );
        if (isUserInsideGeoBoundary == false) {
          this.modal.showSmallModal(AlertPopupComponent,{data:{
            header:this.translate.instant('ORDER NOT PLACED'),
            content:this.translate.instant('user-not-inside-geoboundary-msg'),
            actionable:true,
            buttonText:"Close"
          },
          whenClosed: (data) => {
           // if(data)this.countSubject.next(payload);
          },
        });

        }
        resolve(isUserInsideGeoBoundary);
      } else {
        resolve(true);
      }
    });
  }

  async verifyUserInsideGeoBoundary(boundaryCoordinates,scaling) {
    return new Promise(async (resolve) => {
    let filteredCoords = boundaryCoordinates.map((coord) => [
      coord.long,
      coord.lat,
    ]);
    let geoScaling = scaling ? scaling : 1.2;
    this.store.dispatch(new FetchCurrentCoordinates());
    const opts = { enableHighAccuracy: true};
    navigator.geolocation.getCurrentPosition((position) => {
      console.log('position',position)
      let coord = position['coords'];
      if (coord && coord['longitude'] && coord['latitude']) {
        let point = turf.point([coord['longitude'], coord['latitude']]);
        let polygon = turf.polygon([filteredCoords]);
        let scaledPoly = transformScale(polygon, geoScaling);
        let isInside = booleanPointInPolygon(point, scaledPoly, {
          ignoreBoundary: false,
        });
        resolve(isInside);
      }
    },
    (err) => {
      this.router.navigate([`locationAccess`])
      // this.modal.showSmallModal(AlertPopupComponent,{data:{
      //   content:'Please enable location services to continue ordering',
      //   actionable:false
      // },
      // whenClosed: (data) => {
      // }})

    },opts
    )
  //   this.getCurrentCoordinates$.pipe(
  //     takeUntil(this.destroy$)
  //   ).subscribe((coord) => {
  //     console.log(coord)
  //   if (coord && coord['longitude'] && coord['latitude']) {
  //     let point = turf.point([coord['longitude'], coord['latitude']]);
  //     let polygon = turf.polygon([filteredCoords]);
  //     let scaledPoly = transformScale(polygon, 1.5);
  //     let isInside = booleanPointInPolygon(point, scaledPoly, {
  //       ignoreBoundary: false,
  //     });
  //     resolve(isInside);
  //   }
  //  });

  })

  }

}
