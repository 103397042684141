import { Component, OnInit, Input } from '@angular/core';
import {
  Delivery,
  DeliveryState,
  BaseComponent,
  FetchVenues,
  VenueManagementState,
  Venue,
  SetSelectedPickUpLocation
} from '@moduurnv2/libs-orderingapp/src/core';
import { SetSelectedDelivery } from '@moduurnv2/libs-orderingapp/src/core';
import { Store, Select } from '@ngxs/store';
import { FetchHomeData } from '@moduurnv2/libs-orderingapp/src/core';
import { Observable } from 'rxjs/internal/Observable';
import { takeUntil } from 'rxjs/operators';
import { SweetAlertService } from '@moduurnv2/libs-orderingapp/src/core';
import { ClearCart, CartState } from '@moduurnv2/libs-orderingapp/src/core';
import {TranslateService} from '@ngx-translate/core';
export class DeliveryMethodsBaseComponent extends BaseComponent
  implements OnInit {
  @Input('items') items: Delivery[];
  @Input('selectedDelivery') selectedMethod: Delivery;
  @Select(DeliveryState.getSelectedDelivery) deliveyMethod$: Observable<
    Delivery
  >;
  @Select(VenueManagementState.getVenues) venues$: Observable<Venue[]>;

  public deliveryMethods = [
    {
      id: 'DELIVERYTYPE01',
      icon: '/assets/images/delivery-icon-big.svg',
      icon_inactive: '/assets/images/Delivery Icon Big Inactive.svg',
      bg_color: '#FFD36F',
      gradient: {
        primary: '#FFD36F',
        secondary: '#E5A800',
      },
    },
    {
      id: 'DELIVERYTYPE02',
      icon: '/assets/images/takeout-icon-big.svg',
      icon_inactive: '/assets/images/Take Out Icon Big Inactive.svg',
      bg_color: '#FFB294',
      gradient: {
        primary: '#FFB294',
        secondary: '#F5875B',
      },
    },
    {
      id: 'DELIVERYTYPE03',
      icon: '/assets/images/tableservice-icon-big.svg',
      icon_inactive: '/assets/images/Table Service Icon Big Inactive.svg',
      bg_color: '#8CE0F2',
      gradient: {
        primary: '#29B4D0',
        secondary: '#8CE0F2',
      },
    },
    {
      id: 'DELIVERYTYPE07',
      icon: '/assets/images/Curbside Delivery Icon Big.svg',
      icon_inactive: '/assets/images/Curbside Delivery Icon Big Inactive.svg',
      bg_color: '#F0B3DB',
      gradient: {
        primary: '#F0B3DB',
        secondary: '#D966B2',
      },
    },
    {
      id: 'DELIVERYTYPE04',
      icon: '/assets/images/postal.svg',
      icon_inactive: '/assets/images/Postal Delivery Icon Big Inactive.svg',
      bg_color: '#C2BBEF',
      gradient: {
        primary: '#C2BBEF',
        secondary: '#7E71D5',
      },
    },
    {
      id: 'DELIVERYTYPE05',
      icon: '/assets/images/Events Icon Big.svg',
      icon_inactive: '/assets/images/Events Icon Big Inactive.svg',
      bg_color: '#C2E0A8',
      gradient: {
        primary: '#C2E0A8',
        secondary: '#72AD3F',
      },
    },
    {
      id: 'DELIVERYTYPE08',
      icon: '/assets/images/single-location-address.svg',
      icon_inactive: '/assets/images/single-location-address.svg',
      bg_color: '#FFB294',
      gradient: {
        primary: '#FFB294',
        secondary: '#F5875B',
      },
    },
    {
      id: 'DELIVERYTYPE09',
      icon: '/assets/images/pickup-active.svg',
      icon_inactive: '/assets/images/pickup-inactive.svg',
      bg_color: '#F0B3DB',
      gradient: {
        primary: '#F0B3DB',
        secondary: '#D966B2',
      },
    },
    {
      id: 'DELIVERYTYPE10',
      icon: '/assets/images/classroom-delivery-active.svg',
      icon_inactive: '/assets/images/classroom-delivery-inactive.svg',
      bg_color: '#FFD36F',
      gradient: {
        primary: '#FFD36F',
        secondary: '#E5A800',
      },
    },
  ];

  constructor(private store: Store, public sweetAlert: SweetAlertService, public translate: TranslateService) {
    super();
    this.getSelectedDelivery();
  }

  ngOnInit() {}

  getIcon(textCode) {
    let obj = this.deliveryMethods.find((o) => o.id === textCode);
    return obj
      ? this.selectedMethod.textCode === textCode
        ? obj.icon
        : obj.icon_inactive
      : '';
  }
  async onClick(item) {
    if (this.selectedMethod && this.selectedMethod.textCode == item.textCode)
      return;

    let selectedCart = this.store.selectSnapshot(CartState.getCart);
    if (selectedCart) {
      let vm = this;
      await this.sweetAlert
        .confirm(
          this.translate.instant('are-you-sure'),
          this.translate.instant('another-delivery-method')
           )
        .then((choice) => {
          if (choice) {
            this.selectedMethod = item;
            this.store.dispatch(
              new SetSelectedDelivery(this.selectedMethod.textCode)
            );
            this.store.dispatch(new FetchHomeData());
            this.setDefaultVenue(this.selectedMethod.textCode);
            this.store.dispatch(new ClearCart()); //Changes for MV2-166
          } else {
            return;
          }
        });
    } else {
      this.selectedMethod = item;
      this.store.dispatch(
        new SetSelectedDelivery(this.selectedMethod.textCode)
      );
      this.store.dispatch(new FetchHomeData());
      this.setDefaultVenue(this.selectedMethod.textCode);
      this.store.dispatch(new ClearCart()); //Changes for MV2-166
    }
  }

  getStyle(textCode) {
    let obj = this.deliveryMethods.find((o) => o.id === textCode);
    if (obj) {
      if (this.selectedMethod.textCode === obj.id) {
        return {
          'box-shadow': '4px 4px 7px ' + obj.gradient.secondary + '66',
          background: `transparent linear-gradient(225deg, ${obj.gradient.primary} 0%, ${obj.gradient.secondary} 100%) 0% 0% no-repeat padding-box`,
        };
      } else {
        return {
          'background-color': obj.bg_color,
        };
      }
    }
  }

  getSelectedDelivery() {
    this.deliveyMethod$
      .pipe(takeUntil(this.destroy$))
      .subscribe((selectedMethod) => {
        if (selectedMethod) {
          this.selectedMethod = selectedMethod;
        }
      });
  }

  setDefaultVenue(deliveryTypeTextCode){
    if(deliveryTypeTextCode == 'DELIVERYTYPE09'){
      let filter = {
        tableType: 'TABLETYPE06'
      };
      this.store.dispatch(new FetchVenues(filter));
      this.venues$.pipe(takeUntil(this.destroy$)).subscribe((venues) => {
        if (venues && venues.length == 1) {
          let defaultPickupStation = venues[0];
          if (defaultPickupStation) {
            this.store.dispatch(
              new SetSelectedPickUpLocation(defaultPickupStation)
            );
          }
        } 
      });
    }
  }
}
