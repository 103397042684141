import { Component, OnInit, Input } from '@angular/core';
import {
  BaseComponent,
  environment,
  LocationState,
  WhitelabelState,
} from '@moduurnv2/libs-orderingapp';
import { Store } from '@ngxs/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'moduurnv2-wj-table-empty',
  templateUrl: 'wj-table-empty.component.html',
  styleUrls: ['wj-table-empty.component.scss'],
})
export class WJTableEmptyComponent extends BaseComponent implements OnInit {
  organizationLogo: string;
  organizationName: string;
  service_unavailable = 'assets/images/WJ_Service_Unavailable.png';
  selectedLocationName: string;

  constructor(private store: Store) {
    super();
  }

  ngOnInit() {
    let whitelabel = this.store.selectSnapshot(WhitelabelState.getWhitelabel);
    this.organizationLogo =
    environment.imagebaseurl + whitelabel.restaurantLogo;
    this.organizationName = whitelabel.restaurantName;
  }
}
