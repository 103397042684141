import { Router,ActivatedRoute } from '@angular/router';
import { OnInit,Input } from '@angular/core';

import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Subscription } from 'rxjs';
import { Config } from 'apps/orderingapp/web-orderingapp/src/config';
import { FormBuilder,Validators } from '@angular/forms';
import {
  SharedService
} from '@moduurnv2/libs-orderingapp/src/core';

import {
  BaseComponent,
  Regex,
  AuthEmailState,
  AuthEmailGenericResponse,
  LocationState,
  ResetPassword,
  AuthEmailLoginResponse
} from '@moduurnv2/libs-orderingapp/src/core';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';

export class EmailChangePasswordBaseComponent extends BaseComponent implements OnInit {
  emailRegex = Regex.email;
  resetPasswordSubscription: Subscription;
  organizationId = Config.organizationId;
  formSubmitted =false;
  updateErrorFlag:boolean =false
  isEncrypted: boolean = false;
  userDetails:any;
  changePasswordForm:any;
  encrypted;
  result: string;
  userName: string;
  isMobileView: boolean;
  encryptedUsername:string ='';
  _modalClose: any = null;
  @Select(AuthEmailState.getResetPasswordResponse) resetPwd$: Observable<
  AuthEmailGenericResponse
  >;
  @Select(AuthEmailState.getUserDetails) userDetails$: Observable<
  AuthEmailLoginResponse
>;
@Input() showOldPassword: boolean = false;

  constructor(public store: Store, public router: Router, private route: ActivatedRoute,private fb: FormBuilder,private _shared:SharedService,public modal: ModalService) {
    super();
   this.route.queryParams.subscribe(data => {
    let keyArray = Object.keys(data) || [];
    if (data && keyArray.length > 0) {
      if (keyArray.indexOf("username") > -1) {
        this.encryptedUsername = data["username"];
      } else {
        this.encryptedUsername = keyArray[0];
        this.isEncrypted = true;
      }
    }
  });

  }
  ngOnInit() {
    this.isMobileView = this._shared.isMobileScreen();
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: ['', this.showOldPassword ? [Validators.required] : []],
        password: ['', [Validators.required],this._shared.passwordValidator.bind(this._shared)],
        confirmPassword: ['', [Validators.required]],
      },
      {
        validator: this._shared.MatchPassword('password', 'confirmPassword'),
      }
    );
  }

  ngOnDestroy() {
    if (this.resetPasswordSubscription) this.resetPasswordSubscription.unsubscribe();
    if (this.userDetails) this.userDetails.unsubscribe();
  }


  get changePasswordFormControl() {
    return this.changePasswordForm.controls;
  }
  resetPassword() {
    this.formSubmitted = true;
    if (this.changePasswordForm.valid) {
      let fields = this.changePasswordForm.value;
      let request:any;
      this.userDetails = this.userDetails$.subscribe((res=>{
        if(res){
        this.userName = res.email;
        }
      }))
      if(!this.showOldPassword)
       request = { username: this.encryptedUsername,newPassword:fields.password, encrypted: this.isEncrypted, organizationId: Config.organizationId };
      else
       request = {username: this.userName, newPassword:fields.password, oldPassword:fields.currentPassword,organizationId: Config.organizationId };
      this.store.dispatch(new ResetPassword(request));
      this.resetPasswordSubscription = this.resetPwd$.subscribe((res=>{
        if(res && res.status && res.status ==200){
          this.updateErrorFlag = false;
          this.result = res.msg;
          if(this.isMobileView)
          this.returnToHome();
          // else
          // this.closeChangePassword();
        } else if(res) {
          this.result = res.msg;
          this.updateErrorFlag = true;
        }
      }))
    }
  }

  returnToHome() {
    const selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );
    if(selectedLocation && selectedLocation._id)
    this.router.navigate([`/locations/${selectedLocation._id}/home`]);
    else
    this.router.navigate([`/locations`]);
  }
  closeChangePassword() {
    if(this._modalClose)
    this._modalClose();
  }
}
