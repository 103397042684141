import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import {
  SafeStylePipe,
  SafeResourcePipe,
  SafeUrlPipe,
} from './pipes/safe.pipe';
import {
  DateOrderPipe,
  DateLocalize,
  CurrencyLocalize,
  DecimalLocalize,
  ItemSearch,
  MenuSearch,
  Time24to12Format,
  DistanceConverter,
  LanguageLocalize
} from './pipes';

//to implement date
import localEn from '@angular/common/locales/en';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
registerLocaleData(localeFr, 'fr');
registerLocaleData(localEn, 'en');
registerLocaleData(localeEs, 'es');

import {
  ThemeDirective,
  BgImageDirective,
  CreditCardDirective,
  TogglePasswordDirective,
} from './directives';

@NgModule({
  imports: [TranslateModule],
  declarations: [
    DateOrderPipe,
    DateLocalize,
    CurrencyLocalize,
    Time24to12Format,
    DecimalLocalize,
    ItemSearch,
    MenuSearch,
    SafeStylePipe,
    SafeResourcePipe,
    SafeUrlPipe,
    ThemeDirective,
    BgImageDirective,
    CreditCardDirective,
    TogglePasswordDirective,
    DistanceConverter,
    LanguageLocalize
  ],
  providers:[LanguageLocalize],
  exports: [
    TranslateModule,
    DateOrderPipe,
    DateLocalize,
    CurrencyLocalize,
    Time24to12Format,
    DecimalLocalize,
    ItemSearch,
    MenuSearch,
    SafeStylePipe,
    SafeResourcePipe,
    SafeUrlPipe,
    ThemeDirective,
    BgImageDirective,
    CreditCardDirective,
    TogglePasswordDirective,
    DistanceConverter,
    LanguageLocalize
  ],
})
export class UISharedModule {}
