import { Auth } from '../models';

export class SetCommonAuthDetails {
    static readonly type = '[AUTH] SetCommonAuthDetails';
    constructor(public payload: Auth) { }
}


export class FetchCommonAuthDetails {
    static readonly type = '[AUTH] FetchCommonAuthDetails';
    constructor() { }
}

export class SetCartId {
    static readonly type = '[AUTH] SetCartId';
    constructor(public payload: string) { }
}

export class FetchCartId {
    static readonly type = '[AUTH] FetchCartId';
    constructor() { }
}
