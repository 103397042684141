import {
  Component,
  OnInit,
  ViewChild,
  ElementRef
} from '@angular/core';
import { Store, Select } from '@ngxs/store';
import {
  BaseComponent,LocationState,VenueManagementState
} from '@moduurnv2/libs-orderingapp/src';
import { ModalService } from '@moduurnv2/web/features/ui/components/modal/modal.service';
import { SetCustomerPerTable } from '@moduurnv2/libs-orderingapp/src/core';
import { Router } from '@angular/router';
import { ThemeOptions } from '@moduurnv2/features/ui/directives';
@Component({
  selector: 'moduurnv2-person-count',
  templateUrl: './person-count.component.html',
  styleUrls: ['./person-count.component.scss'],
})
export class PersonCountComponent extends BaseComponent implements OnInit {
  @ViewChild('customerCount') guestCount: ElementRef;
  numbers = new RegExp(/^[0-9]*$/);
  _modalClose: any = null;
  _data: any = null;
  isHavingValue:boolean=false;
  personCount : number ;
  set modalClose(modalRef: any) {
    this._modalClose = (data) => {
      this.modal.closeComponentModal(modalRef, data);
    };
  }
  selectedTimeDate = {
    date: null,
    time: null,
  };
  themeOptions: ThemeOptions = {
    appFontFamily:true
  };

  constructor(private store: Store, private modal: ModalService,public router: Router) {
    super();
  }
  ngOnInit() {}
  clearvalue() {
    this.personCount = undefined;
  }
  personCountSubmit(value) {
    let selectedLocation = this.store.selectSnapshot(
      LocationState.getSelectedLocation
    );

    if(value && parseInt(value)>0) {
    const PersonCount = {
      count:parseInt(value)
    }
    this.isHavingValue = false;
    this.store.dispatch(new SetCustomerPerTable(PersonCount));
    this.modal.closeAllModals();
    this.router.navigate([`/locations/${selectedLocation._id}/menu`]);
  } else {
    this.isHavingValue = true;
    this.guestCount.nativeElement.focus();
  }
  }

  checkFormat(event) {
    if (!this.numbers.test(event.target.value)) {
      return (event.target.value = '');
    } else if(event.target.value>0) {
      this.isHavingValue = false;
    }
  }
  set data(data: any) {
    this._data = data;
  }
}
