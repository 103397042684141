import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
// xplat
import { UIModule } from '@moduurnv2/web';
import { UISharedModule } from '@moduurnv2/features';
import {
  WJHeaderComponent,
  WJFooterComponent,
  WJItemCountComponent,
  WJNotificationBarComponent,
  WJIconListComponent,
  WJSpecialInstructionsComponent
} from '.';
import { WJModifierComponent } from './wj-modifier/wj-modifier.component';
import { WJPortionListComponent } from './wj-modifier/components/wj-portion-list/wj-portion-list.component';
import { WJModifierListComponent } from './wj-modifier/components/wj-modifier-list/wj-modifier-list.component';
import { NgxsModule } from '@ngxs/store';
import { MenuItemState } from '@moduurnv2/libs-orderingapp/src';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    UIModule,
    CommonModule,
    UISharedModule,
    NgxsModule.forFeature([MenuItemState]),
    FormsModule
  ],
  declarations: [
    WJFooterComponent,
    WJHeaderComponent,
    WJModifierComponent,
    WJPortionListComponent,
    WJIconListComponent,
    WJModifierListComponent,
    WJItemCountComponent,
    WJNotificationBarComponent,
    WJSpecialInstructionsComponent,
  ],
  exports: [
    UIModule,
    FormsModule,
    WJFooterComponent,
    WJHeaderComponent,
    WJModifierComponent,
    WJPortionListComponent,
    WJIconListComponent,
    WJModifierListComponent,
    WJItemCountComponent,
    WJNotificationBarComponent,
    WJSpecialInstructionsComponent,
  ],
  schemas: [NO_ERRORS_SCHEMA],
})
export class SharedModule {}
